.aui-hero-section {
  --aui-color-text: var(--aui-color-white);

  @media #{$aui-header-mobile-breakpoint} {
    padding-top: 0;
  }

  &--no-mb {
    margin-bottom: 0;
  }

  &--bg-white {
    background: var(--aui-color-white);
  }

  &--small-height {
    margin-bottom: rem(65px);

    .aui-hero-section__img {
      height: 87dvh;
    }

    @media only screen and (max-width: map-get($aui-breakpoints, md)) {
      &:not(:has(.aui-hero-section__img)) {
        &:not(:has(.submenu)) {
          height: 44dvh;
          min-height: rem(390px);
        }
      }
    }

    .aui-hero-section__content {
      & > .aui-container {
        &:first-child {
          margin-bottom: rem(30px);

          @media #{$aui-header-mobile-breakpoint} {
            flex: none;

            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__img {
    min-height: rem(667px);

    overflow: hidden;

    video {
      width: auto;
      height: 100%;
    }

    @media #{$aui-breakpoint-xl} {
      min-height: 0;

      aspect-ratio: 16 / 9;

      video {
        width: 100%;
        height: auto;

        @media (orientation: landscape) {
          width: auto;
        }
      }
    }

    @media #{$aui-breakpoint-xxxxl} {
      aspect-ratio: unset;

      video {
        width: 100%;
        height: auto;
      }
    }
  }

  &:not(:has(.aui-hero-section__img)) {
    height: 60dvh;
    min-height: rem(450px);

    .aui-hero-section__content {
      --aui-color-heading: var(--aui-color-primary);
      --aui-color-text: var(--aui-color-prima ry);

      background: none;
      color: var(--aui-color-primary);

      padding-bottom: 0;

      .logo-hero {
        color: var(--aui-color-primary);
      }

      &-title {
        color: var(--aui-color-primary);

        & + p,
        & + div {
          max-width: rem(511px);
        }
      }
    }

    hr {
      --aui-horizontal-line-border-color: var(--aui-color-primary);
    }

    &:has(.submenu) {
      min-height: rem(550px);
    }
  }

  &__content {
    @keyframes slideIn {
      0% {
        transform: translateX(-100vw);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }

    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    color: var(--aui-color-white);

    padding-bottom: rem(80px);
    padding-top: rem(135px);

    @media #{$aui-header-mobile-breakpoint} {
      padding-top: 0;
    }

    @media only screen and (max-height: 919px) {
      padding-bottom: rem(55px);
    }

    & > .aui-container {
      &:first-child {
        flex: 1;

        @media #{$aui-header-mobile-breakpoint} {
          flex: none;
        }
      }
    }

    &-title,
    &-title + p,
    .content-block,
    .submenu {
      animation: slideIn 1s forwards ease-out;
      transform: translateX(-100vw);
    }

    &-title {
      max-width: rem(954px);

      text-align: left;
      text-wrap: balance;

      line-height: 1.1;

      margin-top: 0;
      margin-bottom: rem(20px);

      animation-delay: 0.2s;

      & + p {
        animation-delay: 0.2s;
      }
    }

    .submenu {
      animation-delay: 0.5s;

      & + .aui-breadcrumbs {
        margin-top: rem(22px);
      }
    }

    .content-block {
      --aui-color-heading: var(--aui-color-white);
      --aui-color-text: var(--aui-color-white);

      color: var(--aui-color-white);

      animation-delay: 0.5s;

      .aui-button {
        margin-top: rem(40px);

        @media #{$aui-breakpoint-md} {
          margin-top: rem(82px);
        }

        @media only screen and (max-height: 919px) {
          margin-top: rem(20px);
        }
      }

      h3, .aui-like-h3 {
        &::before {
          background: var(--aui-color-white) !important;
        }
      }
    }
  }

  hr {
    --aui-horizontal-line-border-color: var(--aui-color-white);

    display: none;

    margin: rem(20px) 0;

    @media #{$aui-breakpoint-md} {
      display: block;
    }
  }
}
