:root {
  --footer-top-gap: #{rem($footer-top-gap)};
  --footer-top-background: #{$footer-top-background};
  --footer-top-padding: #{rem($footer-top-padding)};

  --footer-top-logo-width: #{rem($footer-top-logo-width)};
  
  --footer-top-navigation-gap: #{rem($footer-top-navigation-gap)};
  --footer-top-navigation-column-gap: #{rem($footer-top-navigation-column-gap)};

  --footer-top-contacts-gap: #{rem($footer-top-contacts-gap)};

  --footer-top-socials-gap: #{rem($footer-top-socials-gap)};

  --footer-bottom-gap: #{rem($footer-bottom-gap)};
  --footer-bottom-background: #{$footer-bottom-background};
  --footer-bottom-padding: #{rem($footer-bottom-padding)};
}