.modal-side {
  @include aui-transition-base;

  width: 100%;
  max-width: rem(641px);
  height: calc(100dvh);

  display: flex;
  flex-direction: column;

  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999;

  overflow-y: auto;

  background: var(--aui-color-white);

  box-shadow: var(--aui-box-shadow--md);

  transform: translateX(100%);

  padding: rem(30px) rem(18px);

  @media #{$aui-breakpoint-md} {
    padding: rem(30px) rem(45px);
  }

  @media #{$aui-breakpoint-xxxl} {
    padding: rem(61px) rem(45px);
  }

  &--is-open {
    transform: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    margin-bottom: rem(54px);

    h2 {
      max-width: rem(309px);

      display: inline-flex;
      align-items: center;

      margin: 0;

      .cart-count {
        height: rem(36px);

        display: inline-block;

        font-size: var(--aui-font-size--sm);
        font-weight: var(--aui-font-weight--bold);

        border: rem(1px) solid var(--aui-color-primary);

        border-radius: rem(36px);

        margin-left: rem(30px);
        padding: rem(9px) rem(14px);
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .socials {
      flex: 1;
      align-items: flex-end;
    }
  }
}

.modal-side-background {
  width: 100vw;
  height: 100dvh;

  display: none;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 999998;
  content: '';

  background: transparent;

  &--is-open {
    display: block;
  }
}