:root {
  --aui-map-border-radius: #{rem($aui-map-border-radius)};
  --aui-map-height: #{$aui-map-height};

  --aui-map-bubble-width: #{rem($aui-map-bubble-width)};
  --aui-map-bubble-max-height: #{rem($aui-map-bubble-max-height)};
  --aui-map-bubble-font-size: #{rem($aui-map-bubble-font-size)};
  --aui-map-bubble-color: #{$aui-map-bubble-color};
  --aui-map-bubble-background: #{$aui-map-bubble-background};
  --aui-map-bubble-border-radius: #{rem($aui-map-bubble-border-radius)};
  --aui-map-bubble-padding: #{rem($aui-map-bubble-padding)};

  --aui-map-bubble-link-color: #{$aui-map-bubble-link-color};
  --aui-map-bubble-link-font-weight: #{$aui-map-bubble-link-font-weight};
}