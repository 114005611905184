:root {
  --aui-quote-border: #{$aui-quote-border};
  --aui-quote-border-radius: #{rem($aui-quote-border-radius)};
  --aui-quote-heading-color: #{$aui-quote-heading-color};
  --aui-quote-content-color: #{$aui-quote-content-color};
  --aui-quote-background: #{$aui-quote-background};
  --aui-quote-padding: #{rem($aui-quote-padding)};
  --aui-quote-padding--sm: #{rem($aui-quote-padding--sm)};

  --aui-quote-quote-color: #{$aui-quote-quote-color};
}
