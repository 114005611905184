// Variables
$aui-map-border-radius: $aui-border-radius--md !default;
$aui-map-height: clamp(rem(400px), 50.34vw, rem(500px)) !default;

$aui-map-bubble-width: 300px !default;
$aui-map-bubble-max-height: 150px !default;
$aui-map-bubble-font-size: $aui-font-size--base !default;
$aui-map-bubble-color: $aui-color-white !default;
$aui-map-bubble-background: $aui-color-primary !default;
$aui-map-bubble-border-radius: $aui-border-radius--md !default;
$aui-map-bubble-padding: 14px !default;

$aui-map-bubble-link-color: $aui-color-white !default;
$aui-map-bubble-link-font-weight: $aui-font-weight--bold !default;

@import "./../css-variables/components/map.scss";

// Styles
.aui-map {
  overflow: hidden;

  border-radius: var(--aui-map-border-radius);

  &__map {
    height: var(--aui-map-height);
  }

  .aui-popup-container {
    height: 0;

    cursor: auto;
    position: absolute;

    &__anchor {
      width: 100%;

      position: absolute;
      bottom: 8px;
      left: 0;

      &::after {
        width: 0;
        height: 0;

        content: '';
        position: absolute;
        top: 0;
        left: 0;


        transform: translate(-50%, 0);

        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid var(--aui-map-bubble-background);
      }

      &-bubble {
        width: var(--aui-map-bubble-width);
        max-height: var(--aui-map-bubble-max-height);

        position: absolute;
        top: 0;
        left: 0;

        transform: translate(-50%, -100%);

        color: var(--aui-map-bubble-color);
        font-size: var(--aui-map-bubble-font-size);

        background-color: var(--aui-map-bubble-background);
        border-radius: var(--aui-map-bubble-border-radius);

        overflow-y: auto;
        
        padding: var(--aui-map-bubble-padding);

        & > * {
          color: var(--aui-map-bubble-color);
        }

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          color: var(--aui-map-bubble-link-color);
          font-weight: var(--aui-map-bubble-link-font-weight);
        }
      }
    }
  }
}