h1, .aui-like-h1,
h2, .aui-like-h2,
h3, .aui-like-h3,
h4, .aui-like-h4,
h5, .aui-like-h5,
h6, .aui-like-h6 {
  line-height: var(--aui-line-height--sm);
  font-weight: var(--aui-font-weight--base);

  text-wrap: pretty;
}

body {
  line-height: var(--aui-line-height--sm);

  @media #{$aui-breakpoint-sm} {
    font-size: var(--aui-font-size--base);
  }

  @media #{$aui-breakpoint-lg} {
    font-size: #{rem(18px)};
  }
}