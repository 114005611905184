:root {
  --aui-navigation-gap: #{rem($aui-navigation-gap)};

  --aui-navigation-item-padding: #{$aui-navigation-item-padding};
  --aui-navigation-item-gap: #{rem($aui-navigation-item-gap)};
  --aui-navigation-item-font-family: #{$aui-navigation-item-font-family};
  --aui-navigation-item-font-size: #{rem($aui-navigation-item-font-size)};
  --aui-navigation-item-font-weight: #{$aui-navigation-item-font-weight};
  --aui-navigation-item-color: #{$aui-navigation-item-color};

  --aui-navigation-item-dropdown-icon-size: #{rem($aui-navigation-item-dropdown-icon-size)};
  --aui-navigation-item-dropdown-icon: #{$aui-navigation-item-dropdown-icon};

  --aui-navigation-item-submenu-padding: #{rem($aui-navigation-item-submenu-padding)};
  --aui-navigation-item-submenu-gap: #{rem($aui-navigation-item-submenu-gap)};
  --aui-navigation-item-submenu-offset: #{rem($aui-navigation-item-submenu-offset)};
  --aui-navigation-item-submenu-background: #{$aui-navigation-item-submenu-background};
  --aui-navigation-item-submenu-border-radius: #{rem($aui-navigation-item-submenu-border-radius)};
  --aui-navigation-item-submenu-border: #{$aui-navigation-item-submenu-border};
}