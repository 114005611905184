.cta-section {
  position: relative;

  padding: 0;

  * {
    text-align: left;
  }

  &__background {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 100%;

    video {
      width: 100%;
      height: auto;
    }
  }

  &__text {
    --aui-color-heading: var(--aui-color-white);

    width: 100%;

    display: flex;
    flex-direction: column;

    color: var(--aui-color-white);

    padding: var(--aui-cta-padding);
  }

  &:has(.cta-section__background) {
    overflow: hidden;

    .cta-section__background {
      @keyframes zoom {
        0%, 100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.2);
        }
      }

      min-height: rem(560px);

      video, img {
        height: rem(560px);
        width: auto;

        object-fit: cover;
      }

      img {
        animation: zoom 40s infinite ease-in-out;
      }

      @media #{$aui-breakpoint-xl} {
        min-height: 0;

        video, img {
          width: 100%;
          height: auto;
        }
      }
    }

    .cta-section__text {
      width: 100%;
      height: 100%;

      position: absolute;
      left: 0;
      top: 0;

      background: rgba($aui-color-primary, .6);
    }
  }

  &__title {
    max-width: rem(690px);

    order: 1;

    @media #{$aui-breakpoint-xl} {
      order: unset;
    }
  }

  &__subtitle {
    @include titleList;

    order: 0;

    margin-bottom: rem(35px);

    &::before {
      background: var(--aui-color-white);
    }

    @media #{$aui-breakpoint-xl} {
      order: unset;
    }
  }

  &__content {
    order: 2;

    @media #{$aui-breakpoint-xl} {
      order: unset;
    }
  }

  &__button {
    order: 4;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    &:has(.cta-section__button-logos) {
      justify-content: space-between;
      flex-direction: column;


      @media #{$aui-breakpoint-md} {
        flex-direction: row;
      }
    }

    &-logos {
      align-self: flex-start;

      @media #{$aui-breakpoint-md} {
        width: 50%;
        align-self: initial;
      }
    }

    @media #{$aui-breakpoint-xl} {
      order: unset;
    }
  }
}
