.font-weight-semi-bold {
  font-weight: var(--aui-font-weight--semi-bold);
}

.line-height-lg {
  line-height: var(--aui-line-height--lg);
}

.flex-grow {
  flex-grow: 1;
}

.display-none {
  display: none !important;
}

.position-relative {
  position: relative;
}

.text-line-through {
  text-decoration: line-through;
}
