// Variables
@import 'variables/typography';

// Styles
h1, .aui-like-h1,
h2, .aui-like-h2,
h3, .aui-like-h3,
h4, .aui-like-h4,
h5, .aui-like-h5,
h6, .aui-like-h6 {
  font-family: var(--aui-font-family--primary);
  font-weight: var(--aui-font-weight--bold);
  line-height: var(--aui-line-height--base);

  letter-spacing: var(--aui-letter-spacing--base);

  color: var(--aui-color-heading);

  &:first-child {
    margin-top: 0;
  }
}

h1, .aui-like-h1 {
  font-size: var(--aui-font-size--4xl);
  margin: calc(var(--aui-font-size--4xl) / var(--aui-headers-margin-coeficient)) 0;
}

h2, .aui-like-h2 {
  font-size: var(--aui-font-size--3xl);
  margin: calc(var(--aui-font-size--3xl) / var(--aui-headers-margin-coeficient)) 0;
}

h3, .aui-like-h3 {
  font-size: var(--aui-font-size--2xl);
  margin: calc(var(--aui-font-size--2xl) / var(--aui-headers-margin-coeficient)) 0;
}

h4, .aui-like-h4 {
  font-size: var(--aui-font-size--xl);
  margin: calc(var(--aui-font-size--xl) / var(--aui-headers-margin-coeficient)) 0;
}

h5, .aui-like-h5 {
  font-size: var(--aui-font-size--lg);
  margin: calc(var(--aui-font-size--lg) / var(--aui-headers-margin-coeficient)) 0;
}

h6, .aui-like-h6 {
  font-size: var(--aui-font-size--base);
  margin: calc(var(--aui-font-size--base) / var(--aui-headers-margin-coeficient)) 0;
}

body {
  font-family: var(--aui-font-family--secondary);
  font-size: var(--aui-font-size--sm);
  font-weight: var(--aui-font-weight--base);
  line-height: var(--aui-line-height--base);

  letter-spacing: var(--aui-letter-spacing--base);

  color: var(--aui-color-text);

  @media #{$aui-breakpoint-sm} {
    font-size: var(--aui-font-size--base);
  }
}

p {
  margin: var(--aui-margin--base) 0;

  &:first-child {
    margin-top: 0;
    margin-bottom: 0;
  }
}

a {
  @include aui-transition-base;

  display: inline-block;

  color: var(--aui-color-link);

  text-decoration: var(--aui-link-decoration);

  &:hover {
    text-decoration: var(--aui-link-decoration--hover);
  }
}
