.content-block {
  --images-margin-top: #{rem(28px)};

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: rem(14px);

  @media #{$aui-breakpoint-md} {
    flex-direction: row;
    justify-content: space-between;
    gap: rem(24px);
  }

  & > h3, & > .aui-like-h3,
  & > .content-block__info > h3,  & > .content-block__info > .aui-like-h3 {
    &:not(.aui-like-h2) {
      &:not(:empty) {
        @include titleList;
      }
    }
    
    flex-shrink: 0;
    order: 0;

    margin: 0;

    &::before {
      background: var(--aui-color-heading);
    }

    @media #{$aui-breakpoint-md} {
      order: unset;
    }
  }

  &__title {
    order: 1;

    @media #{$aui-breakpoint-md} {
      order: unset;
    }

    h2, .aui-like-h2 {
      max-width: rem(525px);

      margin-bottom: 0;
    }
  }

  & > .aui-button {
    order: 4;

    margin-top: rem(16px);

    @media #{$aui-breakpoint-md} {
      align-self: flex-end;
      order: unset;

      margin-top: 0;
    }
  }

  &__info {
    &-image {
      margin-bottom: rem(22px);

      @media #{$aui-breakpoint-md} {
        display: none;
      }
    }
  }

  &__description {
    width: 100%;

    order: 2;

    margin: rem(6px) 0;

    @media #{$aui-breakpoint-md} {
      order: unset;

      margin: rem(24px) 0;
    }
  }

  h3 + .content-block__content {
    @media #{$aui-breakpoint-md} {
      width: 75%;
    }
  }

  &__content {
    order: 3;

    @media #{$aui-breakpoint-md} {
      width: 100%;

      display: block;

      order: unset;

      p {
        max-width: rem(395px);
      }
    }

    &-title {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: rem(12px);

      margin-bottom: rem(23px);

      @media #{$aui-breakpoint-md} {
        flex-direction: row;

        margin-bottom: rem(33px);
      }

      h3, .aui-like-h3,
      h4, .aui-like-h4 {
        max-width: rem(313px);

        margin: 0;
      }
    }

    &-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: rem(16px);

      @media #{$aui-breakpoint-md} {
        gap: rem(28px);
      }
    }

    &-images {
      display: flex;
      flex-direction: column;
      gap: rem(20px);

      margin-top: var(--images-margin-top);

      &:not(:has(*)) {
        margin-top: 0 !important;
      }

      @media #{$aui-breakpoint-sm} {
        flex-direction: row;

        .aui-image {
          &:nth-child(odd) {
            width: 66%;
          }

          &:nth-child(even) {
            width: 33%;
          }

          img {
            width: min-content;
            height: 100%;

            object-fit: cover;
          }
        }
      }

      @media #{$aui-breakpoint-md} {
        margin-top: rem(40px);
      }
    }
  }

  &--border-top {
    border-top: rem(1px) solid var(--aui-color-border);

    padding-top: rem(25px);
    padding-bottom: rem(25px);

    @media #{$aui-breakpoint-md} {
      padding-top: rem(33px);
      padding-bottom: rem(33px);
    }

    &:last-child {
      border-bottom: rem(1px) solid var(--aui-color-border);
    }
  }

  &--half {
    gap: rem(24px);

    @media #{$aui-breakpoint-md} {
      align-items: stretch;

      .content-block__info {
        width: 40%;
        max-width: rem(426px);
  
        display: flex;
        flex-direction: column;
  
        h4 {
          margin-top: rem(43px);
          margin-bottom: rem(53px);
        }
  
        h4 + .content-block__info-text {
          margin-top: 0;
        }
  
        .content-block__info-text {
          flex: 1;
        }

        &:has(.content-block__info-tags) {
          .content-block__info-text {
            flex: none;
          }

          .content-block__info-tags {
            flex: 1;
          }
        }
      }
    }

    .content-block__info {
      &-text {
        margin-bottom: rem(25px);
      }

      &-tags {
        display: flex;
        flex-wrap: wrap;
        gap: rem(10px);

        font-size: var(--aui-font-size--sm);
        font-weight: var(--aui-font-weight--semi-bold);

        margin-top: rem(25px);
        margin-bottom: rem(25px);
      }

      &-button {
        width: max-content;

        display: inline-block;

        font-size: var(--aui-font-size--sm);
        font-weight: var(--aui-font-weight--semi-bold);

        &--arrow-down {
          svg {
            @include aui-transition-base;

            width: rem(17px);
          }

          &:hover {
            svg {
              transform: translateY(rem(6px));
            }
          }
        }
      }
    }

    .content-block__content {
      width: 100%;

      display: none;
      flex-direction: column;
      gap: rem(19px);

      @media #{$aui-breakpoint-sm} {
        flex-direction: row;
      }

      @media #{$aui-breakpoint-md} {
        display: flex;

        width: 55%;

        &:not(.content-block__content--one-image) {
          .aui-image {
            &:first-child {
              max-width: rem(370px) !important;
            }
    
            &:last-child {
              max-width: rem(217px);
            }
          }
        }
        
        .aui-image {
          img {
            width: 100%;
            height: 100%;
          }
        }        
      }

      .aui-image {
        img {
          object-fit: cover;
        }
      }
    }
  }

  &:has(.sliderArrows) {
    position: relative;
  }

  .sliderArrows {
    width: rem(68px);

    position: absolute;
    left: 0;
    bottom: 0;

    display: none;
    gap: rem(22px);

    padding-bottom: rem(90px);

    @media #{$aui-breakpoint-md} {
      display: flex;
    }

    button {
      svg {
        @include aui-transition-base;

        color: var(--aui-color-primary);

        width: rem(22px);
      }

      &:hover {
        &.left {
          svg {
            transform: translateX(-2px);
          }
        }

        &.right {
          svg {
            transform: translateX(2px);
          }
        }
      }
    }
  }
}

.aui-line:has(.content-block--standalone) + .aui-line:has(.content-block--standalone) section {
  margin-top: calc((var(--aui-section-margin-bottom) + 1px) * -1);
}
