:root {
  --aui-product-block-gap: #{rem($aui-product-block-gap)};
  --aui-product-block-border-width: #{rem($aui-product-block-border-width)};
  --aui-product-block-border-type: #{$aui-product-block-border-type};
  --aui-product-block-border-color: #{$aui-product-block-border-color};
  --aui-product-block-border-radius: #{rem($aui-product-block-border-radius)};
  --aui-product-block-box-shadow: #{$aui-product-block-box-shadow};

  --aui-product-block-flags-gap: #{rem($aui-product-block-flags-gap)};
  --aui-product-block-flags-translate-y: #{rem($aui-product-block-flags-translate-y)};
  --aui-product-block-flags-padding: #{rem($aui-product-block-flags-padding)};

  --aui-product-block-image-padding: #{rem($aui-product-block-image-padding)};

  --aui-product-block-content-padding: #{rem($aui-product-block-content-padding)};

  --aui-product-block-content-description-font-size: #{rem($aui-product-block-content-description-font-size)};

  --aui-product-block-content-tags-gap: #{rem($aui-product-block-content-tags-gap)};
}