$aui-font-family--primary: 'Barlow', sans-serif;
$aui-font-family--secondary: 'Barlow', sans-serif;

$aui-color-primary: #000000;
$aui-color-secondary: #F8F8F8;
$aui-color-dark: #000000;
$aui-color-gray--light: #fcfcfc;

$aui-header-mobile-breakpoint: "only screen and (min-width: 1350px)";
$aui-header-mobile-breakpoint-max: "only screen and (max-width: 1350px)";

// quick fixes
#gopay-payment-iframe {
    z-index: 9999999 !important;
}

@import 'adaptive-ui/src/scss/aui';

@import 'typography';
@import 'base';
@import 'mixins';
@import 'utilities';
@import 'aui-variables';

// AUI
@import 'aui/table';
@import 'aui/header';
@import 'aui/navigation';
@import 'aui/hero';
@import 'aui/badge';
@import 'aui/product-block';
@import 'aui/slider';
@import 'aui/input';
@import 'aui/loader-overlay';
@import 'aui/button';
@import 'aui/section-header';
@import 'aui/gallery';
@import 'aui/form';
@import 'aui/paginator';
@import 'aui/breadcrumbs';
@import 'aui/toast';
@import 'aui/modal';
@import 'aui/section';
@import 'aui/line';

// Components
@import 'components/socials';
@import 'components/content-block';
@import 'components/footer';
@import 'components/modal-side';
@import 'components/check-list';
@import 'components/cart';
@import 'components/text-with-image';
@import 'components/slider';
@import 'components/submenu';
@import 'components/cta';
@import 'components/categories';
@import 'components/slider-category';
@import 'components/product-detail';
@import 'components/dot-rating';
@import 'components/logo-hero';
@import 'components/top-section';
@import 'components/recipe';
@import 'components/steps';
@import 'components/order';
@import 'components/order-detail';
@import 'components/offer';
@import 'components/thanks';
@import 'components/user';
@import 'components/my-account';
@import 'components/lightbox';

// Pages
@import 'pages/contacts';
@import 'pages/technology';
@import 'pages/reference';
