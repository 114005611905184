// Variables
$aui-gallery-miniature-miniatures-margin-t: $aui-margin--md !default;

@import "./../css-variables/components/gallery-miniature";

// Styles
.aui-gallery-miniature {
  &__miniatures {
    margin-top: var(--aui-gallery-miniature-miniatures-margin-t);
  }
}