[aui-kit] {
  .ui-kit {
    .aui-container {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }

    padding: 30px 0;

    .colors {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 20px;

      .color {
        display: flex;
        align-items: center;
        gap: 10px;

        &__mark {
          width: 30px;
          height: 30px;

          border-radius: 50%;
        }
      }
    }

    .containers {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .aui-container {
        display: flex;
        align-items: center;
        justify-content: center;

        background: var(--aui-color-white);

        padding-top: 40px;
        padding-bottom: 40px;

        h1 {
          margin-bottom: 0;
        }
      }
    }

    .buttons,
    .icons,
    .alerts {
      display: flex;
      flex-direction: column;
      gap: 30px;

      &__item {
        display: flex;
        align-items: center;
        gap: 20px;

        &-type {
          width: 130px;
        }
      }
    }

    .tooltips {
      display: flex;
      gap: 20px;
    }

    .utilities {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .utility {
        width: 170px;
        height: 170px;

        display: flex;
        align-items: center;
        justify-content: center;

        background: var(--aui-color-white);
      }
    }

    .images {
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(3, 1fr);
    }

    .aui-grid {
      &__item {
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: var(--aui-font-size--4xl);
        font-weight: var(--aui-font-weight--bold);
        background: var(--aui-color-white);

        padding-top: 40px;
        padding-bottom: 40px;
      }
    }

    .badges {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    .aui-breadcrumbs,
    .aui-input-group {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .signposts {
      & > * {
        max-width: 350px;
      }
    }

    .gallery-miniature {
      max-width: 500px;
    }

    .modals {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }

    .blog {
      max-width: 570px;
    }
  }
}
