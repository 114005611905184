// Variables
$aui-go-to-top-z-index: 12;
$aui-go-to-top-bottom: 16px;
$aui-go-to-top-right: 16px;

@import '../css-variables/components/go-to-top.scss';

// Styles
.aui-go-to-top {
  &[fixed] {
    @include aui-transition-base;

    position: fixed;
    z-index: 12;
    bottom: var(--aui-go-to-top-bottom);
    left: auto;
    right: var(--aui-go-to-top-right);

    transform: translateY(calc(100% + var(--aui-go-to-top-bottom) + 10px));

    &.aui-go-to-top--is-show {
      transform: translateY(0);
    }
  }
}