// Variables
$aui-cta-border: rem(1px) solid $aui-color-border !default;
$aui-cta-border-radius: $aui-border-radius--md !default;
$aui-cta-background: transparent !default;
$aui-cta-box-shadow: none !default;
$aui-cta-heading-color: inherit !default;
$aui-cta-content-color: inherit !default;
$aui-cta-padding: $aui-padding--lg !default;
$aui-cta-padding--sm: $aui-padding--md !default;

@import './../css-variables/sections/cta';

// Styles
.aui-cta-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--aui-cta-background);

  border: var(--aui-cta-border);
  border-radius: var(--aui-cta-border-radius);

  box-shadow: var(--aui-cta-box-shadow);

  padding: var(--aui-cta-padding--sm);

  @media #{$aui-breakpoint-md} {
    padding: var(--aui-cta-padding);
  }

  &:not(.aui-section) {
    margin-bottom: var(--aui-section-margin-bottom);
  }

  * {
    text-align: center;
  }

  &__title, &__subtitle {
    color: var(--aui-cta-heading-color);
  }

  &__content {
    color: var(--aui-cta-content-color);
  }

  &__button {
    margin-top: var(--aui-margin--md);

    @media #{$aui-breakpoint-md} {
      margin-top: var(--aui-margin--lg);
    }
  }
}