$aui-color-white: #fff !default;
$aui-color-black: #000 !default;

$aui-color-background: #F2F5F6 !default;

$aui-color-primary: #58BAD7 !default;
$aui-color-secondary: #EF4534 !default;

$aui-color-dark: #020202 !default;

$aui-color-text: $aui-color-dark !default;

$aui-color-gray: #5C5C5C !default;

$aui-color-red: #EE4545 !default;
$aui-color-green: #44B053 !default;
$aui-color-blue: #2D58A5 !default;
$aui-color-orange: #EABB00 !default;

$aui-color-success: #44B053 !default;
$aui-color-info: #CFD7DE !default;
$aui-color-warning: #EABB00 !default;
$aui-color-danger: #EE4545 !default;
$aui-color-error: #EE4545 !default;
$aui-color-disabled-background: #f2f2f2 !default;
$aui-color-disabled-color: #b3b3b3 !default;
$aui-color-disabled-border-color: #d9d9d9 !default;

$aui-color-heading: #000 !default;

$aui-color-border: #CFD7DE !default;

$aui-color-link:#020202 !default;

$aui-color-list--ol: $aui-color-primary !default;
$aui-color-list--ul: $aui-color-primary !default;

$aui-color-placeholder: #5C5C5C !default;

@import './../css-variables/colors';