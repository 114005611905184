// Variables
$aui-paginator-gap: $aui-gap--xs !default;
$aui-paginator-pages-gap: $aui-gap--sm !default;
$aui-paginator-pages-width: 35px !default;
$aui-paginator-pages-height: 35px !default;

@import '../css-variables/components/paginator.scss';

// Style
.aui-paginator {
  display: flex;
  align-items: center;
  gap: var(--aui-paginator-gap);

  &__arrow {
    width: var(--aui-paginator-pages-width);
    height: var(--aui-paginator-pages-height);

    padding: 0;
  }

  &__pages {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--aui-paginator-pages-gap);
    
    &-between {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      text-align: center;
    }

    &-between,
    &-page {
      width: var(--aui-paginator-pages-width);
      height: var(--aui-paginator-pages-height);

      padding: 0;
    }
  }
}