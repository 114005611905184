// Variables
$aui-gallery-creative-content-max-width: 450px !default;

$aui-gallery-creative-gallery-grid-template-columns: repeat(5, minmax(0, 1fr)) !default;
$aui-gallery-creative-gallery-grid-column-span--odd: span 2 / span 2 !default;
$aui-gallery-creative-gallery-grid-column-span--even: span 3 / span 3 !default;
$aui-gallery-creative-gallery-grid-gap: clamp(rem(12px), 1.34vw, rem(24px)) !default;

@import './../css-variables/sections/gallery-creative';

// Styles
.aui-gallery-creative-section {
  &:not(.aui-section) {
    margin-bottom: var(--aui-section-margin-bottom);
  }

  &__content {
    @media #{$aui-breakpoint-md} {
      max-width: var(--aui-gallery-creative-content-max-width);
    }
  }

  &__images {
    display: grid;
    grid-template-columns: var(--aui-gallery-creative-gallery-grid-template-columns);
    gap: var(--aui-gallery-creative-gallery-grid-gap);

    @media #{$aui-breakpoint-md} {
      flex: 1;
    }

    &-item {
      height: 100%;

      img {
        height: 100%;

        object-fit: cover;
      }

      &:nth-child(4n+1),
      &:nth-child(4n+4) {
        grid-column: var(--aui-gallery-creative-gallery-grid-column-span--odd);
      }

      &:nth-child(4n+2),
      &:nth-child(4n+3) {
        grid-column: var(--aui-gallery-creative-gallery-grid-column-span--even);
      }
    }
  }
}

.aui-gallery-creative-thrown-section {
  &:not(.aui-section) {
    margin-bottom: var(--aui-section-margin-bottom);
  }

  & > * {
    span {
      display: inline-block;
    }

    &:nth-child(2) {
      padding-top: 30px;
    }
  }
}