:root {
  // Label
  --aui-label-font-size: #{rem($aui-label-font-size)};
  --aui-label-font-weight: #{$aui-label-font-weight};
  --aui-label-font-family: #{$aui-label-font-family};

  // Input
  --aui-input-height: #{rem($aui-input-height)};

  --aui-input-font-size: #{rem($aui-input-font-size)};
  --aui-input-font-weight: #{$aui-input-font-weight};
  --aui-input-color: #{$aui-input-color};
  --aui-input-background: #{$aui-input-background};

  --aui-input-border-width: #{rem($aui-input-border-width)};
  --aui-input-border-type: #{$aui-input-border-type};
  --aui-input-border-color: #{$aui-input-border-color};
  --aui-input-border-radius: #{$aui-input-border-radius};
  
  --aui-input-shadow: #{$aui-input-shadow};
  
  --aui-input-padding--x: #{rem($aui-input-padding--x)};
  --aui-input-padding--y: #{rem($aui-input-padding--y)};

  --aui-input-disabled-background: #{$aui-input-disabled-background};
  --aui-input-disabled-color: #{$aui-input-disabled-color};
  --aui-input-disabled-border-color: #{$aui-input-disabled-border-color};

  --aui-input-placeholder-color: #{$aui-input-placeholder-color};

  --aui-input-border-color--focus: #{$aui-input-border-color--focus};

  // Checkbox + Radio
  --aui-checkbox-box-gap: #{rem($aui-checkbox-box-gap)};

  --aui-checkbox-label-font-size: #{rem($aui-checkbox-label-font-size)};
  --aui-checkbox-label-font-weight: #{$aui-checkbox-label-font-weight};
  --aui-checkbox-label-color: #{$aui-checkbox-label-color};

  --aui-checkbox-input-size: #{rem($aui-checkbox-input-size)};
  --aui-checkbox-input-background: #{$aui-checkbox-input-background};
  --aui-checkbox-input-border-color: #{$aui-checkbox-input-border-color};
  --aui-checkbox-input-border-width: #{rem($aui-checkbox-input-border-width)};
  --aui-checkbox-input-border-type: #{$aui-checkbox-input-border-type};
  --aui-checkbox-input-radius: #{rem($aui-checkbox-input-radius)};
  --aui-checkbox-input-background--checked: #{$aui-checkbox-input-background--checked};
  --aui-checkbox-input-border-color--checked: #{$aui-checkbox-input-border-color--checked};

  // Textarea
  --aui-textarea-min-height: #{rem($aui-textarea-min-height)};

  // Multiple Select
  --aui-select-multiple-input-gap: #{rem($aui-select-multiple-input-gap)};

  --aui-select-multiple-input-option-color: #{$aui-select-multiple-input-option-color};
  --aui-select-multiple-input-option-background: #{$aui-select-multiple-input-option-background};
  --aui-select-multiple-input-option-border-color: #{$aui-select-multiple-input-option-border-color};
  --aui-select-multiple-input-option-border-width: #{rem($aui-select-multiple-input-option-border-width)};
  --aui-select-multiple-input-option-border-type: #{$aui-select-multiple-input-option-border-type};
  --aui-select-multiple-input-option-border-radius: #{rem($aui-select-multiple-input-option-border-radius)};
  --aui-select-multiple-input-option-padding: #{$aui-select-multiple-input-option-padding};

  --aui-select-multiple-input-option-close-icon-size: #{rem($aui-select-multiple-input-option-close-icon-size)};
  --aui-select-multiple-input-option-close-icon-color: #{$aui-select-multiple-input-option-close-icon-color};

  --aui-select-multiple-list-offset: #{rem($aui-select-multiple-list-offset)};
  --aui-select-multiple-list-option-color: #{$aui-select-multiple-list-option-color};
  --aui-select-multiple-list-option-background: #{$aui-select-multiple-list-option-background};
  --aui-select-multiple-list-option-color--hover: #{$aui-select-multiple-list-option-color--hover};
  --aui-select-multiple-list-option-background--hover: #{$aui-select-multiple-list-option-background--hover};
  --aui-select-multiple-list-option-color--active :#{$aui-select-multiple-list-option-color--active};
  --aui-select-multiple-list-option-content--active: #{$aui-select-multiple-list-option-content--active};
}