.page-technology {
  .aui-line {
    &:has(.text-with-image):not(:last-child) {
      .aui-section {
        margin-bottom: rem(33px);
      }
    }

    &:has(.text-with-image:last-child):not(:has(.text-with-image__image *)) {
      @media #{$aui-breakpoint-md} {
        margin-top: -13%;

        .text-with-image {
          max-width: 50%;
        }
      }
    }
  }

  .text-with-image {
    &__info {
      h2 {
        span {
          max-width: rem(400px);
        }
      }
    }
  }
}