.submenu {
  display: flex;
  flex-wrap: wrap;
  gap: rem(18px);
  font-size: clamp(1.25rem, 1.1rem + 0.5vw, 1.75rem); // 20px to 28px

  margin-top: rem(21px);

  @media #{$aui-breakpoint-md} {
    margin-top: 2%;

    gap: rem(24px) rem(44px);

    &:has(.submenu__button) {
      gap: rem(16px);
    }
  }

  a, button {
    &:not(.submenu__button) {
      @include aui-transition-base;

      --aui-link-decoration: none;
      --aui-link-decoration--hover: underline;

      color: var(--aui-color-gray--medium);
      font-weight: var(--aui-font-weight--semibold);
    
      &:hover,
      &.is-active {
        color: var(--aui-color-white);
        text-decoration: underline;
      }
    }
  }

  &--dark {
    a, button {
      &:hover,
      &.is-active {
        color: var(--aui-color-primary) !important;
        text-decoration: underline !important;
      }
    }
  }

  &__button {
    @extend .aui-button;
    @extend .aui-button--primary-outline;

    font-size: clamp(0.875rem, 0.5rem + 1vw, 1.15rem);

    padding-left: rem(20px);
    padding-right: rem(20px);

    &:not(.is-active) {
      opacity: .4;
    }

    &:hover {
      opacity: 1;
    }
  }
}