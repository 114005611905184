.footer {
  --aui-color-heading: var(--aui-color-white);
  --aui-color-text: var(--aui-color-white);
  --color-footer: var(--aui-color-white);

  position: relative;

  background-image: var(--footer-background-image);
  background-repeat: no-repeat;
  background-size: cover;

  color: var(--aui-color-white);

  padding-top: 10%;
  padding-bottom: 12%;

  &::after {
    width: 100%;
    height: 100%;

    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    content: '';

    background: rgba($aui-color-primary, .4);
  }

  & > div {
    position: relative;
    z-index: 12;
  }

  a {
    --aui-link-decoration: none;
    --aui-link-decoration--hover: underline;

    display: inline-block;
    color: var(--color-footer);
  }

  &__top {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: rem(32px);

    border-bottom: rem(1px) solid var(--color-footer);

    padding-bottom: rem(62px);

    @media #{$aui-breakpoint-md} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      padding-bottom: rem(53px);
    }

    .aui-like-h1 {
      max-width: rem(702px);

      margin: 0;
    }

    a {
      max-width: rem(64px);

      @media #{$aui-breakpoint-md} {
        max-width: rem(139px);
      }
    }
  }

  &__contacts {
    border-bottom: rem(1px) solid var(--color-footer);

    padding: rem(24px) 0;

    @media #{$aui-breakpoint-md} {
      padding-top: rem(73px);
      padding-bottom: rem(60px);
    }

    h3, .aui-like-h3 {
      display: none;

      @media #{$aui-breakpoint-md} {
        display: block;
      }

      &::before {
        background: var(--color-footer) !important;
      }
    }

    &-column {
      width: 100%;

      color: var(--color-footer);

      border-bottom: rem(1px) solid var(--color-footer);

      padding-bottom: rem(24px);
      margin-bottom: rem(24px);

      @media #{$aui-breakpoint-md} {
        width: auto;

        border-bottom: none;
      }

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }

      h4 {
        font-weight: var(--aui-font-weight--bold);
      }

      & > div {
        line-height: 1.4;
        font-size: rem(20px);

        margin-top: rem(14px);

        @media #{$aui-breakpoint-md} {
          margin-top: rem(28px);
        }
      }
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;

    padding-top: rem(20px);

    @media #{$aui-breakpoint-md} {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      gap: rem(158px) 0;

      border-bottom: rem(1px) solid var(--color-footer);

      padding-top: rem(56px);
      padding-bottom: rem(43px);
    }

    &-columns {
      order: 2;

      @media #{$aui-breakpoint-md} {
        width: 45%;

        display: flex;
        justify-content: space-between;
        order: unset;
      }

      .column {
        border-bottom: rem(1px) solid var(--aui-color-white);

        &:first-child {
          border-top: rem(1px) solid var(--aui-color-white);
        }

        @media #{$aui-breakpoint-md} {
          border: none;

          &:first-child {
            border: none;
          }
        }

        h5 {
          position: relative;

          font-size: var(--aui-font-size--sm);

          margin: 0;

          padding-right: rem(15px);
          padding-top: rem(11px);
          padding-bottom: rem(11px);

          @media #{$aui-breakpoint-md} {
            font-size: var(--aui-font-size--lg);
            font-weight: var(--aui-font-weight--bold);

            padding-right: 0;
            padding-top: 0;
            padding-bottom: 0;
          }

          &::before {
            @include aui-transition-base;

            width: rem(11px);
            height: rem(11px);

            position: absolute;
            right: 0;
            top: 50%;
            content: '';

            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMSA2Ij4KICA8cGF0aCBkPSJtLjE5NCAxLjEgNC44MzggNC43MTFhLjY3NS42NzUgMCAwIDAgLjkzNiAwbDQuODM4LTQuNzFhLjYzMy42MzMgMCAwIDAgMC0uOTEyLjY3NS42NzUgMCAwIDAtLjkzNyAwTDUuNSA0LjQ0MyAxLjEzLjE4OWEuNjc1LjY3NSAwIDAgMC0uOTM2IDAgLjYzMy42MzMgMCAwIDAgMCAuOTEyWiIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4=");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            transform: translateY(-50%);

            @media #{$aui-breakpoint-md} {
              display: none;
            }
          }
        }

        &.is-open {
          h5::before {
            transform: translateY(-50%) rotate(180deg);
          }

          .column__items {
            display: flex;
          }
        }

        &__items {
          display: none;
          flex-direction: column;

          margin-top: 0;
          padding-bottom: rem(11px);

          @media #{$aui-breakpoint-md} {
            display: flex;

            margin-top: rem(24px);
            padding-bottom: 0;
          }

          a {
            margin-bottom: rem(12px);

            @media #{$aui-breakpoint-md} {
              margin-bottom: rem(20px);
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &-form {
      order: 0;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      margin-bottom: rem(62px);

      @media #{$aui-breakpoint-md} {
        width: 50%;

        order: unset;

        margin-bottom: 0;

        & > * {
          max-width: rem(376px);
        }
      }

      h5 {
        width: 100%;

        font-size: var(--aui-font-size--sm);
        font-weight: var(--aui-font-weight--bold);

        margin: 0;
        margin-bottom: rem(14px);

        @media #{$aui-breakpoint-md} {
          font-size: var(--aui-font-size--lg);

          margin-bottom: rem(31px);
        }
      }

      p {
        width: 100%;

        color: var(--color-footer);

        margin: 0;
        margin-bottom: rem(30px);

        @media #{$aui-breakpoint-md} {
          margin-bottom: rem(44px);
        }
      }

      .aui-form {
        --aui-input-height: #{rem(40px)};
        --aui-input-border-color: var(--aui-color-white);
        --aui-input-padding--x: #{rem(18px)};
        --aui-input-color: var(--aui-color-white);
        --aui-input-placeholder-color: #{rgba($aui-color-white, .27)};
        --aui-input-border-color--focus: var(--aui-input-border-color);

        width: 100%;

        flex-wrap: nowrap;

        .aui-button {
          padding-left: rem(16px);
          padding-right: rem(16px);
        }
      }
    }

    &-legacy {
      order: 3;

      @media #{$aui-breakpoint-md} {
        display: inline-flex;
        gap: rem(36px);
        order: unset;
      }

      a {
        width: 100%;

        display: block;

        font-size: var(--aui-font-size--sm);

        border-bottom: rem(1px) solid var(--aui-color-white);

        padding-top: rem(11px);
        padding-bottom: rem(11px);

        @media #{$aui-breakpoint-md} {
          width: auto;

          display: inline-block;

          border: none;

          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    &-logos {
      max-width: rem(400px);

      margin-bottom: rem(70px);

      img {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: block;
        }
      }

      @media #{$aui-breakpoint-md} {
        width: 60%;
        max-width: rem(710px);

        margin-bottom: 0;

        img {
          &:first-child {
            display: block;
          }

          &:last-child {
            display: none;
          }
        }
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: rem(30px);

    color: var(--aui-color-white);
    font-size: var(--aui-font-size--sm);

    padding-top: rem(95px);

    @media #{$aui-breakpoint-md} {
      flex-direction: row;
      justify-content: space-between;
      gap: rem(20px);

      padding-top: rem(28px);
    }

    .socials {
      .aui-icon {
        --aui-icon-color: var(--aui-color-white);
      }
    }

    &-copyright {
      display: flex;
      justify-content: space-between;
      order: 1;

      @media #{$aui-breakpoint-md} {
        display: block;
        order: unset;
      }
    }

    &-contacts {
      display: none;

      @media #{$aui-breakpoint-md} {
        display: block;
      }

      a {
        margin: 0 rem(20px);
      }
    }

    &-socials {
      order: 0;

      @media #{$aui-breakpoint-md} {
        order: unset;
      }
    }
  }
}
