html {
  scroll-behavior: smooth;

  /* Style for Webkit-based browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: rem(12px); /* Width of the scrollbar */
    border-radius: 0;
  }

  &::-webkit-scrollbar-track {
    background: var(--aui-color-white);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--aui-color-primary);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--aui-color-primary);
    border-radius: 0;
  }

  /* Style for Firefox */
  scrollbar-color: var(--aui-color-primary) var(--aui-color-white);
  scrollbar-width: thin;

  @media #{$aui-breakpoint-xxxxl} {
    font-size: 145%;
  }
}

body {
  min-height: 100dvh;

  &:has(.header--is-open) {
    padding-top: var(--header-desktop-height);

    @media #{$aui-breakpoint-lg} {
      padding-top: 0;
    }
  }

  &.overflow-hidden {
    overflow: hidden;
  }

  &.bg-white {
    background: var(--aui-color-white);
  }
}

main {
  --aui-transition-base: all .7s ease-in-out 0ms;

  @include aui-transition-base;

  overflow: hidden;

  padding-top: var(--aui-page-offset-top);
}

section {
  margin-bottom: var(--aui-section-margin-bottom);
}

hr {
  margin: rem(48px) 0;

  &.small-margin {
    margin: rem(14px) 0 !important;
  }
}

p {
  --aui-link-decoration: underline;

  margin: rem(24px) 0;
}

ul {
  list-style: none;

  padding-left: 0;
}

ol {
  li {
    position: static;

    padding-left: 0;

    &::before {
      display: none;
    }
  }
}

li {
  position: relative;

  padding-left: rem(20px);

  &::before {
    width: rem(7px);
    height: rem(7px);

    position: absolute;
    left: 0;
    top: calc((var(--aui-line-height--sm) * var(--aui-font-size--sm) - rem(7px)) / 2);
    content: '';

    border-radius: var(--aui-border-radius--full);

    background: var(--aui-color-primary);

    @media #{$aui-breakpoint-sm} {
      top: calc((var(--aui-line-height--base) * var(--aui-font-size--sm) - rem(7px)) / 2);
    }

    @media #{$aui-breakpoint-lg} {
      top: 9px;
    }
  }

  & + li {
    margin-top: rem(16px);
  }
}

video {
  width: 100%;

  display: block;
}

[data-animation] {
  transition: all 1.5s cubic-bezier(.215, .61, .355, 1);
  &.is-enabled:not(.is-inview) {
    opacity: 0;
    transform: translateY(30px);
  }
}
