// Heartbeat
@keyframes aui-heartbeat {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.97);
  }

  35% {
    transform: scale(0.9);
  }

  45% {
    transform: scale(1.1);
  }

  55% {
    transform: scale(0.9);
  }

  65% {
    transform: scale(1.1);
  }

  75% {
    transform: scale(1.03);
  }

  100% {
    transform: scale(1);
  }
}

// FadeIn
@keyframes aui-fade-in {
  from {
    opacity: 0;
    transform: translateY(rem(20px));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
