// Variables
$aui-grid-masonry-ghost-background: $aui-color-gray !default;

@import './../css-variables/components/grid.scss';

// Styles
.aui-grid-masonry {
  &__ghost {
    @keyframes pulse {
      50% {
        opacity: .5;
      }
    }

    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    height: rem(200px);

    background: var(--aui-grid-masonry-ghost-background);


    &:nth-child(2n) {
      height: rem(380px);
    }
  }

  &--loaded {
    .aui-grid-masonry__ghost {
      display: none;
    }
  }

  &:not(.aui-grid-masonry--loaded) {
    & > *:not(.aui-grid-masonry__ghost) {
      visibility: hidden;
    }
  }

  & > * {
    transition: none;
  }
}