:root {
  --aui-button-min-width: #{rem($aui-button-min-width)};

  --aui-button-font-family: #{$aui-button-font-family};
  --aui-button-font-size: #{rem($aui-button-font-size)};
  --aui-button-font-weight: #{$aui-button-font-weight};
  --aui-button-text-transform: #{$aui-button-text-transform};
  --aui-button-letter-spacing: #{rem($aui-button-letter-spacing)};

  --aui-button-subtext-font-family: #{$aui-button-subtext-font-family};
  --aui-button-subtext-font-size: #{rem($aui-button-subtext-font-size)};
  --aui-button-subtext-font-weight: #{$aui-button-subtext-font-weight};
  --aui-button-subtext-text-transform: #{$aui-button-subtext-text-transform};

  --aui-button-text-gap: #{rem($aui-button-text-gap)};
  --aui-button-text-align-items: #{$aui-button-text-align-items};
  
  --aui-button-border-width: #{rem($aui-button-border-width)};
  --aui-button-border-type: #{$aui-button-border-type};
  --aui-button-border-radius: #{$aui-button-border-radius};

  --aui-button-padding--sm: #{rem($aui-button-padding--sm)};
  --aui-button-padding--base: #{rem($aui-button-padding--base)};
  --aui-button-padding--lg: #{rem($aui-button-padding--lg)};

  --aui-button-icon-size: #{rem($aui-button-icon-size)};
  --aui-button-icon-scale: #{rem($aui-button-icon-scale)};
  --aui-button-image-width: #{rem($aui-button-image-width)};

  // Regular
  --aui-button-regular-background: #{$aui-button-regular-background};
  --aui-button-regular-color: #{$aui-button-regular-color};
  --aui-button-regular-border-color: #{$aui-button-regular-border-color};

  --aui-button-regular-background--hover: #{$aui-button-regular-background--hover};
  --aui-button-regular-color--hover: #{$aui-button-regular-color--hover};
  --aui-button-regular-border-color--hover: #{$aui-button-regular-border-color--hover};

  --aui-button-regular-background--focus: #{$aui-button-regular-background--focus};
  --aui-button-regular-color--focus: #{$aui-button-regular-color--focus};
  --aui-button-regular-border-color--focus: #{$aui-button-regular-border-color--focus};

  --aui-button-regular-outline-background: #{$aui-button-regular-outline-background};
  --aui-button-regular-outline-color: #{$aui-button-regular-outline-color};
  --aui-button-regular-outline-border-color: #{$aui-button-regular-outline-border-color};

  --aui-button-regular-outline-background--hover: #{$aui-button-regular-outline-background--hover};
  --aui-button-regular-outline-color--hover: #{$aui-button-regular-outline-color--hover};
  --aui-button-regular-outline-border-color--hover: #{$aui-button-regular-outline-border-color--hover};

  --aui-button-regular-outline-background--focus: #{$aui-button-regular-outline-background--focus};
  --aui-button-regular-outline-color--focus: #{$aui-button-regular-outline-color--focus};
  --aui-button-regular-outline-border-color--focus: #{$aui-button-regular-outline-border-color--focus};

  --aui-button-regular-text-color: #{$aui-button-regular-text-color};

  --aui-button-regular-text-color--hover: #{$aui-button-regular-text-color--hover};
  --aui-button-regular-text-color--focus: #{$aui-button-regular-text-color--focus};

  // Primary
  --aui-button-primary-background: #{$aui-button-primary-background};
  --aui-button-primary-color: #{$aui-button-primary-color};
  --aui-button-primary-border-color: #{$aui-button-primary-border-color};

  --aui-button-primary-background--hover: #{$aui-button-primary-background--hover};
  --aui-button-primary-color--hover: #{$aui-button-primary-color--hover};
  --aui-button-primary-border-color--hover: #{$aui-button-primary-border-color--hover};

  --aui-button-primary-background--focus: #{$aui-button-primary-background--focus};
  --aui-button-primary-color--focus: #{$aui-button-primary-color--focus};
  --aui-button-primary-border-color--focus: #{$aui-button-primary-border-color--focus};

  --aui-button-primary-outline-background: #{$aui-button-primary-outline-background};
  --aui-button-primary-outline-color: #{$aui-button-primary-outline-color};
  --aui-button-primary-outline-border-color: #{$aui-button-primary-outline-border-color};

  --aui-button-primary-outline-background--hover: #{$aui-button-primary-outline-background--hover};
  --aui-button-primary-outline-color--hover: #{$aui-button-primary-outline-color--hover};
  --aui-button-primary-outline-border-color--hover: #{$aui-button-primary-outline-border-color--hover};

  --aui-button-primary-outline-background--focus: #{$aui-button-primary-outline-background--focus};
  --aui-button-primary-outline-color--focus: #{$aui-button-primary-outline-color--focus};
  --aui-button-primary-outline-border-color--focus: #{$aui-button-primary-outline-border-color--focus};

  --aui-button-primary-text-color: #{$aui-button-primary-text-color};

  --aui-button-primary-text-color--hover: #{$aui-button-primary-text-color--hover};
  --aui-button-primary-text-color--focus: #{$aui-button-primary-text-color--focus};

  // Secondary
  --aui-button-secondary-background: #{$aui-button-secondary-background};
  --aui-button-secondary-color: #{$aui-button-secondary-color};
  --aui-button-secondary-border-color: #{$aui-button-secondary-border-color};

  --aui-button-secondary-background--hover: #{$aui-button-secondary-background--hover};
  --aui-button-secondary-color--hover: #{$aui-button-secondary-color--hover};
  --aui-button-secondary-border-color--hover: #{$aui-button-secondary-border-color--hover};

  --aui-button-secondary-background--focus: #{$aui-button-secondary-background--focus};
  --aui-button-secondary-color--focus: #{$aui-button-secondary-color--focus};
  --aui-button-secondary-border-color--focus: #{$aui-button-secondary-border-color--focus};

  --aui-button-secondary-outline-background: #{$aui-button-secondary-outline-background};
  --aui-button-secondary-outline-color: #{$aui-button-secondary-outline-color};
  --aui-button-secondary-outline-border-color: #{$aui-button-secondary-outline-border-color};

  --aui-button-secondary-outline-background--hover: #{$aui-button-secondary-outline-background--hover};
  --aui-button-secondary-outline-color--hover: #{$aui-button-secondary-outline-color--hover};
  --aui-button-secondary-outline-border-color--hover: #{$aui-button-secondary-outline-border-color--hover};

  --aui-button-secondary-outline-background--focus: #{$aui-button-secondary-outline-background--focus};
  --aui-button-secondary-outline-color--focus: #{$aui-button-secondary-outline-color--focus};
  --aui-button-secondary-outline-border-color--focus: #{$aui-button-secondary-outline-border-color--focus};

  --aui-button-secondary-text-color: #{$aui-button-secondary-text-color};

  --aui-button-secondary-text-color--hover: #{$aui-button-secondary-text-color--hover};
  --aui-button-secondary-text-color--focus: #{$aui-button-secondary-text-color--focus};

  // Danger
  --aui-button-danger-background: #{$aui-button-danger-background};
  --aui-button-danger-color: #{$aui-button-danger-color};
  --aui-button-danger-border-color: #{$aui-button-danger-border-color};

  --aui-button-danger-background--hover: #{$aui-button-danger-background--hover};
  --aui-button-danger-color--hover: #{$aui-button-danger-color--hover};
  --aui-button-danger-border-color--hover: #{$aui-button-danger-border-color--hover};

  --aui-button-danger-background--focus: #{$aui-button-danger-background--focus};
  --aui-button-danger-color--focus: #{$aui-button-danger-color--focus};
  --aui-button-danger-border-color--focus: #{$aui-button-danger-border-color--focus};

  --aui-button-danger-outline-background: #{$aui-button-danger-outline-background};
  --aui-button-danger-outline-color: #{$aui-button-danger-outline-color};
  --aui-button-danger-outline-border-color: #{$aui-button-danger-outline-border-color};

  --aui-button-danger-outline-background--hover: #{$aui-button-danger-outline-background--hover};
  --aui-button-danger-outline-color--hover: #{$aui-button-danger-outline-color--hover};
  --aui-button-danger-outline-border-color--hover: #{$aui-button-danger-outline-border-color--hover};

  --aui-button-danger-outline-background--focus: #{$aui-button-danger-outline-background--focus};
  --aui-button-danger-outline-color--focus: #{$aui-button-danger-outline-color--focus};
  --aui-button-danger-outline-border-color--focus: #{$aui-button-danger-outline-border-color--focus};

  --aui-button-danger-text-color: #{$aui-button-danger-text-color};

  --aui-button-danger-text-color--hover: #{$aui-button-danger-text-color--hover};
  --aui-button-danger-text-color--focus: #{$aui-button-danger-text-color--focus};

  // Error
  --aui-button-error-background: #{$aui-button-error-background};
  --aui-button-error-color: #{$aui-button-error-color};
  --aui-button-error-border-color: #{$aui-button-error-border-color};

  --aui-button-error-background--hover: #{$aui-button-error-background--hover};
  --aui-button-error-color--hover: #{$aui-button-error-color--hover};
  --aui-button-error-border-color--hover: #{$aui-button-error-border-color--hover};

  --aui-button-error-background--focus: #{$aui-button-error-background--focus};
  --aui-button-error-color--focus: #{$aui-button-error-color--focus};
  --aui-button-error-border-color--focus: #{$aui-button-error-border-color--focus};

  --aui-button-error-outline-background: #{$aui-button-error-outline-background};
  --aui-button-error-outline-color: #{$aui-button-error-outline-color};
  --aui-button-error-outline-border-color: #{$aui-button-error-outline-border-color};

  --aui-button-error-outline-background--hover: #{$aui-button-error-outline-background--hover};
  --aui-button-error-outline-color--hover: #{$aui-button-error-outline-color--hover};
  --aui-button-error-outline-border-color--hover: #{$aui-button-error-outline-border-color--hover};

  --aui-button-error-outline-background--focus: #{$aui-button-error-outline-background--focus};
  --aui-button-error-outline-color--focus: #{$aui-button-error-outline-color--focus};
  --aui-button-error-outline-border-color--focus: #{$aui-button-error-outline-border-color--focus};

  --aui-button-error-text-color: #{$aui-button-error-text-color};

  --aui-button-error-text-color--hover: #{$aui-button-error-text-color--hover};
  --aui-button-error-text-color--focus: #{$aui-button-error-text-color--focus};

  // Success
  --aui-button-success-background: #{$aui-button-success-background};
  --aui-button-success-color: #{$aui-button-success-color};
  --aui-button-success-border-color: #{$aui-button-success-border-color};

  --aui-button-success-background--hover: #{$aui-button-success-background--hover};
  --aui-button-success-color--hover: #{$aui-button-success-color--hover};
  --aui-button-success-border-color--hover: #{$aui-button-success-border-color--hover};

  --aui-button-success-background--focus: #{$aui-button-success-background--focus};
  --aui-button-success-color--focus: #{$aui-button-success-color--focus};
  --aui-button-success-border-color--focus: #{$aui-button-success-border-color--focus};

  // Info
  --aui-button-info-background: #{$aui-button-info-background};
  --aui-button-info-color: #{$aui-button-info-color};
  --aui-button-info-border-color: #{$aui-button-info-border-color};

  --aui-button-info-background--hover: #{$aui-button-info-background--hover};
  --aui-button-info-color--hover: #{$aui-button-info-color--hover};
  --aui-button-info-border-color--hover: #{$aui-button-info-border-color--hover};

  --aui-button-info-background--focus: #{$aui-button-info-background--focus};
  --aui-button-info-color--focus: #{$aui-button-info-color--focus};
  --aui-button-info-border-color--focus: #{$aui-button-info-border-color--focus};

  // Warning
  --aui-button-warning-background: #{$aui-button-warning-background};
  --aui-button-warning-color: #{$aui-button-warning-color};
  --aui-button-warning-border-color: #{$aui-button-warning-border-color};

  --aui-button-warning-background--hover: #{$aui-button-warning-background--hover};
  --aui-button-warning-color--hover: #{$aui-button-warning-color--hover};
  --aui-button-warning-border-color--hover: #{$aui-button-warning-border-color--hover};

  --aui-button-warning-background--focus: #{$aui-button-warning-background--focus};
  --aui-button-warning-color--focus: #{$aui-button-warning-color--focus};
  --aui-button-warning-border-color--focus: #{$aui-button-warning-border-color--focus};
}