.slider-category {
  display: flex;
  flex-direction: column;
  gap: rem(32px);
  align-items: stretch;

  @media #{$aui-breakpoint-lg} {
    flex-direction: row;
    align-items: stretch;

    gap: rem(56px);
  }

  &__info {
    width: 100%;

    @media #{$aui-breakpoint-lg} {
      width: 50%;
      max-width: rem(332px);

      .categories__items {
        margin-top: rem(-35px);
      }
    }
  }

  &__slider {
    width: 100%;

    &-slide {
      height: 100%;

      display: flex;
      flex-direction: column;
      gap: 4%;

      @media #{$aui-breakpoint-sm} {
        flex-direction: row;
      }

      .aui-image {
        @media #{$aui-breakpoint-sm} {
          max-width: rem(360px);
        }

        img {
          height: 100%;
          width: 100%;

          object-fit: cover;
        }
      }

      & > div:not(.aui-image) {
        h5, .aui-like-h5 {
          font-weight: var(--aui-font-weight--bold);

          @media #{$aui-breakpoint-md} {
            font-size: rem(20px);
          }
        }

        @media #{$aui-breakpoint-sm} {
          max-width: rem(284px);
        }

        display: flex;
        flex-direction: column;

        p {
          flex: 1;
        }
      } 
    }
  }
}

.aui-line:has(.slider-category) {
  padding-top: var(--aui-section-margin-bottom);
  margin-top: calc(var(--aui-section-margin-bottom) * -1);
}

