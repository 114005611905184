.text-with-image {
  --image-fallback: 0;

  display: flex;
  flex-direction: column-reverse;
  gap: rem(36px);

  @media #{$aui-breakpoint-md} {
    --image-fallback: #{rem(60px)};

    flex-direction: row;
    gap: var(--sections-gap-x);
  }

  &--without-fallback {
    --image-fallback: 0;

    .text-with-image__info {
      @media #{$aui-breakpoint-lg} {
        h2, .aui-like-h2 {
          --aui-headers-margin-coeficient: 1;
        }
      }
    }
  }

  &:first-child {
    padding-top: var(--image-fallback);

    .text-with-image__image {
      margin-top: calc(-1 * var(--image-fallback));
    }
  }

  &__info,
  &__image {
    width: 100%;

    flex-grow: 1;
  }

  &__info {
    height: max-content;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media #{$aui-breakpoint-lg} {
      flex-direction: row;

      h2, .aui-like-h2 {
        width: 100%;
        
        order: unset !important;
  
        --aui-headers-margin-coeficient: 0.6;
      }
    }

    h2, .aui-like-h2 {
      order: 1;

      span {
        max-width: rem(588px);

        display: inline-block;
      }
    }

    & > h3, & > .aui-like-h3 {
      &:not(:empty) {
        @include titleList;
      }

      &:empty {
        margin-bottom: 0;
      }

      width: 50%;
      max-width: rem(197px); // prev value 165px

      order: 0;

      margin-top: 0;

      @media #{$aui-breakpoint-lg} {
        order: unset;
      }
    }

    p, .aui-button {
      margin-bottom: var(--aui-margin--base);
    }

    & > div {
      order: 2;

      @media #{$aui-breakpoint-lg} {
        order: unset;
      }
    }

    &:has(h3, .aui-like-h3) {
      & > div {
        @media #{$aui-breakpoint-lg} {
          width: 60%;
        }
      }
    }
  }

  &:not(:has(.text-with-image__image)),
  &:not(:has(.text-with-image__image .aui-image)) { // Without image
    --image-fallback: 0;

    .text-with-image__image {
      display: none;
    }

    .text-with-image__info {
      & > div {
        display: flex;
        flex-wrap: wrap;
        gap: rem(24px) 10%;

        @media #{$aui-breakpoint-lg} {
          width: 65%;
        }

        p {
          width: 100%;

          margin: 0;
        }

        &:not(.flex-grow) {
          p {
            @media #{$aui-breakpoint-md} {
              width: 45%;
            }
          }
        }
      }
    }
  }

  &:has(.text-with-image__image .aui-image) {
    .text-with-image__info {
      h3:empty {
        display: none;
      }
    }
  }

  &:has(.text-with-image__info h2) { // With big title
    .text-with-image__image {
      margin-top: calc(-1 * var(--image-fallback) * 1.5);
    }
  }

  &__image {
    margin-top: calc(-1 * var(--image-fallback));

    .aui-image {
      margin-bottom: rem(30px);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.aui-image--overlay:before {
  content: attr(data-aui-over-count);
}