
// Variables
$footer-top-gap: 32px !default;
$footer-top-background: $aui-color-white !default;
$footer-top-padding: 32px 0 !default;

$footer-top-logo-width: 200px !default;

$footer-top-navigation-gap: 24px !default;
$footer-top-navigation-column-gap: 16px !default;

$footer-top-contacts-gap: 24px !default;

$footer-top-socials-gap: 24px !default;

$footer-bottom-gap: 32px !default;
$footer-bottom-background: inherit !default;
$footer-bottom-padding: 16px 0 !default;

@import "../css-variables/layout/footer.scss";

// Styles
.aui-footer {
  .aui-footer-top {
    background: var(--footer-top-background);

    padding: var(--footer-top-padding);

    & > .aui-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: var(--footer-top-gap);

      @media #{$aui-breakpoint-lg} {
        flex-direction: row;
      }

      @media #{$aui-breakpoint-lg} {
        flex-wrap: nowrap;
      }
    }

    &__logo {
      &-element {
        width: var(--footer-top-logo-width);
      }
    }

    &__navigation {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: var(--footer-top-navigation-gap);

      &-column {
        display: inline-flex;
        flex-direction: column;
        gap: var(--footer-top-navigation-column-gap);

        a {
          --aui-link-decoration: none;
          --aui-link-decoration--hover: underline;

          width: max-content;

          display: inline-block;
        }
      }
    }

    &__contacts {
      display: flex;
      flex-direction: column;
      gap: var(--footer-top-contacts-gap);
    }

    &__socials {
      display: flex;
      flex-direction: column;
      gap: var(--footer-top-socials-gap);
    }
  }

  .aui-footer-bottom {
    background: var(--footer-bottom-background);

    padding: var(--footer-bottom-padding);

    & > .aui-container {
      display: flex;
      justify-content: space-between;
      gap: var(--footer-bottom-gap);
    }

    &__creator {
      display: inline-flex;
      align-items: center;
      gap: rem(8px);
    }
  }
}