.user {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: rem(16px);

  &__avatar {
    width: rem(40px);
    height: rem(40px);
  }

  &__info {
    font-size: var(--aui-font-size--base);
  }
}
