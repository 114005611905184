:root {
  --aui-badge-gap: #{rem($aui-badge-gap)};
  --aui-badge-font-weight: #{$aui-badge-font-weight};
  --aui-badge-font-size: #{rem($aui-badge-font-size)};
  --aui-badge-border-width: #{rem($aui-badge-border-width)};
  --aui-badge-border-type: #{$aui-badge-border-type};
  --aui-badge-border-radius: #{rem($aui-badge-border-radius)};
  --aui-badge-padding: #{rem($aui-badge-padding)};

  --aui-badge-icon-color: #{$aui-badge-icon-color};
  --aui-badge-icon-background: #{$aui-badge-icon-background};
  --aui-badge-icon-size: #{rem($aui-badge-icon-size)};

  --aui-badge-content-color: #{$aui-badge-content-color};
  --aui-badge-content-background: #{$aui-badge-content-background};
}