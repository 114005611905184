:root {
  --aui-full: #{$aui-full};
  --aui-half: #{$aui-half};
  --aui-third: #{$aui-third};
  --aui-two-thirds: #{$aui-two-thirds};
  --aui-quarter: #{$aui-quarter};

  --aui-border-radius--none: #{$aui-border-radius--none};
  --aui-border-radius--sm: #{rem($aui-border-radius--sm)};
  --aui-border-radius--base: #{rem($aui-border-radius--base)};
  --aui-border-radius--md: #{rem($aui-border-radius--md)};
  --aui-border-radius--lg: #{rem($aui-border-radius--lg)};
  --aui-border-radius--full: #{rem($aui-border-radius--full)};

  --aui-box-shadow--none: #{$aui-box-shadow--none};
  --aui-box-shadow--sm: #{$aui-box-shadow--sm};
  --aui-box-shadow--base: #{$aui-box-shadow--base};
  --aui-box-shadow--md: #{$aui-box-shadow--md};
  --aui-box-shadow--lg: #{$aui-box-shadow--lg};

  --aui-grid-gap--x: #{rem($aui-grid-gap--x)};
  --aui-grid-gap--y: #{rem($aui-grid-gap--y)};
  --aui-grid-col-max-width: #{rem($aui-grid-col-max-width)};

  --aui-gap--none: #{rem($aui-gap--none)};
  --aui-gap--xs: #{rem($aui-gap--xs)};
  --aui-gap--sm: #{rem($aui-gap--sm)};
  --aui-gap--base: #{rem($aui-gap--base)};
  --aui-gap--md: #{rem($aui-gap--md)};
  --aui-gap--lg: #{rem($aui-gap--lg)};
  --aui-gap--xl: #{rem($aui-gap--xl)};

  --aui-padding--none: #{rem($aui-padding--none)};
  --aui-padding--xs: #{rem($aui-padding--xs)};
  --aui-padding--sm: #{rem($aui-padding--sm)};
  --aui-padding--base: #{rem($aui-padding--base)};
  --aui-padding--md: #{rem($aui-padding--md)};
  --aui-padding--lg: #{rem($aui-padding--lg)};
  --aui-padding--xl: #{rem($aui-padding--xl)};

  --aui-margin--none: #{rem($aui-margin--none)};
  --aui-margin--xs: #{rem($aui-margin--xs)};
  --aui-margin--sm: #{rem($aui-margin--sm)};
  --aui-margin--base: #{rem($aui-margin--base)};
  --aui-margin--md: #{rem($aui-margin--md)};
  --aui-margin--lg: #{rem($aui-margin--lg)};
  --aui-margin--xl: #{rem($aui-margin--xl)};
}