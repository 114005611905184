// Variables
$aui-badge-gap: 0 !default;
$aui-badge-font-size: $aui-font-size--sm !default;
$aui-badge-font-weight: $aui-font-weight--base !default;
$aui-badge-text-transform: uppercase !default;
$aui-badge-border-width: 1px !default;
$aui-badge-border-type: solid !default;
$aui-badge-border-radius: $aui-border-radius--sm !default;
$aui-badge-padding: $aui-padding--xs !default;

$aui-badge-icon-color: inherit !default;
$aui-badge-icon-background: transparent !default;
$aui-badge-icon-size: $aui-badge-font-size !default;

$aui-badge-content-color: inherit !default;
$aui-badge-content-background: transparent !default;

@import "./../css-variables/components/badge.scss";

// Styles
.aui-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--aui-badge-gap);

  font-size: var(--aui-badge-font-size);
  font-weight: var(--aui-badge-font-weight);
  text-transform: var(--aui-badge-text-transform);
  text-decoration: none;
  line-height: 1;

  cursor: default;
  pointer-events: none;

  border-radius: var(--aui-badge-border-radius);
  overflow: hidden;

  padding: 0;

  &--circle {
    border-radius: 50%;
    aspect-ratio: 1 / 1;
  }

  &--rounded {
    border-radius: var(--aui-rounded-full);
  }

  .aui-icon {
    color: var(--aui-badge-icon-color);
    background: var(--aui-badge-icon-background);

    padding: var(--aui-badge-padding);

    svg, i {
      width: var(--aui-badge-icon-size);
      height: var(--aui-badge-icon-size);
    }
  }

  &__content {
    color: var(--aui-badge-content-color);
    background: var(--aui-badge-content-background);

    padding: var(--aui-badge-padding);

    &--text {
      padding: 0;
    }
  }
}