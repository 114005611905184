.aui-form {
  &__response {
    --aui-form-response-border-radius: 0;
    --aui-form-response-border-width: 0;
    --aui-form-response-background: var(--aui-color-gray);
  }

  .aui-form-item {
    &--row {
      @media #{$aui-breakpoint-md} {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &--quarter {
      @media #{$aui-breakpoint-md} {
        width: calc(var(--aui-quarter) - var(--aui-form-gap) / 1.33) !important;
      }
    }

    &--no-margin {
      margin-bottom: 0;
    }
  }
}
