.thanks {
  display: flex;
  flex-direction: column;
  gap: rem(24px);

  @media #{$aui-breakpoint-md} {
    flex-direction: row;
    justify-content: space-between;
  }

  &__info {
    width: 100%;
    max-width: rem(735px);

    p {
      max-width: rem(592px);
    }
  }

  &__button {
    display: flex;
    align-items: center;
    gap: rem(18px);

    @media #{$aui-breakpoint-md} {
      align-items: flex-start;
      justify-content: flex-end;
    }

    .aui-button {
      height: rem(40px);
    }
  }
}