.aui-hero-section {
  position: relative;

  &__img {
    width: 100%;
    height: 100dvh;
    min-height: rem(600px);

    img {
      height: 100%;

      object-fit: cover;
    }
  }

  &__content {
    width: 100%;
    height: 100%;

    position: absolute;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba($aui-color-black, 0.4);

    &-title,
    &-perex {
      text-align: center;
      color: $aui-color-white;
    }
  }
}