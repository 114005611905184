:root {
  --aui-color-white: #{$aui-color-white};
  --aui-color-black: #{$aui-color-black};

  --aui-color-background: #{$aui-color-background};

  --aui-color-primary: #{$aui-color-primary};
  --aui-color-secondary: #{$aui-color-secondary};

  --aui-color-dark: #{$aui-color-dark};

  --aui-color-text: #{$aui-color-text};
  
  --aui-color-gray: #{$aui-color-gray};

  --aui-color-red: #{$aui-color-red};
  --aui-color-green: #{$aui-color-green};
  --aui-color-blue: #{$aui-color-blue};
  --aui-color-orange: #{$aui-color-orange};

  --aui-color-success: #{$aui-color-success};
  --aui-color-info: #{$aui-color-info};
  --aui-color-warning: #{$aui-color-warning};
  --aui-color-danger: #{$aui-color-danger};
  --aui-color-error: #{$aui-color-error};

  --aui-color-disabled-background: #{$aui-color-disabled-background};
  --aui-color-disabled-color: #{$aui-color-disabled-color};
  --aui-color-disabled-border-color: #{$aui-color-disabled-border-color};

  --aui-color-heading: #{$aui-color-heading};

  --aui-color-border: #{$aui-color-border};

  --aui-color-link: #{$aui-color-link};

  --aui-color-list--ol: #{$aui-color-list--ol};
  --aui-color-list--ul: #{$aui-color-list--ul};
}