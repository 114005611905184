// Variables
$aui-product-block-gap: $aui-gap--base !default;
$aui-product-block-border-width: 1px !default;
$aui-product-block-border-type: solid !default;
$aui-product-block-border-color: $aui-color-border !default;
$aui-product-block-border-radius: $aui-border-radius--md !default;
$aui-product-block-box-shadow: none !default;

$aui-product-block-flags-gap: $aui-gap--xs $aui-gap--sm !default;
$aui-product-block-flags-translate-y: -15px !default;
$aui-product-block-flags-padding: $aui-padding--base !default;

$aui-product-block-image-padding: $aui-padding--base !default;

$aui-product-block-content-padding: $aui-padding--base !default;

$aui-product-block-content-description-font-size: inherit !default;

$aui-product-block-content-tags-gap: $aui-gap--sm !default;

@import "./../css-variables/components/product-block.scss";

// Styles
.aui-product-block {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: var(--aui-product-block-gap);

  position: relative;

  background: var(--aui-product-block-background);
  border: var(--aui-product-block-border-width) var(--aui-product-block-border-type)  var(--aui-product-block-border-color);
  border-radius: var(--aui-product-block-border-radius);

  box-shadow: var(--aui-product-block-box-shadow);

  text-decoration: none;

  @media #{$aui-breakpoint-sm} {
    &--row {
      flex-direction: row;

      .aui-product-block__image {
        flex-shrink: 0.5;

        padding: var(--aui-product-block-image-padding);
        padding-right: 0;
      }

      .aui-product-block__content {
        padding: var(--aui-product-block-content-padding);
        padding-left: 0;
      }
    }

    &--row-reverse {
      flex-direction: row-reverse;

      .aui-product-block__image {
        padding: var(--aui-product-block-image-padding);
        padding-left: 0;
      }

      .aui-product-block__content {
        padding: var(--aui-product-block-content-padding);
        padding-right: 0;
      }
    }
  }

  &__flags {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    gap: var(--aui-product-block-flags-gap);

    position: absolute;
    left: 0;
    top: 0;
    z-index: 12;

    &--col {
      flex-direction: column;
    }

    &--left {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }

    &--padding {
      padding: var(--aui-product-block-flags-padding);
    }

    &--translate-y {
      transform: translateY(var(--aui-product-block-flags-translate-y));
    }
  }

  &__image {
    padding: var(--aui-product-block-image-padding);
    padding-bottom: 0;

    img {
      @include aui-transition-base;
    }
  }

  &__content {
    height: 100%;

    display: flex;
    flex-direction: column;
  
    padding: var(--aui-product-block-content-padding);
    padding-top: 0;

    & > *:last-child {
      margin-bottom: 0;
    }

    &-title {
      margin-top: 0;
    }

    &-subtitle {
      margin-top: 0;
    }

    &-description {
      font-size: var(--aui-product-block-content-description-font-size);

      margin-bottom: var(--aui-margin--sm);
    }

    &-price {
      margin-bottom: var(--aui-margin--sm);
    }

    &-tags {
      display: flex;
      flex-wrap: wrap;
      gap: var(--aui-product-block-content-tags-gap);

      margin-bottom: var(--aui-margin--sm);

      &--left {
        justify-content: flex-start;
      }
  
      &--center {
        justify-content: center;
      }
  
      &--right {
        justify-content: flex-end;
      }
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 1;
      gap: var(--aui-gap--sm);

      margin-bottom: var(--aui-margin--sm);

      .aui-button {
        width: 100%;
      }

      &--row {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;

        .aui-button {
          width: max-content;
        }
      }
    }
  }

  &:hover {
    .aui-product-block__image {
      img {
        transform: scale(var(--aui-image-scale));
      }
    }

    .aui-product-block__content-title {
      text-decoration: underline;
    }
  }
}