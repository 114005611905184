.aui-gallery-section {
 &__content {
    &--dual {
      &[data-aui-gallery-items="2"] {
        .aui-image {
          &:nth-child(2) {
            background: none;
            grid-column: span 2;
            display: flex;
            align-items: center;

            @media #{$aui-breakpoint-md} {
              grid-row: span 2;
              grid-column: span 1;

              img {
                aspect-ratio: 5 / 4;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
 }
}