@import '/node_modules/toastify-js/src/toastify.css';

// Styles
.aui-toast {
  &--success {
    background: var(--aui-color-success);
  }

  &--warning {
    background: var(--aui-color-warning);
  }

  &--danger {
    background: var(--aui-color-danger);
  }

  &--error {
    background: var(--aui-color-error);
  }

  &--info {
    background: var(--aui-color-info);
  }

  .toast-close {
    color: var(--aui-color-white);
    opacity: 1;
    margin-left: rem(8px);
  }
}