.offer {
    .content-block {
        &__content {
            &-images {
                .aui-image {
                    display: none;

                    &:first-of-type {
                        display: block;
                    }
                }

                @media #{$aui-breakpoint-md} {
                    .aui-image {
                        display: block;
                    }
                }
            }
        }
    }
}
