// Variables
$aui-video-border-radius: $aui-border-radius--md !default;
$aui-video-aspect-ratio: "16 / 9" !default;

$aui-video-overlay-background: rgba($aui-color-primary, .4) !default;
$aui-video-overlay-color: $aui-color-white !default;
$aui-video-overlay-font-size: $aui-font-size--4xl !default;
$aui-video-overlay-font-weight: $aui-font-weight--bold !default;
$aui-video-overlay-content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAyOSAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjggMTQuNzY4QzI5LjMzMzMgMTUuNTM3OCAyOS4zMzMzIDE3LjQ2MjIgMjggMTguMjMyTDMuMjQ5OTkgMzIuNTIxNUMxLjkxNjY2IDMzLjI5MTMgMC4yNDk5OTggMzIuMzI5IDAuMjQ5OTk4IDMwLjc4OTRMMC4yNSAyLjIxMDU3QzAuMjUgMC42NzA5NzMgMS45MTY2NyAtMC4yOTEyNzIgMy4yNSAwLjQ3ODUyOUwyOCAxNC43NjhaIiBmaWxsPSIjZmZmIi8+PC9zdmc+Cg==) !default;
$aui-video-overlay-width: 30px !default;
$aui-video-overlay-height: 30px !default;

@import "./../css-variables/components/video.scss";

// Styles
.aui-video {
  overflow: hidden;

  border-radius: var(--aui-video-border-radius);

  &__video {
    width: 100%;
    aspect-ratio: var(--aui-video-aspect-ratio);
  }

  &[data-aui-video="thumbnail-start"] {
    --aui-image-overlay-background: var(--aui-video-overlay-background);

    cursor: pointer;

    position: relative;

    &::after {
      @include aui-transition-base;

      width: var(--aui-video-overlay-width);
      height: var(--aui-video-overlay-height);

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      left: 50%;
      top: 50%;
      content: '';
      z-index: 12;

      background: var(--aui-video-overlay-content);
      background-repeat: no-repeat;
      background-size: contain;
        
      color: var(--aui-video-overlay-color);

      transform: translateX(-50%) translateY(-50%);
    }

    &:hover {
      &::after {
        transform: translateX(-50%) translateY(-50%) scale(1.1);
      }
    }
  }
}