:root {
  --aui-timeline-gap: #{rem($aui-timeline-gap)};

  --aui-timeline-item-gap: #{rem($aui-timeline-item-gap)};
  --aui-timeline-item-max-width--row: #{rem($aui-timeline-item-max-width--row)};
  --aui-timeline-item-max-width--col: #{rem($aui-timeline-item-max-width--col)};
  --aui-timeline-item-mark-gap: #{rem($aui-timeline-item-mark-gap)};
  --aui-timeline-item-mark-size: #{rem($aui-timeline-item-mark-size)};
  --aui-timeline-item-mark-background: #{$aui-timeline-item-mark-background};
  --aui-timeline-item-mark-color: #{$aui-timeline-item-mark-color};
  --aui-timeline-item-mark-font-weight: #{$aui-timeline-item-mark-font-weight};
  --aui-timeline-item-mark-border-radius: #{$aui-timeline-item-mark-border-radius};
  --aui-timeline-item-mark-border-width: #{rem($aui-timeline-item-mark-border-width)};
  --aui-timeline-item-mark-border-type: #{$aui-timeline-item-mark-border-type};
  --aui-timeline-item-mark-border-color: #{$aui-timeline-item-mark-border-color};
}
