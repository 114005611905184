:root {
  --aui-video-border-radius: #{rem($aui-video-border-radius)};
  --aui-video-aspect-ratio: #{$aui-video-aspect-ratio};

  --aui-video-overlay-background: #{$aui-video-overlay-background};
  --aui-video-overlay-color: #{$aui-video-overlay-color};
  --aui-video-overlay-font-size: #{rem($aui-video-overlay-font-size)};
  --aui-video-overlay-font-weight: #{$aui-video-overlay-font-weight};
  --aui-video-overlay-content: #{$aui-video-overlay-content};
  --aui-video-overlay-width: #{rem($aui-video-overlay-width)};
  --aui-video-overlay-height: #{rem($aui-video-overlay-height)};
}