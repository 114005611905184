// Variables
$aui-form-gap: 24px !default;

$aui-form-item-gap: 4px !default;

$aui-form-response-border-radius: $aui-border-radius--md !default;
$aui-form-response-background: $aui-color-white !default;
$aui-form-response-color: inherit !default;
$aui-form-response-border-width: 1px !default;
$aui-form-response-border-type: solid !default;
$aui-form-response-border-color: $aui-color-border !default;
$aui-form-response-padding: $aui-padding--md !default;

$aui-form-response-icon-color: $aui-color-primary !default;
$aui-form-response-icon-max-size: 100px !default;

@import './../css-variables/components/form';

// Styles
.aui-form {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  gap: 0 var(--aui-form-gap);

  @media #{$aui-breakpoint-md} {
    .aui-form-item {
      width: calc(50% - var(--aui-form-gap) / 2);

      &--full {
        width: var(--aui-full) !important;
      }
  
      &--half {
        width: calc(var(--aui-half) - var(--aui-form-gap) / 2) !important;
      }
  
      &--third {
        width: calc(var(--aui-third) - var(--aui-form-gap) / 2) !important;
      }
  
      &--two-thirds {
        width: calc(var(--aui-two-thirds) - var(--aui-form-gap) / 2) !important;
      }
  
      &--quarter {
        width: calc(var(--aui-quarter) - var(--aui-form-gap) / 2) !important;
      }
    }
  }

  &__response {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    position: absolute;
    left: 0;
    top: 0;
    z-index: 12;

    background: var(--aui-form-response-background);
    border-radius: var(--aui-form-response-border-radius);
    border: var(--aui-form-response-border-width) var(--aui-form-response-border-type) var(--aui-form-response-border-color);

    padding: var(--aui-form-response-padding);

    * {
      color: var(--aui-form-response-color);
    }

    &-icon {
      max-height: var(--aui-form-response-icon-max-size);
      min-height: 30px;
      height: 100%;

      color: var(--aui-form-response-icon-color);

      margin-bottom: var(--aui-margin--base);

      svg, i {
        width: auto;
        height: 100%;
      }
    }
  }
}

.aui-form-item {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: var(--aui-form-item-gap);

  margin-bottom: var(--aui-form-gap);

  @media #{$aui-breakpoint-md} {
    &--full {
      width: var(--aui-full);
    }

    &--half {
      width: var(--aui-half);
    }

    &--third {
      width: var(--aui-third);
    }

    &--two-thirds {
      width: var(--aui-two-thirds);
    }

    &--quarter {
      width: var(--aui-quarter);
    }
  }

  &.is-invalid {
    input {
      border-color: var(--aui-color-error);
    }

    .error-message {
      display: block;
    }
  }

  .aui-input-description {
    color: var(--aui-color-gray);
    font-size: var(--aui-font-size--sm);
  }

  .error-message {
    display: none;

    color: var(--aui-color-error);
    font-size: var(--aui-font-size--sm);
  }
}