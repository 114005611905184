:root {
  --aui-container-width: #{rem($aui-container-width)};

  --aui-container-max-width--xs: #{rem($aui-container-max-width--xs)};
  --aui-container-max-width--sm: #{rem($aui-container-max-width--sm)};
  --aui-container-max-width--base: #{rem($aui-container-max-width--base)};
  --aui-container-max-width--lg: #{rem($aui-container-max-width--lg)};
  --aui-container-max-width--xl: #{rem($aui-container-max-width--xl)};

  --aui-container-fluid-padding: #{rem($aui-container-fluid-padding)};
}