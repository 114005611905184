:root {
  --aui-signpost-background: #{$aui-signpost-background};
  --aui-signpost-color: #{$aui-signpost-color};
  --aui-signpost-border-radius: #{rem($aui-signpost-border-radius)};
  --aui-signpost-border-width: #{rem($aui-signpost-border-width)};
  --aui-signpost-border-type: #{$aui-signpost-border-type};
  --aui-signpost-border-color: #{$aui-signpost-border-color};
  --aui-signpost-padding: #{rem($aui-signpost-padding)};

  
  --aui-signpost-color--hover: #{$aui-signpost-color--hover};
  --aui-signpost-background--hover: #{$aui-signpost-background--hover};
  --aui-signpost-border-color--hover: #{$aui-signpost-border-color--hover};

  --aui-signpost-icon-color: #{$aui-signpost-icon-color};
  --aui-signpost-icon-color--hover: #{$aui-signpost-icon-color--hover};
  --aui-signpost-icon-size: #{rem($aui-signpost-icon-size)};

  --aui-signpost-link-color: #{$aui-signpost-link-color};
  --aui-signpost-link-color--hover: #{$aui-signpost-link-color--hover};
}