.logo-hero {
  width: rem(64px);

  color: var(--aui-color-white);

  margin-bottom: rem(32px);

  @media #{$aui-header-mobile-breakpoint} {
    display: none;
  } 

  &--dark {
    color: var(--aui-color-primary);
  }
}