.aui-button {
  &--lg {
    font-size: var(--aui-font-size--base);

    padding: var(--aui-button-padding--base);

    @media #{$aui-breakpoint-md} {
      padding: var(--aui-button-padding--lg);
    }
  }

  &--primary-outline {
    &:hover {
      .aui-icon {
        svg {
          color: var(--aui-button-primary-outline-color--hover);
        }
      }
    }
  }
}