// Font Size
$aui-font-size--4xl: clamp(1.688rem, 1.35rem + 1.13vw, 2.25rem) !default; // 27px to 36px H1
$aui-font-size--3xl: clamp(1.5rem, 1.275rem + 0.75vw, 1.875rem) !default; // 24px to 30px H2
$aui-font-size--2xl: clamp(1.25rem, 1.1rem + 0.5vw, 1.5rem) !default; // 20px to 24px H3
$aui-font-size--xl: clamp(1.125rem, 1.05rem + 0.25vw, 1.25rem) !default; // 18px to 20px H4
$aui-font-size--lg: clamp(1rem, 0.925rem + 0.25vw, 1.125rem) !default; // 16px to 18px H5
$aui-font-size--base: 16px !default;
$aui-font-size--sm: 14px !default;
$aui-font-size--xs: 12px !default;

// Font Weight
$aui-font-weight--thin: 300 !default;
$aui-font-weight--base: 400 !default;
$aui-font-weight--semi-bold: 600 !default;
$aui-font-weight--bold: 700 !default;
$aui-font-weight--extra-bold: 900 !default;

// Line Height
$aui-line-height--sm: 1.2 !default;
$aui-line-height--base: 1.5 !default;
$aui-line-height--lg: 1.7 !default;

// Letter Spacing
$aui-letter-spacing--base: normal !default;
$aui-letter-spacing--md: 2px !default;
$aui-letter-spacing--lg: 4px !default;

// Link
$aui-link-decoration: underline !default;
$aui-link-decoration--hover: none !default;

// General
$aui-headers-margin-coeficient: 1.5 !default;

@import './../css-variables/typography';