// Variables
$aui-horizontal-line-border-color: var(--aui-color-border) !default;
$aui-horizontal-line-border-width: 1px !default;
$aui-horizontal-line-border-type: solid !default;

@import '../css-variables/components/line.scss';

// Styles
hr,
.aui-horizontal-line {
  border: var(--aui-horizontal-line-border-width) var(--aui-horizontal-line-border-type) var(--aui-horizontal-line-border-color);
}