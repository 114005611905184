.aui-paginator {
  justify-content: center;

  &__arrow {
    --aui-button-icon-size: #{rem(14px)};
  }
  
  &__pages {
    gap: 0;
    column-gap: var(--aui-paginator-pages-gap);

    &-page {
      &:not(.is-active) {
        color: rgba($aui-color-primary, .4);
      }

      &.is-active {
        font-weight: var(--aui-font-weight--bold);
      }

      &:not(&--separate):hover {
        color: var(--aui-color-primary);
      }

      &--separate {
        display: flex;
        align-items: center;
      }
    }
  }
}