:root {
  --aui-file-gap: #{rem($aui-file-gap)};

  --aui-file-icon: #{$aui-file-icon};
  --aui-file-icon-size: #{rem($aui-file-icon-size)};
  --aui-file-icon-padding: #{rem($aui-file-icon-padding)};
  --aui-file-icon-margin: #{rem($aui-file-icon-margin)};

  --aui-file-info-gap: #{rem($aui-file-info-gap)};

  --aui-file-info-trigger-font-weight: #{$aui-file-info-trigger-font-weight};
  --aui-file-info-trigger-font-size: #{$aui-file-info-trigger-font-size};
  --aui-file-info-trigger-color: #{$aui-file-info-trigger-color};
  --aui-file-info-trigger-text-decoration: #{$aui-file-info-trigger-text-decoration};

  --aui-file-info-description-font-weight: #{$aui-file-info-description-font-weight};
  --aui-file-info-description-font-size: #{$aui-file-info-description-font-size};
  --aui-file-info-description-color: #{$aui-file-info-description-color};
}