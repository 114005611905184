// Variables
$aui-tabs-gap: 16px !default;

$aui-tab-gap: 8px !default;
$aui-tab-font-size: $aui-font-size--base !default;
$aui-tab-font-weight: $aui-font-weight--base !default;
$aui-tab-font-family: $aui-font-family--secondary !default;
$aui-tab-color: $aui-color-text !default;

@import './../css-variables/components/tabs';

// Styles
.aui-tabs {
  display: flex;
  gap: var(--aui-tabs-gap);
}

.aui-tab {
  display: inline-flex;
  gap: var(--aui-tab-gap);
  
  font-size: var(--aui-tab-font-size);
  font-weight: var(--aui-tab-font-weight);
  font-family: var(--aui-tab-font-family);
  color: var(--aui-tab-color);

  &:hover,
  &.is-active {
    text-decoration: underline;
  }
}

[data-aui-tabs-content] {
  &:not(.is-active) {
    display: none;
  }
};