.aui-line {
  &:has(.aui-section--white) {
    background: var(--aui-color-white);

    padding-bottom: var(--aui-section-margin-bottom);

    & + .aui-line {
      &:not(:has(.aui-section--white)) {
        padding-top: rem(40px);

        @media #{$aui-breakpoint-sm} {
          padding-top: rem(87px);
        }
      }
    }
  }
 }