:root {
  --aui-announcement-background: #{$aui-announcement-background};
  --aui-announcement-padding--y: #{rem($aui-announcement-padding--y)};
  --aui-announcement-padding--x: #{rem($aui-announcement-padding--x)};
  --aui-announcement-color: #{$aui-announcement-color};

  --aui-announcement-font-size: #{rem($aui-announcement-font-size)};

  --aui-announcement-link-color: #{$aui-announcement-link-color};
  --aui-announcement-link-color--hover: #{$aui-announcement-link-color--hover};
}