.cart-item {
  width: 100%;

  display: flex;
  gap: rem(28px);

  border-bottom: rem(1px) solid var(--aui-color-primary);

  padding: rem(24px) 0;

  &:first-child {
    padding-top: 0;
  }

  &__image {
    width: rem(92px);

    flex-shrink: 0;

    img {
      height: 100%;

      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    gap: rem(20px);
    flex-grow: 1;
    justify-content: space-between;

    &-title {
      width: 40%;
      max-width: rem(235px);

      .aui-like-h4 {
        font-size: rem(21px);
        font-weight: var(--aui-font-weight--bold);

        margin: 0;

        & > div {
          font-size: var(--aui-font-size--sm);
          font-weight: var(--aui-font-weight--base);

          margin-top: rem(6px);
        }
      }
    }

    &-price {
      width: rem(162px);

      font-weight: var(--aui-font-weight--semi-bold);
      font-size: rem(23px);

      margin: 0;

      & > span {
        font-size: rem(20px);
      }
    }

    &-quantity {
      align-self: flex-end;
    }

    &-button {
      align-self: flex-end;
    }
  }
}

.cart-price-totals {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: rem(16px);

  font-weight: var(--aui-font-weight--bold);

  margin: rem(42px) 0;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.discount {
      font-size: rem(18px);
    }

    &.total {
      font-size: rem(23px);

      > span {
        font-size: rem(21px);
      }
    }
  }
}

.cart {
  .cart-item {
    @media #{$aui-breakpoint-md} {
      padding: rem(45px) 0;
    }

    &__image {
      @media #{$aui-breakpoint-md} {
        width: rem(155px);
      }
    }

    &__info {
      @media #{$aui-breakpoint-md} {
        align-items: flex-end;
      }

      &-title {
        @media #{$aui-breakpoint-sm} {
          width: 64%;
          max-width: 100%;

          h3, .aui-like-h4 {
            max-width: rem(255px);

            & > div {
              font-size: var(--aui-font-size--base);
            }
          }
        }

        @media #{$aui-breakpoint-md} {
          order: 0;
        }
      }

      &-price {
        @media #{$aui-breakpoint-md} {
          order: 2;
        }
      }

      &-quantity {
        @media #{$aui-breakpoint-md} {
          order: 1;

          align-self: unset;
        }
      } 

      &-button {
        @media #{$aui-breakpoint-md} {
          order: 3;

          margin-left: auto;
        }
      }
    }
  }

  &__actions {
    --aui-input-height: #{rem(40px)};

    width: 100%;

    display: flex;
    gap: rem(30px);
    justify-content: space-between;
    flex-direction: column;

    @media #{$aui-breakpoint-md} {
      flex-direction: row;
    }

    .aui-button--primary {
      width: 100%;

      @media #{$aui-breakpoint-md} {
        width: auto;
      }
    }

    &-discount {
      div {
        display: flex;
        flex-direction: column;
        gap: rem(16px);

        @media #{$aui-breakpoint-sm} {
          flex-direction: row;
          align-items: center;
        }

        .aui-button {
          width: 100%;

          @media #{$aui-breakpoint-sm} {
            width: auto;
          }
        }
      }
    }
  }
}
