:root {
  --aui-header-top-bar-height: #{rem(42px)};
  --header-padding-bottom-offset: 0px;
  --aui-page-offset-top: 0px;
}

.aui-announcements {
  width: 100%;

  @include aui-transition-base;

  z-index: 9;
}

body {
  &:has(.aui-header--pinned) {
    main {
      padding-top: 0;
    }

    .aui-announcements {
      top: calc(var(--aui-page-offset-top) * -1);
    }

    .aui-header {
      top: 0;
    }
  }
}

.aui-header {
  --aui-transition-base: all .7s ease-in-out 0ms;

  @include aui-transition-base;

  width: 100%;

  position: fixed;
  left: 0;
  top: var(--aui-page-offset-top);
  z-index: 99999;

  border-bottom: rem(1px) solid var(--aui-color-white);

  @media #{$aui-header-mobile-breakpoint} {
    border: none;
  }

  .splide__arrows {
    display: none;
  }

  &--dark,
  &--pinned {
    .aui-header-bar {
      --aui-navigation-item-color: var(--aui-color-primary);

      --aui-button-secondary-background: var(--aui-button-primary-background);
      --aui-button-secondary-color: var(--aui-button-primary-color);
      --aui-button-secondary-border-color: var(--aui-button-primary-border-color);

      --aui-button-secondary-background--hover: var(--aui-button-primary-background--hover);
      --aui-button-secondary-color--hover: var(--aui-button-primary-color--hover);
      --aui-button-secondary-border-color--hover: var(--aui-button-primary-border-color--hover);

      --aui-button-secondary-background--focus: var(--aui-button-primary-background--focus);
      --aui-button-secondary-color--focus: var(--aui-button-primary-color--focus);
      --aui-button-secondary-border-color--focus: var(--aui-button-primary-border-color--focus);
    }

    .aui-header-bar__actions-burger .aui-icon {
      --aui-icon-color: var(--aui-color-primary);
    }
  }

  &--pinned {
    &:not(.aui-header--is-open) {
      transform: translateY(calc(-1 * var(--aui-header-top-bar-height)));

      .aui-header-bar {
        background: rgba($aui-color-gray--light, .89);

        @media #{$aui-header-mobile-breakpoint} {
          padding-bottom: calc(var(--header-padding-bottom-offset) + 65px);

          &::after {
            transform: scaleX(1);

            background: var(--aui-color-primary);
          }
        }

        &__logo {
          @media #{$aui-header-mobile-breakpoint} {
            max-width: rem(64px);
          }

          @media #{$aui-breakpoint-xxxl} {
            max-width: rem(55px);
          }
        }
      }
    }
  }

  .aui-header-top-bar {
    @include aui-transition-base;

    height: var(--aui-header-top-bar-height);

    display: none;
    align-items: center;

    &.splide {
      display: flex;

      & > .aui-container {
        width: 100%;
      }

      @media #{$aui-header-mobile-breakpoint} {
        display: none;
      }
    }

    &:not(.splide) {
      @media #{$aui-header-mobile-breakpoint} {
        display: flex;
      }
    }

    * {
      font-size: var(--aui-font-size--sm);
    }

    &__content {
      width: 100%;
      max-width: var(--aui-container-max-width--base);

      display: flex;
      justify-content: space-between;

      * {
        text-transform: uppercase;
      }
    }

    &__contacts {
      gap: rem(32px);
    }
  }

  .aui-header-bar {
    @include aui-transition-base;

    width: 100%;

    position: relative;
    z-index: 12;

    @media #{$aui-header-mobile-breakpoint} {
      padding-top: rem(35px);
      padding-bottom: calc(var(--header-padding-bottom-offset) + 90px);

      &:hover {
        --aui-navigation-item-color: var(--aui-color-primary);

        --aui-button-secondary-background: var(--aui-button-primary-background);
        --aui-button-secondary-color: var(--aui-button-primary-color);
        --aui-button-secondary-border-color: var(--aui-button-primary-border-color);

        --aui-button-secondary-background--hover: var(--aui-button-primary-background--hover);
        --aui-button-secondary-color--hover: var(--aui-button-primary-color--hover);
        --aui-button-secondary-border-color--hover: var(--aui-button-primary-border-color--hover);

        --aui-button-secondary-background--focus: var(--aui-button-primary-background--focus);
        --aui-button-secondary-color--focus: var(--aui-button-primary-color--focus);
        --aui-button-secondary-border-color--focus: var(--aui-button-primary-border-color--focus);

        background: rgba($aui-color-gray--light, .89);
      }

      @media #{$aui-breakpoint-xxxxl} {
        padding-bottom: calc(var(--header-padding-bottom-offset) + 130px);
      }

      &::after {
        @include aui-transition-base;

        width: 100%;
        height: rem(2px);

        position: absolute;
        bottom: 0;
        left: 0;
        content: '';

        background: var(--aui-color-white);

        transform: scaleX(0);
      }
    }

    & > .aui-container {
      align-items: center;

      position: relative;
    }

    &__logo {
      @include aui-transition-base;

      max-width: rem(64px);

      display: none;

      color: var(--aui-navigation-item-color);

      @media #{$aui-header-mobile-breakpoint} {
        max-width: rem(91px);

        display: inline-block;

        position: absolute;
        left: 0;
        top: 8px;

        transform: translateX(calc(-100% - 2.5vw));
      }
    }

    &__navigation,
    &__actions {
      --aui-transition-base: all .20s ease-in-out;
    }

    &__navigation {
      margin-left: rem(-8px);

      .aui-navigation {
        &__item {
          &.only-mobile {
            display: none;
          }
        }
      }
    }

    &__actions {
      --aui-button-regular-text-color: var(--aui-navigation-item-color);
      --aui-icon-color: var(--aui-navigation-item-color);

      gap: rem(27px);

      &-button {
        font-size: rem(10px);
        line-height: 0;

        padding-left: rem(12px);
        padding-right: rem(12px);

        @media #{$aui-header-mobile-breakpoint} {
          font-size: rem(18px);
          line-height: 0.6;

          margin-right: rem(25px);
          padding-left: rem(23px);
          padding-right: rem(23px);
        }
      }

      &-cart {
        position: relative;

        .cart-amount {
          min-width: rem(22px);

          display: flex;
          align-items: center;
          justify-content: center;

          position: absolute;
          top: 0;
          right: 0;

          background: var(--aui-color-white);
          color: var(--aui-color-primary);
          border-radius: var(--aui-border-radius--full);

          box-shadow: var(--aui-box-shadow--md);

          font-size: var(--aui-font-size--sm);

          transform: translateX(50%) translateY(-30%);

          padding: rem(4px) rem(8px);
        }
      }

      &-burger {
        --aui-icon-color: var(--aui-color-gray--dark);
      }

      .aui-button .aui-icon svg, .aui-button .aui-icon i {
        width: auto;
      }
    }
  }

  &--is-open {
    --aui-navigation-item-color: var(--aui-color-primary);

    background: var(--aui-color-white);

    .aui-header-top-bar {
      display: none;
    }

    .aui-header-bar {
      & > .aui-container {
        align-items: flex-start;
      }

      &__logo {
        display: inline-block;
      }

      &__actions {
        display: none;

        &-burger {
          .aui-icon {
            --aui-icon-color: var(--aui-color-primary);
          }
        }
      }
    }

    .aui-header-burger {
      padding-top: rem(75px);

      .aui-navigation {
        max-height: 44dvh;

        border-top: rem(1px) solid var(--aui-color-primary);

        overflow-y: auto;

        @media (orientation: landscape) {
          max-height: 28dvh;
        }

        &__item {
          font-size: rem(20px);

          border-bottom: rem(1px) solid var(--aui-color-primary);

          padding-top: rem(8px);
          padding-bottom: rem(8px);

          &.only-mobile {
            display: inline-flex;
          }

          &-submenu-container {
            @include aui-transition-base;

            height: 0;

            display: flex;

            overflow: none;
            overflow-y: auto;

            padding: 0;
          }
        }

        &__dropdown {
          &.is-open {
            margin-bottom: rem(3dvh);
          }
        }

        &:has(.aui-navigation__dropdown.is-open) {
          .aui-navigation__item {
            color: rgba($aui-color-black, .4);
            border-color: rgba($aui-color-black, .4);
          }

          .aui-navigation__dropdown:hover {
            .aui-navigation__item-submenu-container {
              height: rem(140px);

              padding: var(--aui-navigation-item-submenu-padding);
              padding-left: 0;
              padding-right: 0;
            }

            .aui-navigation__item {
              color: var(--aui-color-primary);
              border-color: var(--aui-color-primary);
            }
          }
        }
      }

      &__contacts {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.aui-navigation__dropdown > .aui-navigation__item::after, .aui-navigation__dropdown-submenu > .aui-navigation__item::after {
  display: none;
}
