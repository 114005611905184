// Variables
$aui-alert-padding: $aui-padding--base !default;
$aui-alert-background: transparent !default;
$aui-alert-border-width: 2px !default;
$aui-alert-border-type: solid !default;
$aui-alert-border-color: $aui-color-primary !default;
$aui-alert-border-radius: $aui-border-radius--base !default;
$aui-alert-gap: $aui-gap--base !default;
$aui-alert-icon-width: 30px !default;
$aui-alert-background-opacity: 0.2 !default;

@import '../css-variables/components/alert.scss';

// Styles
.aui-alert {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: var(--aui-alert-gap);

  background: var(--aui-alert-background);

  border: 0;
  border-left: var(--aui-alert-border-width) var(--aui-alert-border-type) var(--aui-alert-border-color);

  border-radius: var(--aui-alert-border-radius);

  padding: var(--aui-alert-padding);

  .aui-icon {
    color: var(--aui-color-primary);

    svg, i {
      width: var(--aui-alert-icon-width);
    }
  }

  &--row-reverse {
    flex-direction: row-reverse;
  }

  &--col {
    flex-direction: column;
  }

  &--col-reverse {
    flex-direction: column-reverse;
  }

  &--primary {
    border-color: var(--aui-color-primary);
    color: var(--aui-color-primary);
  }

  &--warning {
    border-color: var(--aui-color-warning);
    color: var(--aui-color-warning);

    .aui-icon {
      color: var(--aui-color-warning);
    }
  }

  &--danger {
    border-color: var(--aui-color-danger);
    color: var(--aui-color-danger);

    .aui-icon {
      color: var(--aui-color-danger);
    }
  }

  &--success {
    border-color: var(--aui-color-success);
    color: var(--aui-color-success);

    .aui-icon {
      color: var(--aui-color-success);
    }
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }


  &__content {
    .aui-section-header {
      margin-bottom: 0;
    }

    &-button {
        margin-top: var(--aui-alert-gap);
    }
  }
}