:root {
  --aui-modal-padding: #{rem($aui-modal-padding)};
  --aui-modal-background: #{$aui-modal-background};
  --aui-modal-animation-duration: #{$aui-modal-animation-duration};

  --aui-modal-container-max-width--sm: #{rem($aui-modal-container-max-width--sm)};
  --aui-modal-container-max-width--base: #{rem($aui-modal-container-max-width--base)};
  --aui-modal-container-max-width--lg: #{rem($aui-modal-container-max-width--lg)};
  --aui-modal-container-background: #{$aui-modal-container-background};
  --aui-modal-container-color: #{$aui-modal-container-color};
  --aui-modal-container-border-width: #{rem($aui-modal-container-border-width)};
  --aui-modal-container-border-type: #{$aui-modal-container-border-type};
  --aui-modal-container-border-color: #{$aui-modal-container-border-color};
  --aui-modal-container-border-radius: #{rem($aui-modal-container-border-radius)};

  --aui-modal-container-header-position: #{$aui-modal-container-header-position};
  --aui-modal-container-header-padding--y: #{rem($aui-modal-container-header-padding--y)};
  --aui-modal-container-header-padding--x: #{rem($aui-modal-container-header-padding--x)};
  --aui-modal-container-header-font-size: #{rem($aui-modal-container-header-font-size)};
  --aui-modal-container-header-font-weight: #{$aui-modal-container-header-font-weight};
  --aui-modal-container-header-border-width: #{rem($aui-modal-container-header-border-width)};
  --aui-modal-container-header-border-type: #{$aui-modal-container-header-border-type};
  --aui-modal-container-header-border-color: #{$aui-modal-container-header-border-color};

  --aui-modal-container-content-padding--x: #{rem($aui-modal-container-content-padding--x)};
  --aui-modal-container-content-padding--y: #{rem($aui-modal-container-content-padding--y)};

  --aui-modal-container-footer-border-width: #{rem($aui-modal-container-footer-border-width)};
  --aui-modal-container-footer-border-type: #{$aui-modal-container-footer-border-type};
  --aui-modal-container-footer-border-color: #{$aui-modal-container-footer-border-color};
  --aui-modal-container-footer-padding--y: #{rem($aui-modal-container-footer-padding--y)};
  --aui-modal-container-footer-padding--x: #{rem($aui-modal-container-footer-padding--x)};
}