// Variables
$aui-signpost-background: $aui-color-primary !default;
$aui-signpost-color: $aui-color-white !default;
$aui-signpost-border-width: 1px !default;
$aui-signpost-border-type: solid !default;
$aui-signpost-border-color: transparent !default;
$aui-signpost-border-radius: $aui-border-radius--md !default;

$aui-signpost-color--hover: $aui-color-white !default;
$aui-signpost-background--hover: darken($aui-color-primary, 2%) !default;
$aui-signpost-border-color--hover: darken($aui-color-primary, 2%) !default;

$aui-signpost-padding: $aui-padding--md !default;

$aui-signpost-icon-color: $aui-color-white !default;
$aui-signpost-icon-color--hover: $aui-color-white !default;
$aui-signpost-icon-size: 40px !default;

$aui-signpost-link-color: $aui-color-white !default;
$aui-signpost-link-color--hover: $aui-color-white !default;

@import './../css-variables/components/signpost.scss';

// Styles
.aui-signpost {
  @include aui-transition-base;

  position: relative;

  background: var(--aui-signpost-background);
  color: var(--aui-signpost-color);
  border: var(--aui-signpost-border-width) var(--aui-signpost-border-type) var(--aui-signpost-border-color);
  border-radius: var(--aui-signpost-border-radius);

  padding: var(--aui-signpost-padding);

  .aui-icon {
    color: var(--aui-signpost-icon-color);

    margin-bottom: var(--aui-margin--md);

    svg, i {
      @include aui-transition-base;

      width: var(--aui-signpost-icon-size);
      height: var(--aui-signpost-icon-size);
    }
  }

  &__title {
    @include aui-transition-base;

    color: var(--aui-signpost-color);
  }

  a.aui-signpost__button {
    color: var(--aui-signpost-link-color);

    &:hover {
      color: var(--aui-signpost-link-color--hover);
    }
  }

  &__button {
    margin-top: var(--aui-margin--md);
  }

  &:has(a):hover {
    background: var(--aui-signpost-background--hover);
    border-color: var(--aui-signpost-border-color--hover);
    color: var(--aui-signpost-color--hover);

    .aui-signpost__title {
      color: var(--aui-signpost-color--hover);
    }

    .aui-icon {
      color: var(--aui-signpost-icon-color--hover);
    }
  }
}
