:root {
  // Colors
  --aui-color-gray: #F9F9F9;
  --aui-color-gray--medium: #CECECE;
  --aui-color-gray--darker: #EDEDED;
  --aui-color-gray--dark: #D9D9D9;
  --aui-color-border: #707070;

  --aui-color-background: var(--aui-color-gray);

  --aui-color-heading: var(--aui-color-primary);
  --aui-color-text: var(--aui-color-primary);
  --aui-color-link: var(--aui-color-primary);

  // Typography
  --aui-font-size--4xl: clamp(2.5rem, 1.50rem + 4.13vw, 6.25rem); // 38px to 100px H1
  --aui-font-size--3xl: clamp(1.523rem, 1.35rem + 2.13vw, 3.125rem); // 34px to 50px H2
  --aui-font-size--2xl: clamp(1.5rem, 1.35rem + 2.40vw, 2.563rem); // 34px to 41px H3
  --aui-font-size--xl: clamp(1.25rem, 1.1rem + 0.5vw, 1.5rem); // 20px to 24px H4

  --aui-font-weight--base: 500;
  --aui-font-weight--semi-bold: 600;

  // Layout
  --aui-container-max-width--base: #{rem(1088px)};
  --aui-container-max-width--lg: #{rem(1157px)};

  --sections-gap-x: #{rem(93px)};

  // Sections
  --aui-section-margin-bottom: clamp(3.938rem, 8vw, 6.688rem); // 63px -> 107px
  --aui-section-header-margin: 0 0 clamp(2rem, 8vw, 5.625rem) 0; // 32px -> 90px

  // Buttons
  --aui-button-font-family: var(--aui-font-family--secondary);
  --aui-button-border-radius: #{rem(20px)};
  --aui-button-font-size: var(--aui-font-size--sm);
  --aui-button-font-weight: var(--aui-font-weight--semi-bold);
  --aui-button-padding--sm: #{rem(6px)} #{rem(14px)};
  --aui-button-padding--base: #{rem(12px)} #{rem(37px)};
  --aui-button-padding--lg: #{rem(18px)} #{rem(37px)};

  --aui-button-icon-scale: 0;
  --aui-button-icon-size: #{rem(24px)};

  // Buttons -> Primary
  --aui-button-primary-background: var(--aui-color-primary);
  --aui-button-primary-color: var(--aui-color-white);
  --aui-button-primary-border-color: var(--aui-color-primary);

  --aui-button-primary-background--hover: var(--aui-color-white);
  --aui-button-primary-color--hover: var(--aui-color-primary);
  --aui-button-primary-border-color--hover: var(--aui-color-primary);

  --aui-button-primary-background--focus: var(--aui-color-white);
  --aui-button-primary-color--focus: var(--aui-color-primary);
  --aui-button-primary-border-color--focus: var(--aui-color-primary);

  // Buttons -> Primary Outline
  --aui-button-primary-outline-background: transparent;
  --aui-button-primary-outline-color: var(--aui-color-primary);
  --aui-button-primary-outline-border-color: var(--aui-color-primary);

  --aui-button-primary-outline-background--hover: var(--aui-color-primary);
  --aui-button-primary-outline-color--hover: var(--aui-color-white);
  --aui-button-primary-outline-border-color--hover: var(--aui-color-primary);

  --aui-button-primary-outline-background--focus: var(--aui-color-primary);
  --aui-button-primary-outline-color--focus: var(--aui-color-white);
  --aui-button-primary-outline-border-color--focus: var(--aui-color-primary);

  // Buttons -> Secondary
  --aui-button-secondary-background: var(--aui-color-white);
  --aui-button-secondary-color: var(--aui-color-dark);
  --aui-button-secondary-border-color: var(--aui-color-white);

  --aui-button-secondary-background--hover: var(--aui-color-dark);
  --aui-button-secondary-color--hover: var(--aui-color-white);
  --aui-button-secondary-border-color--hover: var(--aui-color-dark);

  --aui-button-secondary-background--focus: var(--aui-color-dark);
  --aui-button-secondary-color--focus: var(--aui-color-white);
  --aui-button-secondary-border-color--focus: var(--aui-color-dark);

  // Buttons -> Secondary Outline
  --aui-button-secondary-outline-background: transparent;
  --aui-button-secondary-outline-color: var(--aui-color-white);
  --aui-button-secondary-outline-border-color: var(--aui-color-white);

  --aui-button-secondary-outline-background--hover: var(--aui-color-white);
  --aui-button-secondary-outline-color--hover: var(--aui-color-dark);
  --aui-button-secondary-outline-border-color--hover: var(--aui-color-white);

  --aui-button-secondary-outline-background--focus: var(--aui-color-white);
  --aui-button-secondary-outline-color--focus: var(--aui-color-dark);
  --aui-button-secondary-outline-border-color--focus: var(--aui-color-white);

  // Icon
  --aui-icon-color: var(--aui-color-primary);
  --aui-icon-size--sm: #{rem(18px)};

  // Image
  --aui-image-background: var(--aui-color-gray--dark);
  --aui-image-border-radius: 0;

  // Table
  --aui-table-padding--y: #{rem(12px)};
  --aui-table-padding--x: #{rem(23px)};

  --aui-table-row-background--even: transparent;
  --aui-table-row-color--even: inherit;

  --aui-table-row-background--odd: transparent;
  --aui-table-row-color--odd: inherit;

  // Header
  --aui-header-top-bar-font-weight: var(--aui-font-weight--base);

  --aui-header-bar-background: transparent;
  --aui-header-bar-navigation-justify-content: flex-start;
  --aui-header-bar-box-shadow: none;
  --aui-header-bar-padding: #{rem(15px)} 0;

  // Header -> Navigation
  --aui-navigation-gap: 0;
  --aui-navigation-item-color: var(--aui-color-white);
  --aui-navigation-item-font-weight: var(--aui-font-weight--base);
  --aui-navigation-item-submenu-background: transparent;

  // Products
  --aui-product-block-image-padding: 0;
  --aui-product-block-border-radius: 0;
  --aui-product-block-border-width: 0;
  --aui-product-block-gap: #{rem(31px)};

  --aui-product-block-content-padding: 0;

  --aui-product-block-content-description-font-size: var(--aui-font-size--base);

  --aui-product-price-item-value-font-size: var(--aui-font-size--base);

  // Badge
  --aui-badge-border-radius: 0;
  --aui-badge-font-weight: var(--aui-font-weight--semi-bold);
  --aui-badge-padding: #{rem(6px)} #{rem(13px)};

  // Breadcrumbs
  --aui-breadcrumbs-color: var(--aui-color-primary);
  --aui-breadcrumbs-last-color: var(--aui-color-primary);
  --aui-breadcrumbs-font-size: var(--aui-font-size--sm);
  --aui-breadcrumbs-separator: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAxMyI+CiAgPHBhdGggZD0iTTE2LjY1LjM3Yy41LS41IDEuMy0uNSAxLjggMGw1LjA5IDUuMDljLjUuNS41IDEuMyAwIDEuOGwtNS4wOSA1LjA5Yy0uNDkuNTEtMS4yOS41Mi0xLjguMDNhMS4yNjggMS4yNjggMCAwIDEgMC0xLjgzbDIuOTItMi45MkgyLjI4QzEuNTggNy42NS45OSA3LjEuOTcgNi4zOWMtLjAyLS43LjUzLTEuMjkgMS4yNC0xLjMxaDE3LjM2bC0yLjkyLTIuOTJjLS41LS41LS41LTEuMyAwLTEuOHYuMDFaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz4KPC9zdmc+");
  --aui-breadcrumbs-last-font-weight: var(--aui-font-weight--base);

  // FAQ
  --aui-faq-border-color: var(--aui-color-light);
  --aui-faq-border-radius: 0;
  --aui-faq-padding: #{rem(42px)} 0;

  // CTA
  --aui-cta-padding--sm: #{rem(30px)};
  --aui-cta-border-radius: 0;
  --aui-cta-border: none;
  --aui-cta-background: var(--aui-color-primary);

  // Map
  --aui-map-border-radius: 0;
  --aui-map-bubble-link-font-weight: var(--aui-font-weight--base);
  --aui-map-bubble-border-radius: 0;
  --aui-map-bubble-padding: #{rem(10px)};

  // Alert
  // --aui-alert-border-width: 2px;
  // --aui-alert-border-radius: 0;
  // --aui-alert-padding: 0 0 0 #{rem(46px)};

  // Signpost
  --aui-signpost-background: var(--aui-color-white);
  --aui-signpost-background--hover: var(--aui-color-primary);
  --aui-signpost-border-color: var(--aui-color-primary);
  --aui-signpost-border-color--hover: var(--aui-color-primary);
  --aui-signpost-color: var(--aui-color-primary);
  --aui-signpost-color--hover: var(--aui-color-white);
  --aui-signpost-border-radius: 0;
  --aui-signpost-link-color: var(--aui-color-primary);
  --aui-signpost-link-color--hover: var(--aui-color-white);

  // Form
  --aui-label-font-family: var(--aui-font-family--secondary);
  --aui-label-font-weight: var(--aui-font-weight--base);

  --aui-input-height: #{rem(40px)};
  --aui-input-padding--y: #{rem(10px)};
  --aui-input-padding--x: #{rem(14px)};
  --aui-input-border-radius: #{rem(20px)};
  --aui-input-border-color: var(--aui-color-primary);
  --aui-input-background: transparent;
  --aui-input-shadow: none;
  --aui-input-font-size: var(--aui-font-size--base);

  --aui-textarea-min-height: #{rem(205px)};

  // --aui-form-item-gap: #{rem(22px)};

  --aui-input-placeholder-color: #{rgba($aui-color-primary, .4)};
  --aui-checkbox-input-border-color: var(--aui-color-primary);
  --aui-checkbox-label-font-size: var(--aui-font-size--base);
  --aui-checkbox-box-gap: #{rem(13px)};

  --aui-input-border-color--focus: var(--aui-input-border-color);

  // Horizontal line
  --aui-horizontal-line-border-width: #{rem(0.5px)};
  --aui-horizontal-line-border-color: var(--aui-color-dark);

  // Modal
  --aui-modal-container-header-font-weight: var(--aui-font-weight--s--aui-gallery-dual-grid-gap);
  --aui-modal-container-header-font-size: var(--aui-font-size--xl);
  --aui-modal-container-border-radius: 0;
  --aui-modal-container-header-border-width: 0;
  --aui-modal-background: rgb(250 249 247 / 0.75);

  --aui-modal-container-header-padding--y: #{rem(16px)};
  --aui-modal-container-header-padding--x: #{rem(16px)};

  --aui-modal-container-content-padding--y: #{rem(16px)};
  --aui-modal-container-content-padding--x: #{rem(16px)};

  --aui-modal-container-footer-padding--y: #{rem(16px)};
  --aui-modal-container-footer-padding--x: #{rem(16px)};

  // Gallery
  --aui-gallery-dual-grid-gap: #{rem(27px)} #{rem(38px)};

  // Timeline
  // --aui-timeline-item-mark-size: #{rem(16px)};
  // --aui-timeline-item-gap: #{rem(34px)};
  // --aui-timeline-item-mark-gap: #{rem(8px)};
  --aui-timeline-item-mark-border-radius: 0;
  // --aui-timeline-gap: #{rem(24px)} #{rem(8px)};

  // Video
  --aui-video-overlay-width: #{rem(64px)};
  --aui-video-overlay-height: #{rem(64px)};
  --aui-video-border-radius: 0;
  --aui-image-overlay-background: #{rgba($aui-color-primary, .4)};

  // Quote
  // --aui-quote-padding: #{rem(48px)} 0;

  // Files
  // --aui-file-info-trigger-font-weight: var(--aui-font-weight--base);
  // --aui-file-info-trigger-color: var(--aui-color-text);
  // --aui-file-gap: #{rem(16px)};
  // --aui-file-icon-size: #{rem(24px)};
  // --aui-file-icon: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggZD0iTTQgMjF2LTQuNW0wIDBoMkExLjUgMS41IDAgMCAxIDcuNSAxOHYwQTEuNSAxLjUgMCAwIDEgNiAxOS41SDRtMTQuNS0uMzVIMTZtMy0yLjY1aC0zVjIxbTQtOFY4LjQxNGExIDEgMCAwIDAtLjI5My0uNzA3bC00LjQxNC00LjQxNEExIDEgMCAwIDAgMTQuNTg2IDNINmEyIDIgMCAwIDAtMiAydjgiIHN0cm9rZT0iIzQ5MzM3MiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgogIDxwYXRoIGQ9Ik0yMCA5aC00YTIgMiAwIDAgMS0yLTJWMyIgc3Ryb2tlPSIjNDkzMzcyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CiAgPHBhdGggY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTAgMTYuNWgxLjVhMiAyIDAgMCAxIDIgMnYuNWEyIDIgMCAwIDEtMiAySDEwdi00LjVaIiBzdHJva2U9IiM0OTMzNzIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+");

  // Paginator
  --aui-paginator-pages-width: 1.1875rem;

  @media #{$aui-breakpoint-md} {
    --aui-input-height: #{rem(50px)};
    --aui-input-padding--y: #{rem(15px)};
    --aui-input-padding--x: #{rem(26px)};

    --aui-textarea-min-height: #{rem(298px)};

    --aui-checkbox-box-gap: #{rem(24px)};

    --aui-cta-padding: #{rem(45px)} #{rem(35px)};
  }

  @media #{$aui-header-mobile-breakpoint} {
    --aui-navigation-gap: #{rem(40px)};
  }
}
