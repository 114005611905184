.my-account {
  display: flex;
  flex-direction: column-reverse;
  gap: rem(40px);

  @media #{$aui-breakpoint-md} {
    justify-content: space-between;
    flex-direction: row;
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    gap: rem(11px);

    .aui-button {
      width: 100%;

      @media #{$aui-breakpoint-md} {
        width: auto;
      }
    }
  }

  &__user {
    display: flex;
    gap: rem(40px);
  }
}