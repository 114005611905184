.steps {
  @extend .aui-breadcrumbs;

  &__item {
    @extend .aui-breadcrumbs__item;

    text-transform: uppercase;

    a {
      text-decoration: none;
    }

    &.is-active {
      text-decoration: underline;
    }
  }
}
