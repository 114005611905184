main:has(.order) {
  overflow: unset;
}

.order {
  display: flex;
  flex-direction: column;
  gap: rem(32px);

  position: relative;

  @media #{$aui-breakpoint-md} {
    flex-direction: row;
    justify-content: space-between;
  }

  &__data,
  &__summary {
    width: 100%;

    @media #{$aui-breakpoint-md} {
      width: 50%;
      max-width: rem(400px);
    }
  }

  &__data {
    &-choice {
      width: 100% !important;

      display: flex;
      align-items: center;
      flex-direction: row;
      gap: rem(16px);

      padding-top: calc(16px - var(--aui-form-gap));
      padding-bottom: rem(16px);

      border-bottom: rem(1px) solid var(--aui-color-primary);

      @media #{$aui-breakpoint-md} {
        padding-top: calc(25px - var(--aui-form-gap));
        padding-bottom: rem(25px);
      }

      &--disabled {
        text-decoration: line-through;
      }

      .branch {
        font-size: var(--aui-font-size--xs);
        margin-top: rem(4px);
      }

      .price {
        flex-shrink: 0;

        font-size: clamp(1.125rem, 1.1rem + 0.5vw, 1.313rem);
        font-weight: var(--aui-font-weight--semi-bold);
      }
    }
  }

  &__summary {
    position: relative;

    & > div {
      position: sticky;

      @media #{$aui-breakpoint-md} {
       // top: calc(var(--aui-header-height) + rem(20px));
        top: calc(var(--aui-header-height));
      }
    }

    &-item {
      display: flex;
      gap: rem(22px);

      font-size: var(--aui-font-size--base);

      border-bottom: rem(1px) solid var(--aui-color-primary);

      padding: rem(16px) 0;

      @media #{$aui-breakpoint-md} {
        padding: rem(25px) 0;
      }

      &--no-border {
        border-bottom: none;
      }

      &--small-gap {
        gap: rem(8px);
      }

      .price {
        flex: 1;

        text-align: right;
      }

      &--totalPrice {
        justify-content: space-between;
        align-items: center;

        .aui-like-h4 {
          font-weight: var(--aui-font-weight--bold);
          margin: 0;
        }
      }
    }
  }
}

#packeta-widget-overlay {
  z-index: 9999999 !important;
}
