// Variables
$aui-section-header-margin: 0 0 40px 0 !default;

@import '../css-variables/components/section-header';

// Styles
.aui-section-header {
  margin: var(--aui-section-header-margin);

  &__top {
    display: flex;
    flex-direction: column;

    &-title {

    }

    &-subtitle {
      margin-top: 0;
    }
  }

  &__description {
    margin-top: 0;
  }
}