// Variables
$aui-modal-padding: $aui-padding--md !default;
$aui-modal-background: rgba($aui-color-black, .4) !default;
$aui-modal-animation-duration: .25s !default;

$aui-modal-container-max-width--sm: 580px !default;
$aui-modal-container-max-width--base: 820px !default;
$aui-modal-container-max-width--lg: 1150px !default;
$aui-modal-container-background: $aui-color-white !default;
$aui-modal-container-color: initial !default;
$aui-modal-container-border-width: 1px !default;
$aui-modal-container-border-type: solid !default;
$aui-modal-container-border-color: $aui-color-border !default;
$aui-modal-container-border-radius: $aui-border-radius--md !default;

$aui-modal-container-header-position: sticky !default;
$aui-modal-container-header-font-size: $aui-font-size--xl !default;
$aui-modal-container-header-font-weight: $aui-font-weight--bold !default;
$aui-modal-container-header-padding--x: $aui-padding--sm !default;
$aui-modal-container-header-padding--y: $aui-padding--sm !default;
$aui-modal-container-header-border-width: 1px !default;
$aui-modal-container-header-border-type: solid !default;
$aui-modal-container-header-border-color: $aui-color-border !default;

$aui-modal-container-content-padding--x: $aui-padding--sm !default;
$aui-modal-container-content-padding--y: $aui-padding--sm !default;

$aui-modal-container-footer-border-width: 1px !default;
$aui-modal-container-footer-border-type: solid !default;
$aui-modal-container-footer-border-color: $aui-color-border !default;
$aui-modal-container-footer-padding--x: $aui-padding--sm !default;
$aui-modal-container-footer-padding--y: $aui-padding--sm !default;

@import '../css-variables/components/modal.scss';

// Styles
.aui-modal {
  width: 100dvw;
  height: 100dvh;

  display: none;
  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;

  background: var(--aui-modal-background);

  & + .aui-modal {
    background: none;
  }

  &--is-open {
    display: flex;

    .aui-modal__container {
      animation: aui-fade-in .25s ease-in-out;
    }
  }

  &--bounded {
    padding: var(--aui-modal-padding);
  }

  &--top {
    align-items: flex-start;
  }

  &--center {
    align-items: center;
  }

  &--bottom {
    align-items: flex-end;
  }

  &--sm {
    .aui-modal__container {
      max-width: var(--aui-modal-container-max-width--sm);
    }
  }

  &--base {
    .aui-modal__container {
      max-width: var(--aui-modal-container-max-width--base);
    }
  }

  &--lg {
    .aui-modal__container {
      max-width: var(--aui-modal-container-max-width--lg);
    }
  }

  &--full {
    padding: 0;

    .aui-modal__container {
      max-width: 100%;
      height: 100%;
      max-height: 100%;

      border-radius: 0;
    }
  }

  &__container {
    width: 100%;
    max-width: var(--aui-modal-container-max-width--base);
    max-height: 100%;

    display: flex;
    flex-direction: column;

    position: relative;

    color: var(--aui-modal-container-color);
    background: var(--aui-modal-container-background);
    border: var(--aui-modal-container-border-width) var(--aui-modal-container-border-type)  var(--aui-modal-container-border-color);
    border-radius: var(--aui-modal-container-border-radius);

    overflow: hidden;

    &-header {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--aui-gap--md);

      position: var(--aui-modal-container-header-position);
      left: 0;
      top: 0;

      font-size: var(--aui-modal-container-header-font-size);
      font-weight: var(--aui-modal-container-header-font-weight);

      border-bottom: var(--aui-modal-container-header-border-width) var(--aui-modal-container-header-border-type) var(--aui-modal-container-header-border-color);

      padding: var(--aui-modal-container-header-padding--y) var(--aui-modal-container-header-padding--x);
    }

    &-content {
      max-height: calc(100dvh - var(--aui-modal-padding) * 4);

      flex: 1;

      overflow-y: auto;

      padding: var(--aui-modal-container-content-padding--y) var(--aui-modal-container-content-padding--x);
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      gap: var(--aui-gap--sm);

      border-top: var(--aui-modal-container-footer-border-width) var(--aui-modal-container-footer-border-type) var(--aui-modal-container-footer-border-color);

      padding: var(--aui-modal-container-footer-padding--y) var(--aui-modal-container-footer-padding--x);
    }
  }
}
