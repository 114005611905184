.top-section {
  padding-top: rem(140px);

  &--margin-b-half {
    margin-bottom: 3rem;
  }

  @media #{$aui-header-mobile-breakpoint} {
    padding-top: rem(281px);
  }
}