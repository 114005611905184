// Variables
$aui-image-background: transparent !default;
$aui-image-border-radius: $aui-border-radius--md !default;
$aui-image-scale: 1.02 !default;

$aui-image-overlay-background: rgba($aui-color-primary, .4) !default;
$aui-image-overlay-color: $aui-color-white !default;
$aui-image-overlay-font-size: $aui-font-size--4xl !default;
$aui-image-overlay-font-weight: $aui-font-weight--bold !default;

@import './../css-variables/components/image';

// Styles
.aui-image {
  width: 100%;

  overflow: hidden;

  background: var(--aui-image-background);
  border-radius: var(--aui-image-border-radius);

  &--overlay {
    position: relative;

    &::before {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      left: 0;
      top: 0;
      content: '';
      z-index: 12;

      background: var(--aui-image-overlay-background);
      color: var(--aui-image-overlay-color);
      font-size: var(--aui-image-overlay-font-size);
      font-weight: var(--aui-image-overlay-font-weight);
    }
  }

  img {
    max-width: 100%;

    display: block;
    margin: 0;
  }
}

a.aui-image,
button.aui-image,
.aui-image--hover {
  cursor: pointer;

  img {
    @include aui-transition-base;
  }

  &:hover {
    img {
      transform: scale(var(--aui-image-scale));
    }
  }
}