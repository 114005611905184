@import '/node_modules/flatpickr/dist/flatpickr.min.css';


// Variables

// Label
$aui-label-font-size: $aui-font-size--sm !default;
$aui-label-font-weight: $aui-font-weight--bold !default;
$aui-label-font-family: $aui-font-family--primary !default;

// Input
$aui-input-height: 44px !default;

$aui-input-font-size: $aui-font-size--sm !default;
$aui-input-font-weight: $aui-font-weight--base !default;
$aui-input-color: $aui-color-text !default;
$aui-input-background: $aui-color-white !default;

$aui-input-border-width: 1px !default;
$aui-input-border-type: solid !default;
$aui-input-border-color: $aui-color-gray !default;
$aui-input-border-radius: $aui-border-radius--base !default;

$aui-input-shadow: $aui-box-shadow--base !default;

$aui-input-padding--x: $aui-padding--sm !default;
$aui-input-padding--y: $aui-padding--sm !default;

$aui-input-disabled-background: $aui-color-disabled-background !default;
$aui-input-disabled-color: $aui-color-disabled-color !default;
$aui-input-disabled-border-color: $aui-color-disabled-border-color !default;

$aui-input-placeholder-color: #CFD7DE !default;

$aui-input-border-color--focus: $aui-color-primary !default;

// Checkbox + Radio
$aui-checkbox-box-gap: 12px !default;

$aui-checkbox-label-font-size: $aui-font-size--base !default;
$aui-checkbox-label-font-weight: $aui-font-weight--base !default;
$aui-checkbox-label-color: $aui-color-text !default;

$aui-checkbox-input-size: 24px !default;
$aui-checkbox-input-background: $aui-input-background !default;
$aui-checkbox-input-border-color: $aui-input-border-color !default;
$aui-checkbox-input-border-width: $aui-input-border-width !default;
$aui-checkbox-input-border-type: $aui-input-border-type !default;
$aui-checkbox-input-radius: $aui-input-border-radius !default;
$aui-checkbox-input-background--checked: $aui-color-primary !default;
$aui-checkbox-input-border-color--checked: $aui-color-primary !default;

// Textarea
$aui-textarea-min-height: 120px;

// Multiple select
$aui-select-multiple-input-gap: 8px !default;

$aui-select-multiple-input-option-color: $aui-color-white !default;
$aui-select-multiple-input-option-background: $aui-color-primary !default;
$aui-select-multiple-input-option-border-radius: $aui-border-radius--base !default;
$aui-select-multiple-input-option-border-width: 1px !default;
$aui-select-multiple-input-option-border-type: solid !default;
$aui-select-multiple-input-option-border-color: $aui-color-primary !default;
$aui-select-multiple-input-option-padding: rem(4px) rem(16px) rem(4px) rem(4px) !default;

$aui-select-multiple-input-option-close-icon-size: 10px !default;
$aui-select-multiple-input-option-close-icon-color: $aui-color-white !default;

$aui-select-multiple-list-offset: 20px !default;
$aui-select-multiple-list-option-color: $aui-input-color !default;
$aui-select-multiple-list-option-background: $aui-input-background !default;
$aui-select-multiple-list-option-color--hover: $aui-color-white !default;
$aui-select-multiple-list-option-background--hover: $aui-color-primary !default;
$aui-select-multiple-list-option-color--active: $aui-color-primary !default;
$aui-select-multiple-list-option-content--active: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTIxIDdMOSAxOWwtNS41LTUuNWwxLjQxLTEuNDFMOSAxNi4xN0wxOS41OSA1LjU5eiIvPjwvc3ZnPg==") !default;


@import './../css-variables/components/input';

// Styles
label {
  display: block;
  
  font-size: var(--aui-label-font-size);
  font-family: var(--aui-label-font-family);
  font-weight: var(--aui-label-font-weight);
}

input, select, textarea, .aui-select-multiple__input {
  &[disabled] {
    background: var(--aui-input-disabled-background) !important;
    color: var(--aui-input-disabled-color) !important;
    border-color: var(--aui-input-disabled-border-color) !important;
    cursor: not-allowed;
  }

  &--is-open,
  &:focus {
    border-color: var(--aui-input-border-color--focus) !important;
  }
}

input:not([type="checkbox"], [type="radio"], [type="submit"]),
textarea,
select,
.aui-spinbox__button,
.aui-select-multiple__input {
  width: 100%;
  height: var(--aui-input-height);

  color: var(--aui-input-color, initial);
  line-height: 1;
  font-size: var(--aui-input-font-size);
  font-weight: var(--aui-input-font-weight);
  background: var(--aui-input-background);

  border: var(--aui-input-border-width, 1) var(--aui-input-border-type, solid) var(--aui-input-border-color);
  border-radius: var(--aui-input-border-radius);

  box-shadow: var(--aui-input-shadow, none);

  padding: var(--aui-input-padding--y) var(--aui-input-padding--x);
  
  &::placeholder {
    color: var(--aui-input-placeholder-color);
  }

  &[type="number"] {
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}

.aui-spinbox__button {
  min-height: 0 !important;
}

select, .aui-select-multiple__input {
  appearance: none;

  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: center;
}

input[type="checkbox"], input[type="radio"] {
  @include aui-transition-base;

  width: var(--aui-checkbox-input-size);
  height: var(--aui-checkbox-input-size);

  position: relative;

  flex-shrink: 0;

  background: var(--aui-checkbox-input-background);
  border: var(--aui-checkbox-input-border-width) var(--aui-checkbox-input-border-type, solid) var(--aui-checkbox-input-border-color);
  border-color: var(--aui-checkbox-input-border-color);

  appearance: none;

  cursor: pointer;

  &:checked {
    background: var(--aui-checkbox-input-background--checked);
    border-color: var(--aui-checkbox-input-border-color--checked);

    &::before {
      transform: scale(1);
    }
  }

  &::before {
    @include aui-transition-base;

    width: 100%;
    height: 100%;

    position: absolute;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNC41NzUgOC45NzdzLS40MDQtLjAwNy0uNTM2LS4xNjVMLjA1NyA1LjY0bC44MjktMS4yMjdMNC40NyA3LjI2OCAxMC45MjEuMDg2bC45MjMgMS4xMDMtNi44NjMgNy42NGMtLjEzNC0uMDAzLS40MDYuMTQ4LS40MDYuMTQ4eiIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);

    transform: scale(0);
  }

  & + label {
    color: var(--aui-checkbox-label-color, initial);
    font-size: var(--aui-checkbox-label-font-size);
    font-weight: var(--aui-checkbox-label-font-weight);
    line-height: 1;

    cursor: pointer;

    padding-top: rem(4px);
  }

  &[disabled] + label {
    color: var(--aui-input-disabled-color);
    cursor: not-allowed;
  }
}

textarea {
  min-height: var(--aui-textarea-min-height);

  resize: vertical;
}

input[type="checkbox"] {
  border-radius: var(--aui-input-border-radius);
}

input[type="radio"] {
  border-radius: var(--aui-border-radius--full);
}

.aui-checkbox, .aui-radio {
  width: 100%;

  display: flex;
  gap: var(--aui-checkbox-box-gap);

  label {
    a {
      display: inline;
    }
  }
}

.aui-spinbox {
  display: flex;

  &__button {
    @include aui-transition-base;

    width: min-content;
    min-width: rem(35px);

    &:first-child {
      border-right: none;
      border-radius: var(--aui-input-border-radius) 0 0 var(--aui-input-border-radius);
    }

    &:last-child {
      border-left: none;
      border-radius: 0 var(--aui-input-border-radius) var(--aui-input-border-radius) 0;
    }
  }

  input[type="number"] {
    border-radius: 0;
  }
}

select[data-aui-select-multiple] {
  visibility: hidden;
}

.aui-select-multiple {
  position: relative;

  select {
    display: none;
  }

  &__input {
    width: 100%;
    height: auto;
    min-height: var(--aui-input-height);

    display: flex;
    flex-wrap: wrap;
    gap: var(--aui-select-multiple-input-gap);

    position: relative;

    padding-right: calc(var(--aui-input-padding--x) + rem(12px));

    &--is-open + .aui-select-multiple__list {
      opacity: 1;
      visibility: visible;
    }

    &-placeholder {
      @include aui-transition-base;

      display: block;

      position: absolute;
      left: var(--aui-input-padding--x);
      top: 50%;
      
      color: var(--aui-input-placeholder-color);
      line-height: var(--aui-line-height--base);
      
      cursor: pointer;

      transform: translateY(-50%);
      
      &.hide {
        opacity: 0;
        visibility: hidden;
      }
    }
    
    a {
      --aui-link-decoration: none;
      --aui-link-decoration--hover: none;

      display: inline-block;
      vertical-align: top;

      position: relative;
      
      color: var(--aui-select-multiple-input-option-color);
      background: var(--aui-select-multiple-input-option-background);
      border-radius: var(--aui-select-multiple-input-option-border-radius);
      border: var(--aui-select-multiple-input-option-border-width) var(--aui-select-multiple-input-option-border-type) var(--aui-select-multiple-input-option-border-color);

      padding: var(--aui-select-multiple-input-option-padding);

      em {
        font-style: normal;
        font-weight: var(--aui-font-weight--base);
        display: block;
        white-space: nowrap;
      }

      i {
        width: var(--aui-select-multiple-input-option-close-icon-size);
        height: 100%;

        display: block;

        position: absolute;
        top: 0;
        right: 0;

        cursor: pointer;
        
        &:before,
        &:after {
          width: 1px;
          height: var(--aui-select-multiple-input-option-close-icon-size);

          display: block;
          
          position: absolute;
          left: 20%;
          top: 50%;
          content: '';

          background: var(--aui-select-multiple-input-option-close-icon-color);
          border-radius: 1px;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
  
  &__list {
    @include aui-transition-base;

    max-height: rem(250px);

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 12;
    
    border-radius: var(--aui-input-border-radius);

    box-shadow: 0 12px 20px rgba($aui-color-black, .08);

    visibility: hidden;
    overflow: hidden;
    overflow-y: auto;
    opacity: 0;

    transform: translate(0, var(--aui-select-multiple-list-offset));
    transform-origin: 0 0;

    margin: 0;
    padding: 0;

    &-search {
      border:none !important;
      border-radius: 0 !important;
      box-shadow: 0 12px 20px rgba($aui-color-black, .08) !important;
    }

    &-group-label,
    &-option {
      @include aui-transition-base;

      position: relative;

      color: var(--aui-select-multiple-list-option-color);
      background: var(--aui-select-multiple-list-option-background);
      
      padding: var(--aui-input-padding--x) var(--aui-input-padding--y);
    }

    &-option {
      cursor: pointer;

      &:hover {
        background: var(--aui-select-multiple-list-option-background--hover);
        color: var(--aui-select-multiple-list-option-color--hover) !important;
      }

      &[data-group] {
        padding-left: calc(var(--aui-input-padding--x) * 2);
      }

      &.is-active {
        position: relative;

        color: var(--aui-select-multiple-list-option-color--active);

        &::before {
          width: rem(16px);
          height: rem(16px);

          position: absolute;
          right: var(--aui-input-padding--x);
          top: 50%;
          content: '';

          background: var(--aui-select-multiple-list-option-content--active);
          background-repeat: no-repeat;
          background-size: contain;

          transform: translateY(-50%);
        }
      }
    }
  }
}