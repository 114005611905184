.check-list {
  display: flex;
  flex-direction: column;
  gap: rem(33px);
}

.check-list-item {
  display: flex;
  gap: rem(17px);

  &__icon {
    width: rem(25px);
    
    flex-shrink: 0;

    @media #{$aui-breakpoint-md} {
      width: rem(40px);
    }
  }

  &__label {
    padding-top: rem(4px);

    @media #{$aui-breakpoint-md} {
      padding-top: rem(8px);
    }
  }
}