// Variables
$aui-input-group-text-background: $aui-color-gray !default;
$aui-input-group-text-color: $aui-color-white !default;
$aui-input-group-text-font-size: $aui-font-size--sm !default;
$aui-input-group-text-font-weight: inherit !default;
$aui-input-group-text-border-width: 1px !default;
$aui-input-group-text-border-type: solid !default;
$aui-input-group-text-border-color: $aui-color-gray !default;

@import './../css-variables/components/input-group.scss';

// Styles
.aui-input-group {
  display: flex;
  align-items: flex-end;

  .aui-form-item {
    margin-bottom: 0;

    & + * {
      height: var(--aui-input-height);
      
      font-size: var(--aui-input-group-text-font-size);
      border-radius: var(--aui-input-border-radius);
  
      padding: var(--aui-input-padding--y) var(--aui-input-padding--x);
    }
  }

  & > span {
    display: flex;
    align-items: center;

    color: var(--aui-input-group-text-color);
    font-weight: var(--aui-input-group-text-font-weight);
    background: var(--aui-input-group-text-background);
    border: var(--aui-input-group-text-border-width, 1) var(--aui-input-group-text-border-type, solid) var(--aui-input-group-text-border-color);

    white-space: nowrap;
  }

  &--row {
    flex-direction: row;

    .aui-form-item + * {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    input:not([type=checkbox],[type=radio]) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    & > span {
      border-left-width: 0;
    }
  }

  &--row-reverse {
    flex-direction: row-reverse;

    .aui-form-item + * {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    input:not([type=checkbox],[type=radio]) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    & > span {
      border-right-width: 0;
    }
  }

  .aui-form-item.is-invalid {
    position: relative;

    .error-message {
      position: absolute;
      top: calc(100% + var(--aui-form-item-gap));
      left: 0;
    }
  }
}