:root {
  --aui-form-gap: #{rem($aui-form-gap)};

  --aui-form-item-gap: #{rem($aui-form-item-gap)};
  
  --aui-form-response-border-radius: #{rem($aui-form-response-border-radius)};
  --aui-form-response-background: #{$aui-form-response-background};
  --aui-form-response-color: #{$aui-form-response-color};
  --aui-form-response-border-width: #{rem($aui-form-response-border-width)};
  --aui-form-response-border-type: #{$aui-form-response-border-type};
  --aui-form-response-border-color: #{$aui-form-response-border-color};
  --aui-form-response-padding: #{rem($aui-form-response-padding)};

  --aui-form-response-icon-max-size: #{rem($aui-form-response-icon-max-size)};
  --aui-form-response-icon-color: #{$aui-form-response-icon-color};
}