// Variables
$aui-file-section-file-basis: 300px !default;
$aui-file-section-files-gap: $aui-gap--base !default;

@import '../css-variables/sections/file.scss';

// Styles
.aui-file-section {
  &:not(.aui-section) {
    margin-bottom: var(--aui-section-margin-bottom);
  }

  &__files {
    display: flex;
    flex-wrap: wrap;
    gap: var(--aui-file-section-files-gap);

    .aui-file {
      width: var(--aui-file-section-file-basis);

      flex-grow: 1;
    }
  }
} 