.aui-navigation {
  &__item {
    & > span {
      @include aui-transition-base;

      text-wrap: nowrap;
    }

    &-icon {
      @include aui-transition-base;

      width: rem(11px);

      transform: rotate(180deg);
    }

    &-submenu {
      border: none;
    }

    &-submenu {
      .aui-navigation__item {
        padding-top: rem(4px);
        padding-bottom: rem(4px);

        @media #{$aui-header-mobile-breakpoint} {
          padding-left: rem(20px);
        }
      }
    }
  }

  &__dropdown,
  &__dropdown-submenu {
    &.is-open {
      & > .aui-navigation__item > .aui-navigation__item-icon {
        transform: none;
      }
    }

    @media #{$aui-header-mobile-breakpoint} {
      &:hover {
        & > .aui-navigation__item > .aui-navigation__item-icon {
          transform: none;
        }
      }
    }
  }
}
