:root {
  --aui-product-price-gap: #{rem($aui-product-price-gap)};

  --aui-product-price-item-gap: #{rem($aui-product-price-item-gap)};
  --aui-product-price-item-border-width: #{rem($aui-product-price-item-border-width)};
  --aui-product-price-item-border-type: #{$aui-product-price-item-border-type};
  --aui-product-price-item-border-color: #{$aui-product-price-item-border-color};
  --aui-product-price-item-padding: #{rem($aui-product-price-item-padding)};

  --aui-product-price-item-label-color: #{$aui-product-price-item-label-color};
  --aui-product-price-item-label-font-size: #{rem($aui-product-price-item-label-font-size)};
  --aui-product-price-item-label-font-weight: #{$aui-product-price-item-label-font-weight};

  --aui-product-price-item-value-color: #{$aui-product-price-item-value-color};
  --aui-product-price-item-value-font-size: #{rem($aui-product-price-item-value-font-size)};
  --aui-product-price-item-value-font-weight: #{$aui-product-price-item-value-font-weight};

  --aui-product-price-item-value-discount-color: #{$aui-product-price-item-value-discount-color};
  --aui-product-price-item-value-discount-font-size: #{rem($aui-product-price-item-value-discount-font-size)};
  --aui-product-price-item-value-discount-font-weight: #{$aui-product-price-item-value-discount-font-weight};

  --aui-product-price-item-value-after-discount-color: #{$aui-product-price-item-value-after-discount-color};
  --aui-product-price-item-value-after-discount-font-size: #{rem($aui-product-price-item-value-after-discount-font-size)};
  --aui-product-price-item-value-after-discount-font-weight: #{$aui-product-price-item-value-after-discount-font-weight};
}