// Variables
$aui-navigation-gap: $aui-gap--base !default;

$aui-navigation-item-gap: $aui-gap--xs !default;
$aui-navigation-item-padding: $aui-padding--xs !default;
$aui-navigation-item-font-family: inherit !default;
$aui-navigation-item-font-size: inherit !default;
$aui-navigation-item-font-weight: inherit !default;
$aui-navigation-item-color: inherit !default;

$aui-navigation-item-dropdown-icon-size: 16px !default;
$aui-navigation-item-dropdown-icon: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTcuNDEgOC41OEwxMiAxMy4xN2w0LjU5LTQuNTlMMTggMTBsLTYgNmwtNi02eiIvPjwvc3ZnPg==") !default;

$aui-navigation-item-submenu-padding: $aui-padding--xs !default;
$aui-navigation-item-submenu-gap: 0 !default;
$aui-navigation-item-submenu-offset: 10px !default;
$aui-navigation-item-submenu-background: $aui-color-white !default;
$aui-navigation-item-submenu-border-radius: $aui-border-radius--base !default;
$aui-navigation-item-submenu-border: rem(1px) solid $aui-color-black !default;

@import '../css-variables/components/navigation';

// Styles
.aui-navigation {
  display: inline-flex;
  gap: var(--aui-navigation-gap);

  a {
    white-space: nowrap;
    text-decoration: none;
  }

  &__item {
    display: inline-flex;
    gap: var(--aui-navigation-item-gap);
    align-items: center;
    
    font-family: var(--aui-navigation-item-font-family);
    font-size: var(--aui-navigation-item-font-size);
    font-weight: var(--aui-navigation-item-font-weight);
    color: var(--aui-navigation-item-color);

    padding: var(--aui-navigation-item-padding);

    &:hover,
    &.aui-navigation__item--is-active {
      text-decoration: underline;
    }

    &.aui-navigation__item--is-highlighted {
      text-decoration: underline;
    }

    &-submenu-container {
      @include aui-transition-base;

      display: flex;

      position: absolute;
      left: 50%;
      z-index: 12;

      pointer-events: none;

      opacity: 0;

      transform: translateX(-50%);

      padding: var(--aui-navigation-item-submenu-padding);
    }

    &-submenu {
      min-width: max-content;

      display: flex;
      flex-direction: column;
      gap: var(--aui-navigation-item-submenu-gap);

      border-radius: var(--aui-navigation-item-submenu-border-radius);
      border: var(--aui-navigation-item-submenu-border);
      background: var(--aui-navigation-item-submenu-background);

      .aui-navigation__item-submenu-container {
        width: 100%;
        
        left: 100%;

        transform: none;
      }
    }
  }

  &__dropdown,
  &__dropdown-submenu {
    position: relative;

    &:hover {
      & > .aui-navigation__item {
        &::after {
          transform: rotate(180deg);
        }
      }

      & > .aui-navigation__item-submenu-container {
        display: flex;

        pointer-events: initial;

        opacity: 1;
      }
    }
  }

  &__dropdown {
    display: inline-flex;

    .aui-navigation__item-submenu-container {
      top: calc(100% + var(--aui-navigation-item-submenu-offset));
    }

    &:hover {
      & > .aui-navigation__item-submenu-container {
        top: 100%;
      }
    }

    &-submenu {
      .aui-navigation__item-submenu-container {
        top: calc(50% + var(--aui-navigation-item-submenu-offset));
      }

      &:hover {
        & > .aui-navigation__item-submenu-container {
          top: 50%;
        }
      }
    }
  }

  &__dropdown,
  &__dropdown-submenu {
    & > .aui-navigation__item {
      position: relative;

      &::after {
        @include aui-transition-base;

        width: var(--aui-navigation-item-dropdown-icon-size);
        height: var(--aui-navigation-item-dropdown-icon-size);

        content: var(--aui-navigation-item-dropdown-icon);
        line-height: 1;
      }
    }
  }
}