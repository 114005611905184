input[type="checkbox"] {
  border-radius: rem(6px);
}

input[type="checkbox"],
input[type="radio"] {
  font-size: var(--aui-font-size--sm);
  line-height: 1;
}


.aui-spinbox {
  --aui-input-height: #{rem(28px)};
  --aui-input-padding--y: #{rem(10px)};
  --aui-input-padding--x: #{rem(12px)};
  --aui-input-font-weight: var(--aui-font-weight--bold);
  --aui-input-border-radius: 0;
  --aui-input-border-color: transparent;
  --aui-input-border-color--focus: transparent;

  input {
    text-align: center;
  }

  &__button {
    width: rem(28px);
    height: rem(28px);
    min-width: 0;

    border-radius: 99999px !important;
    border: rem(1px) solid var(--aui-color-primary);
    border-right: rem(1px) solid var(--aui-color-primary) !important;
    border-left: rem(1px) solid var(--aui-color-primary) !important;

    padding: 0;

    svg {
      width: rem(11px);
      height: rem(11px);
    }
  }
}

.aui-form-item .aui-input-description {
  color: var(--aui-color-text)
}
