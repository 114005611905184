@import 'vanilla-cookieconsent/dist/cookieconsent';

// Variables
@import './../css-variables/components/cookies-policy';

// Styles
#cc-main {
  // this is fix for strange behaviour of cookie consent when there are no description associated with categories
  svg {
    width: auto;
  }

  .cm {
    box-shadow: var(--aui-box-shadow--lg);
  }

  .pm {
    box-shadow: none;

    &__header {
      padding: var(--aui-modal-container-header-padding--y) var(--aui-modal-container-header-padding--x);
    }

    &__body {
      padding: var(--aui-modal-container-content-padding--y) var(--aui-modal-container-content-padding--x);
    }

    &__footer {
      padding: var(--aui-modal-container-footer-padding--y) var(--aui-modal-container-footer-padding--x);
    }
  }
}