// Variables
$aui-file-gap: 8px !default;

$aui-file-icon: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAyMCI+CiAgPHBhdGggZD0iTTEgMTl2LTQuNW0wIDBoMkExLjUgMS41IDAgMCAxIDQuNSAxNnYwQTEuNSAxLjUgMCAwIDEgMyAxNy41SDFtMTQuNS0uMzVIMTNtMy0yLjY1aC0zVjE5bTQtOFY2LjQxNGExIDEgMCAwIDAtLjI5My0uNzA3bC00LjQxNC00LjQxNEExIDEgMCAwIDAgMTEuNTg2IDFIM2EyIDIgMCAwIDAtMiAydjgiIHN0cm9rZT0iIzU4QkFENyIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgogIDxwYXRoIGQ9Ik0xNyA3aC00YTIgMiAwIDAgMS0yLTJWMSIgc3Ryb2tlPSIjNThCQUQ3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CiAgPHBhdGggY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNyAxNC41aDEuNWEyIDIgMCAwIDEgMiAydi41YTIgMiAwIDAgMS0yIDJIN3YtNC41WiIgc3Ryb2tlPSIjNThCQUQ3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==") !default;
$aui-file-icon-size: 18px !default;
$aui-file-icon-padding: 0 !default;
$aui-file-icon-margin: 3px 0 0 0 !default;

$aui-file-info-gap: 4px !default;

$aui-file-info-trigger-font-weight: $aui-font-weight--bold !default;
$aui-file-info-trigger-font-size: inherit !default;
$aui-file-info-trigger-color: $aui-color-primary !default;
$aui-file-info-trigger-text-decoration: underline !default;

$aui-file-info-description-font-weight: inherit !default;
$aui-file-info-description-font-size: inherit !default;
$aui-file-info-description-color: inherit !default;

@import '../css-variables/components/file.scss';

// Styles
.aui-file {
  display: flex;
  gap: var(--aui-file-gap);

  &__icon {
    width: var(--aui-file-icon-size);
    height: var(--aui-file-icon-size);

    content: var(--aui-file-icon);

    padding: var(--aui-file-icon-padding);
    margin: var(--aui-file-icon-margin);

    &:has(svg),
    &:has(img) {
      width: auto;
      height: auto;

      content: unset;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: var(--aui-file-info-gap);

    &-trigger {
      width: max-content;
      
      color: var(--aui-file-info-trigger-color);
      font-weight: var(--aui-file-info-trigger-font-weight);
      font-size: var(--aui-file-info-trigger-font-size);
      text-decoration: var(--aui-file-info-trigger-text-decoration);
    }

    &-description {
      color: var(--aui-file-info-description-color);
      font-weight: var(--aui-file-info-description-font-weight);
      font-size: var(--aui-file-info-description-font-size);

      margin: 0;
    }
  }
}