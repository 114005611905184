// Variables
$aui-header-top-bar-padding: $aui-padding--xs 0 !default;
$aui-header-top-bar-background: $aui-color-primary !default;
$aui-header-top-bar-color: $aui-color-white !default;
$aui-header-top-bar-font-size: $aui-font-size--sm !default;
$aui-header-top-bar-font-weight: $aui-font-weight--base !default;

$aui-header-bar-background: $aui-color-white !default;
$aui-header-bar-box-shadow: $aui-box-shadow--base !default;
$aui-header-bar-padding: $aui-padding--sm 0 !default;
$aui-header-bar-logo-max-width: 150px !default;
$aui-header-bar-navigation-justify-content: center !default;

$aui-header-burger-offset-top: 0 !default;
$aui-header-burger-background: $aui-color-white !default;
$aui-header-burger-padding: 16px 0 !default;

$aui-header-mobile-breakpoint: $aui-breakpoint-lg !default;

@import '../css-variables/layout/header';

// Styles
.aui-header {
  &--pinned {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999999;
  }

  // Top bar
  .aui-header-top-bar {
    width: 100%;

    position: relative;
    z-index: 12;

    background: var(--aui-header-top-bar-background);

    & > .aui-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: var(--aui-gap--base);
    }

    .aui-button {
      gap: var(--aui-gap--xs);
    }

    * {
      color: var(--aui-header-top-bar-color);
      font-size: var(--aui-header-top-bar-font-size);
      font-weight: var(--aui-header-top-bar-font-weight);
    }

    padding: var(--aui-header-top-bar-padding);

    &--resp-hide {
      display: none;

      @media #{$aui-breakpoint-sm} {
        display: block;
      }
    }

    &__contacts,
    &__actions {
      display: inline-flex;
      align-items: center;
      gap: var(--aui-gap--xs) var(--aui-gap--base);
    }

    &__contacts {
      flex-wrap: wrap;

      &-item {
        display: inline-flex;
        align-items: center;
        gap: var(--aui-gap--base);

        & > span {
          display: none;

          @media #{$aui-breakpoint-xs} {
            display: inline-block;
          }
        }
      }
    }

    &__actions {
      display: none;

      @media #{$aui-header-mobile-breakpoint} {
        display: inline-flex;
      }
    }
  }

  // Bar
  .aui-header-bar {
    position: relative;
    z-index: 12;

    background: var(--aui-header-bar-background);

    box-shadow: var(--aui-header-bar-box-shadow);

    padding: var(--aui-header-bar-padding);

    & > .aui-container {
      display: flex;
      justify-content: space-between;
      gap: var(--aui-gap--base);
    }

    &__logo,
    &__navigation,
    &__actions {
      display: flex;
      align-items: center;
    }

    &__logo {
      flex-shrink: 0;

      &-element {
        max-width: var(--aui-header-bar-logo-max-width);
      }
    }

    &__navigation {
      display: none;

      flex: 1;
      justify-content: var(--aui-header-bar-navigation-justify-content);

      @media #{$aui-header-mobile-breakpoint} {
        display: flex;
      }
    }

    &__actions {
      gap: var(--aui-gap--base);

      &-cart,
      &-burger {
        --aui-button-icon-size: #{rem(24px)};
      }

      &-cart {
        span {
          display: none;

          @media #{$aui-header-mobile-breakpoint} {
            display: inline-block;
          }
        }
      }

      &-burger {
        @media #{$aui-header-mobile-breakpoint} {
          display: none;
        }

        .aui-header-burger-close {
          display: none;
        }

        &--is-open {
          .aui-header-burger-open {
            display: none;
          }

          .aui-header-burger-close {
            display: block;
          }
        }
      }
    }
  }

  // Burger
  .aui-header-burger {
    width: 100%;
    height: 100%;

    position: absolute;
    left: 0;
    top: 0;

    display: none;

    background: var(--aui-header-burger-background);

    padding-top: var(--aui-header-burger-offset-top);

    @media #{$aui-header-mobile-breakpoint} {
      display: none;
    }

    & > .aui-container {
      height: 100%;

      display: flex;
      flex-direction: column;
      gap: rem(24px);

      padding: var(--aui-header-burger-padding);
    }

    &__navigation {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .aui-navigation {
        width: 100%;

        flex-direction: column;

        &__dropdown,
        &__dropdown-submenu {
          flex-direction: column;

          & > .aui-navigation__item {
            width: 100%;

            justify-content: space-between;
          }

          &:not(.no-hover):hover {
            & > .aui-navigation__item-submenu-container {
              display: flex;
            }
          }
        }

        &__item {
          font-size: var(--aui-font-size--base);

          &-submenu-container {
            max-height: rem(150px);
            width: 100%;

            position: static;
            left: auto;
            top: 0;

            display: none;

            transform: none;

            overflow-y: auto;

            .aui-navigation__item {
              padding-right: 0;
            }
          }

          &-submenu {
            width: 100%;

            border: none;
          }
        }
      }

      // @media #{$aui-breakpoint-sm} and (max-height: 600px)  {
        // flex-direction: row;
      // }
    }

    &__contacts {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: var(--aui-gap--base);
    }

    &__actions {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: var(--aui-gap--base);
    }
  }

  &--is-open {
    height: 100dvh;

    .aui-header-bar {
      box-shadow: none;
    }

    .aui-header-burger {
      display: block;
    }
  }
}