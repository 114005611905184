@import '@splidejs/splide/dist/css/splide.min';

// Styles
.splide {
  &[unbounded]:has(.splide__arrows) {
    padding-left: 2.7rem;
    padding-right: 2.7rem;
  }

  &__arrow {
    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  &:not([unbounded]) {
    .splide__arrow {
      &--prev {
        left: auto;
        right: calc(100% + 1rem);
      }

      &--next {
        left: calc(100% + 1rem);
        right: auto;
      }
    }
  }

  &__pagination {
    &:not(.splide__pagination--ttb) {
      bottom: auto;
      top: calc(100% + 0.5rem);
    }

    &__page {
      &.is-active {
        background: var(--aui-color-primary);
      }
    }
  }
}
