// Variables
$aui-blog-perex-color: inherit !default;
$aui-blog-perex-font-size: inherit !default;
$aui-blog-perex-font-weight: $aui-font-weight--base !default;

@import "./../css-variables/components/blog.scss";

// Styles
.aui-blog {
  display: flex;
  flex-direction: column;
  gap: rem(16px);

  text-decoration: none;

  &__img {
    width: 100%;
  }

  &__text {
    flex: 1;
    display: flex;
    flex-direction: column;

    &-perex {
      flex: 1;
      color: var(--aui-blog-perex-color);
      font-size: var(--aui-blog-perex-font-size);
      font-weight: var(--aui-blog-perex-font-weight);
    }

    &-subtitle {
      margin-bottom: 0;
      margin-top: 0;
    }

    &-button {
      margin-top: rem(24px);
    }
  }
}