// Variables
$aui-timeline-gap: 24px !default;

$aui-timeline-item-gap: 36px !default;
$aui-timeline-item-max-width--col: 430px !default;
$aui-timeline-item-max-width--row: 290px !default;

$aui-timeline-item-mark-gap: 15px !default;
$aui-timeline-item-mark-size: 48px !default;
$aui-timeline-item-mark-background: $aui-color-primary !default;
$aui-timeline-item-mark-color: $aui-color-white !default;
$aui-timeline-item-mark-font-weight: $aui-font-weight--bold !default;
$aui-timeline-item-mark-border-radius: $aui-border-radius--full !default;
$aui-timeline-item-mark-border-width: 1px !default;
$aui-timeline-item-mark-border-type: solid !default;
$aui-timeline-item-mark-border-color: transparent !default;

@import './../css-variables/sections/timeline';

// Styles
.aui-timeline-section {
  &:not(.aui-section) {
    margin-bottom: var(--aui-section-margin-bottom);
  }

  &__content {
    display: flex;
    gap: var(--aui-timeline-gap);
    flex-wrap: wrap;
    justify-content: flex-start;

    &--centered {
      align-items: stretch;
      justify-content: center;
    }

    &--col {
      flex-direction: column;
      justify-content: center;

      &.aui-timeline-section__content--centered {
        align-items: center;
      }

      .aui-timeline-section__content__item {
        max-width: var(--aui-timeline-item-max-width--col);

        flex-direction: row;

        &__mark {
          flex-direction: column;
          justify-content: space-between;

          &::after {
            width: rem(1px);
            height: 100%;
            min-height: rem(60px);
          }
        }

        &:last-child {
          .aui-timeline-section__content__item__mark {
            &::after {
              display: none;
            }
          }
        }
      }
    }

    &__item {
      width: 100%;
      max-width: var(--aui-timeline-item-max-width--row);

      display: flex;
      flex-direction: column;
      gap: var(--aui-timeline-item-gap);

      &__mark {
        display: flex;
        align-items: center;
        gap: var(--aui-timeline-item-mark-gap);

        &__shape {
          width: var(--aui-timeline-item-mark-size);
          height: var(--aui-timeline-item-mark-size);

          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          flex-shrink: 0;

          background: var(--aui-timeline-item-mark-background);

          border-radius: var(--aui-timeline-item-mark-border-radius);
          border: var(--aui-timeline-item-mark-border-width) var(--aui-timeline-item-mark-border-type) var(--aui-timeline-item-mark-border-color);

          &__number {
            color: var(--aui-timeline-item-mark-color);
            font-weight: var(--aui-timeline-item-mark-font-weight);
          }
        }

        &::after {
          width: 100%;
          height: rem(1px);

          display: block;

          content: '';

          background: var(--aui-timeline-item-mark-background);
        }
      }

      &__text {
        text-align: left;

        &__title {
          margin-top: 0;
          text-align: left;
        }
      }
    }
  }
}
