@mixin titleList {
  height: max-content;

  position: relative;

  font-size: var(--aui-font-size--sm);
  font-weight: var(--aui-font-weight--semi-bold);
  text-transform: uppercase;

  padding-left: rem(20px);

  &::before {
    width: rem(7px);
    height: rem(7px);

    position: absolute;
    left: 0;
    top: 50%;
    content: '';

    border-radius: var(--aui-border-radius--full);

    background: var(--aui-color-primary);

    transform: translateY(-50%);
  }
}