.aui-product-block {
  &__flags {
    top: rem(12px);
    left: rem(8px);

    .aui-badge {
      color: var(--aui-color-white);
      background: var(--badge-background, var(--aui-color-primary));
      border-color: var(--badge-background, var(--aui-color-primary));
    }

    &--right {
      right: rem(8px);
      left: auto;
    }
  }

  &__image {
    position: relative;

    .image-overlay-block {
      width: 100%;
      height: 100%;

      position: absolute;
      left: 0;
      top: 0;
      z-index: 12;

      overflow-y: hidden;
    }

    .image-overlay {
      @include aui-transition-base;

      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;

      position: absolute;
      left: 0;
      top: 0;
      z-index: 123;

      font-size: var(--aui-font-size--sm);

      background: var(--image-overlay-background, var(--aui-color-gray--dark));

      transform: translateY(100%);

      padding: rem(27px);

      &:has(img), &:has(.aui-image) {
        padding: 0;
      }

      .font-weight-semi-bold {
        font-size: var(--aui-font-size--base);

        margin-top: rem(6px);
      }

      hr {
        margin: rem(16px) 0;
      }

      &__button {
        flex: 1;
        display: flex;
        align-items: flex-end;

        padding-bottom: rem(20px);

        span {
          text-decoration: underline;
        }
      }

      .aui-image,
      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }

    &:has(.image-overlay) {
      --aui-image-scale: 1;
    }

    @media #{$aui-breakpoint-md} {
      &:hover {
        .image-overlay {
          opacity: 1;
          transform: none;
        }
      }
    }
  }

  &__content {
    &-title {
      font-size: rem(21px);
      font-weight: var(--aui-font-weight--bold);

      margin-bottom: rem(8px);
    }

    &-subtitle {
      font-size: var(--aui-font-size--base);

      margin-bottom: rem(20px);
    }

    &-description {
      margin-bottom: rem(24px);
    }

    &-price {
      opacity: .3;

      margin-bottom: 0;
    }

    &-buttons {
      --aui-button-font-size: var(--aui-font-size--base);
      --aui-button-font-weight: var(--aui-font-weight--bold);

      display: flex;
      align-items: flex-end;

      &--row {
        justify-content: space-between;
      }

      .aui-button {
        width: auto;

        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.aui-products-section {
  &__products {
    display: flex;
    gap: rem(12px);

    .aui-product-block {
      max-width: 33.3%;

      flex-grow: 1;

      @media only screen and (max-width: map-get($aui-breakpoints, md)) {
        min-width: rem(210px);
        width: 37vw;
        max-width: 100%;
      }

      @media only screen and (max-width: map-get($aui-breakpoints, sm)) {
        width: 57vw;
      }
    }
  }

  .aui-grid {
    gap: rem(66px) rem(24px);
    grid-template-columns: var(--aui-grid-col-max-width);

    @media #{$aui-breakpoint-md} {
      grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
      gap: rem(42px);
    }

    @media #{$aui-breakpoint-lg} {
      gap: rem(72px);
      grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
    }
  }
}
