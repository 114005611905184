// Variables
$aui-text-with-image-gap: 40px !default;

@import './../css-variables/sections/text-with-image';

// Styles
.aui-text-with-image-section {
  display: flex;
  flex-direction: column;
  gap: var(--aui-text-with-image-gap);

  &:not(.aui-section) {
    margin-bottom: var(--aui-section-margin-bottom);
  }

  @media #{$aui-breakpoint-md} {
    flex-direction: row;
    align-items: center;

    &--v-center {
      align-items: center;
    }

    &--v-start {
      align-items: flex-start;
    }

    &--v-end {
      align-items: flex-end;
    }

    & > div {
      width: calc(var(--aui-half) - var(--aui-text-with-image-gap) / 2);
    }
  }

  &--row-reverse {
    flex-direction: column-reverse;

    @media #{$aui-breakpoint-md} {
      flex-direction: row-reverse;

      & > div {
        width: calc(var(--aui-half) - var(--aui-text-with-image-gap) / 2);
      }
    }
  }

  &--resp-col-reverse {
    flex-direction: column-reverse;
  }

  &__image {
    display: flex;
    justify-content: center;
  }
}