@import 'simplelightbox/src/simple-lightbox.scss';

// Variables
$aui-gallery-grid-col: 5 !default;
$aui-gallery-grid-gap: clamp(rem(12px), 1.34vw, rem(24px)) !default;

$aui-gallery-item-overlay-content: attr(data-aui-over-count) !default;

$aui-gallery-dual-grid-gap: 16px !default;

@import './../css-variables/sections/gallery';

// Styles
.aui-gallery-section {
  &:not(.aui-section) {
    margin-bottom: var(--aui-section-margin-bottom);
  }

  &__content {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / var(--aui-gallery-grid-col, 2) - var(--aui-gallery-grid-gap)), 1fr));

    @media #{$aui-breakpoint-md} {
      --aui-gallery-grid-col: #{$aui-gallery-grid-col};
    }

    gap: var(--aui-gallery-grid-gap);

    .aui-image--overlay {
      &::before {
        content: var(--aui-gallery-item-overlay-content);
      }
    }

    &--dual {
      display: grid;
      justify-content: center;
      gap: var(--aui-gallery-dual-grid-gap);
      grid-template-areas: 
      "A A"
      "B C";
      grid-template-columns: none;

      @media #{$aui-breakpoint-md} {
        grid-template-areas: 
          "A B"
          "A C";
      }

      & > * {
        &:nth-child(1) {
          height: 100%;

          grid-area: A;

          img {
            height: 100%;
          }
        }

        &:nth-child(2) {
          grid-area: B;
        }

        &:nth-child(3) {
          grid-area: C;
        }
      }
    }
  }
}