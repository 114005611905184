.slider {
  & > .aui-container {
    display: flex;
    flex-direction: column-reverse;
    gap: rem(22px);

    @media #{$aui-breakpoint-md} {
      flex-direction: row;
      justify-content: space-between;
      gap: rem(32px);
    }

    h2, h3 {
      margin: 0;
    }
    
    h2 {
      font-size: rem(16px);
      font-weight: var(--aui-font-weight--bold);

      @media #{$aui-breakpoint-md} {
        font-size: rem(21px);
      }
    }
  
    h3 {
      font-size: rem(12px);
      font-weight: var(--aui-font-weight--regular);

      @media #{$aui-breakpoint-md} {
        font-size: rem(17px);
      }
    }
  }

  &__aside {
    width: 100%;

    display: flex;
    flex-direction: column;

    padding-top: 0;
    padding-bottom: 0;
  
    @media #{$aui-breakpoint-md} {
      width: rem(158px);
      min-width: rem(158px);

      padding-top: rem(26px);
      padding-bottom: rem(30px);
    }

    &-info {
      display: none;

      flex: 1;

      margin-bottom: rem(16px);

      @media #{$aui-breakpoint-md} {
        display: block;
      }
    }

    &-arrows {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: rem(22px);

      @media #{$aui-breakpoint-md} {
        justify-content: flex-start;
      }

      button {
        svg {
          @include aui-transition-base;

          color: var(--aui-color-primary);

          width: rem(22px);
        }

        &:hover {
          &.left {
            svg {
              transform: translateX(-2px);
            }
          }

          &.right {
            svg {
              transform: translateX(2px);
            }
          }
        }
      }
    }
  }

  &__slides {
    width: 100%;

    .splide__slide {
      max-width: rem(209px);

      @media #{$aui-breakpoint-md} {
        max-width: rem(357px);
      }
      
      &--full {
        max-width: 100%;
      }

      &-slogan {
        font-size: clamp(1.125rem, 1.1rem + 1.5vw, 2.375rem); // 18px -> 38px 
        line-height: 1.5;
      }

      &-info {
        margin-top: rem(18px);

        @media #{$aui-breakpoint-md} {
          display: none;
        }
      }
    }
  }

  &:has(.splide__slide-slogan) {
    .slider__aside {
      @media #{$aui-breakpoint-md} {
        width: rem(198px);

        padding-top: rem(10px);
        padding-bottom: rem(10px);
      }
    } 

    .slider__slides {
      max-width: rem(811px);
    }
  }
}
