@import '/node_modules/glightbox/dist/css/glightbox.min.css';

// Variables
$aui-gallery-lightbox-padding: 16px !default;

@import './../css-variables/components/gallery';

// Styles
.glightbox-clean {
  .gslider {
    padding: var(--aui-gallery-lightbox-padding);
  }
}

.gslide-description.description-left, .gslide-description.description-right {
  @media #{$aui-breakpoint-md} {
    max-width: rem(400px);
  }
}

