:root {
  --aui-loader-overlay-background: #{$aui-loader-overlay-background};
  --aui-loader-overlay-color: #{$aui-loader-overlay-color};
  --aui-loader-overlay-font-size: #{rem($aui-loader-overlay-font-size)};
  --aui-loader-overlay-font-weight: #{$aui-loader-overlay-font-weight};

  --aui-loader-overlay-icon-min-height: #{rem($aui-loader-overlay-icon-min-height)};

  --aui-loader-overlay-icon-color: #{$aui-loader-overlay-icon-color};
  --aui-loader-overlay-icon-max-size: #{rem($aui-loader-overlay-icon-max-size)};

  --aui-loader-overlay-icon-animation: #{$aui-loader-overlay-icon-animation};
  --aui-loader-overlay-icon-path-animation: #{$aui-loader-overlay-icon-path-animation};
}