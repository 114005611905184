.aui-section-header {
  display: flex;
  flex-direction: column;
  gap: rem(17px);

  @media #{$aui-breakpoint-md} {
    align-items: flex-end;
    flex-direction: row;
    gap: var(--sections-gap-x);
  }

  &__top,
  &__description {
    width: 100%;
    
    flex-grow: 1;
  }

  &__top {
    h2, .aui-like-h2 {
      margin: 0;
    }
  }

  &__description {
    h3, .aui-like-h3 {
      font-size: rem(20px);
      font-weight: var(--aui-font-weight--bold);

      margin: 0;
    }

    p {
      margin-top: 0 !important;
    }
  }

  &:not(:has(.aui-section-header__description)) {
    .aui-section-header__top {
      max-width: rem(746px);
    }
  }
}