// Styles
html {
  border: none !important;
}

body {
  position: relative;

  background: var(--aui-color-background);

  margin: 0;
  padding: 0;

  &.aui-lock-scroll,
  &.aui-loader-overlay,
  &:has(.aui-modal--lock-scroll) {
    max-height: 100dvh;
    overflow: hidden;
  }
}

img, svg {
  width: 100%;

  display: block;
}

iframe {
  display: block;
}

main {
  min-height: 100dvh;

  overflow: hidden;
}

hr {
  width: 100%;

  border-color: var(--aui-color-border);
  border-style: solid;
  border-width: rem(1px);
}

abbr {
  color: var(--aui-color-primary);
}

button,
label,
input,
select,
textarea,
blockquote {
  outline: none;
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
}

button {
  cursor: pointer;
}

section {

}

strong {
  font-weight: var(--aui-font-weight--bold);
}

table {

}

ol, ul {
  padding-left: rem(20px);
}

ol {
  li {
    &::marker {
      color: var(--aui-color-list--ol);
    }
  }
}

ul {
  li {
    &::marker {
      color: var(--aui-color-list--ul);
    }
  }
}