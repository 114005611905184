// Variables
$aui-icon-size--sm: 16px !default;
$aui-icon-size--base: 24px !default;
$aui-icon-size--lg: 32px !default;

$aui-icon-background: $aui-color-white !default;
$aui-icon-color: inherit !default;
$aui-icon-border-width: 1px !default;
$aui-icon-border-type: solid !default;
$aui-icon-border-color: $aui-color-border !default;
$aui-icon-border-radius: $aui-border-radius--md !default;
$aui-icon-padding: $aui-padding--sm !default;

@import './../css-variables/components/icon.scss';

// Styles
.aui-icon {
  flex-shrink: 0;

  color: var(--aui-icon-color);

  svg, i {
    @include aui-transition-base;

    width: var(--aui-icon-size--base);
    height: var(--aui-icon-size--base);

    flex-shrink: 0;

    color: var(--aui-icon-color);
  }

  &--sm  {
    svg, i {
      width: var(--aui-icon-size--sm);
      height: var(--aui-icon-size--sm);
    }
  }

  &--base {
    svg, i {
      width: var(--aui-icon-size--base);
      height: var(--aui-icon-size--base);
    }
  }

  &--lg {
    svg, i {
      width: var(--aui-icon-size--lg);
      height: var(--aui-icon-size--lg);
    }
  }

  &--bounded {
    overflow: hidden;

    background: var(--aui-icon-background);
    border-radius: var(--aui-icon-border-radius);
    border: var(--aui-icon-border-width) var(--aui-icon-border-type) var(--aui-icon-border-color);

    padding: var(--aui-icon-padding);
  }
}