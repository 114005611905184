.splide__arrows,
.splide__pagination {
  display: none;
}

.splide__pagination {
  li {
    padding-left: 0;

    &::before {
      display: none;
    }
  }

  &__page {
    background: transparent;
    border: rem(1px) solid var(--aui-color-primary);
    opacity: 1;

    &.is-active {
      transform: none;
      background: var(--aui-color-primary);
    }
  }
}