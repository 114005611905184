$aui-breadcrumbs-separator: "'/'" !default; // If value is only string not (url or attr) function, use it with double quotes
$aui-breadcrumbs-font-size: $aui-font-size--base !default;
$aui-breadcrumbs-color: $aui-color-dark !default;
$aui-breadcrumbs-last-color: $aui-breadcrumbs-color !default;
$aui-breadcrumbs-last-font-weight: $aui-font-weight--bold !default;

@import './../css-variables/components/breadcrumbs';

.aui-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: rem(4px) 0;

  padding: 0;
  margin: 0;

  li, &__item {
    display: flex;
    font-size: var(--aui-breadcrumbs-font-size);
    color: var(--aui-breadcrumbs-color);

    & > * {
      font-size: var(--aui-breadcrumbs-font-size);
      color: var(--aui-breadcrumbs-color);
    }

    &::after {
      content: var(--aui-breadcrumbs-separator);
      margin: 0 rem(10px);
    }

    &:last-child {
      color: var(--aui-breadcrumbs-last-color);

      & > * {
        color: var(--aui-breadcrumbs-last-color);
        font-weight: var(--aui-breadcrumbs-last-font-weight);
        pointer-events: none;
        text-decoration: none;
      }

      &::after {
        display: none;
      }
    }
  }

  &--center {
    justify-content: center;
  }

  &--scroll {
    flex-wrap: nowrap;

    position: relative;

    overflow-x: auto;

    padding-bottom: rem(15px);

    .aui-breadcrumbs__item {
      white-space: nowrap;
    }
  }
}