// Variables
$aui-announcement-background: $aui-color-primary !default;
$aui-announcement-padding--y: $aui-padding--sm !default;
$aui-announcement-padding--x: $aui-padding--sm !default;
$aui-announcement-color: $aui-color-white !default;
$aui-announcement-font-size: $aui-font-size--sm !default;

$aui-announcement-link-color: $aui-color-white !default;
$aui-announcement-link-color--hover: $aui-color-white !default;

@import './../css-variables/components/announcement.scss';

// Styles
.aui-announcements {
  width: 100%;

  &--fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
  }
}

.aui-announcement {
  width: 100%;

  position: relative;

  background: var(--aui-announcement-background);

  &:hover {
    text-decoration: none;
  }

  &__container {
    padding: var(--aui-announcement-padding--y) var(--aui-announcement-padding--x);
    
    &-content {
      display: flex;
      flex-direction: column;

      padding-right: rem(40px);

      * {
        display: inline-block;

        font-size: var(--aui-announcement-font-size);
        color: var(--aui-announcement-color);
      }

      a {
        font-size: var(--aui-announcement-font-size);
        color: var(--aui-announcement-link-color);

        &:hover {
          color: var(--aui-announcement-link-color--hover);
        }
      }
    }

    &-close {
      position: absolute;
      right: var(--aui-announcement-padding--x);
      top: 50%;
      z-index: 12;

      transform: translateY(-50%);

      color: var(--aui-announcement-color);
    }
  }
}