#cc-main {
  --cc-font-family: var(--aui-font-family--secondary);

  --cc-modal-border-radius: var(--aui-modal-container-border-radius);
  
  --cc-btn-border-radius: var(--aui-button-border-radius);
  --cc-link-color: var(--aui-color-link);
  --cc-bg: var(--aui-color-white);

  --cc-color-primary: var(--aui-color-primary);
  --cc-color-secondary: var(--aui-color-secondary);

  --cc-btn-primary-bg: var(--aui-button-primary-background);
  --cc-btn-primary-color: var(--aui-button-primary-color);
  --cc-btn-primary-border-color: var(--aui-button-primary-border-color);

  --cc-btn-primary-hover-bg: var(--aui-button-primary-background--hover);
  --cc-btn-primary-hover-color: var(--aui-button-primary-color--hover);
  --cc-btn-primary-hover-border-color: var(--aui-button-primary-border-color--hover);

  --cc-btn-secondary-bg: var(--aui-button-regular-outline-background);
  --cc-btn-secondary-color: var(--aui-button-regular-outline-color);
  --cc-btn-secondary-border-color: var(--aui-button-regular-outline-border-color);

  --cc-btn-secondary-hover-bg: var(--aui-button-regular-outline-background--hover);
  --cc-btn-secondary-hover-color: var(--aui-button-regular-outline-color--hover);
  --cc-btn-secondary-hover-border-color: var(--aui-button-regular-outline-border-color--hover);

  --cc-separator-border-color: var(--aui-color-border);

  --cc-overlay-bg: var(--aui-modal-background);

  --cc-modal-transition-duration: var(--aui-modal-animation-duration);
}