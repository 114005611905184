.order-detail-item {
    width: 100%;

    display: flex;
    gap: rem(28px);

    border-bottom: rem(1px) solid var(--aui-color-primary);

    padding: rem(24px) 0;

    &:first-child {
        padding-top: 0;
    }

    &__image {
        width: rem(92px);

        flex-shrink: 0;

        img {
            height: 100%;

            object-fit: cover;
        }
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        gap: rem(20px);
        flex-grow: 1;
        justify-content: space-between;

        &-title {
            .aui-like-h4 {
                font-size: rem(21px);
                font-weight: var(--aui-font-weight--bold);

                margin: 0;

                & > span {
                    @media #{$aui-breakpoint-md} {
                        display: none;
                    }
                }

                & > div {
                    font-size: var(--aui-font-size--sm);
                    font-weight: var(--aui-font-weight--base);

                    margin-top: rem(6px);
                }
            }
        }

        &-price {
            width: rem(162px);

            font-weight: var(--aui-font-weight--semi-bold);
            font-size: rem(23px);

            margin: 0;

            @media #{$aui-breakpoint-md} {
                text-align: right;
            }

            & > span {
                font-size: rem(20px);
            }
        }
    }
}

.cart-price-totals {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: rem(16px);

    font-weight: var(--aui-font-weight--bold);

    margin: rem(42px) 0;

    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.discount {
            font-size: rem(18px);
        }

        &.total {
            font-size: rem(23px);

            > span {
                font-size: rem(21px);
            }
        }
    }
}

.order-detail {
    .order-detail-item {
        @media #{$aui-breakpoint-md} {
            padding: rem(45px) 0;
        }

        &__image {
            @media #{$aui-breakpoint-md} {
                width: rem(155px);
            }
        }

        &__info {
            @media #{$aui-breakpoint-md} {
                align-items: flex-end;
            }

            &-title {
                width: 100%;

                @media #{$aui-breakpoint-sm} {
                    width: 100%;
                    max-width: 100%;

                    h3, .aui-like-h4 {
                        & > div {
                            font-size: var(--aui-font-size--base);
                        }
                    }
                }

                @media #{$aui-breakpoint-md} {
                    width: 64%;
                    order: 0;
                }
            }

            &-price {
                @media #{$aui-breakpoint-md} {
                    order: 2;
                }
            }

            &-quantity {
                display: none;

                @media #{$aui-breakpoint-md} {
                    display: block;
                    order: 1;

                    align-self: unset;
                }
            }
        }
    }
}
