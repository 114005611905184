:root {
  --aui-faq-border-width: #{rem($aui-faq-border-width)};
  --aui-faq-border-type: #{$aui-faq-border-type};
  --aui-faq-border-color: #{$aui-faq-border-color};
  --aui-faq-border-radius: #{rem($aui-faq-border-radius)};
  --aui-faq-padding: #{rem($aui-faq-padding)};
  --aui-faq-margin-bottom: #{rem($aui-faq-margin-bottom)};
  --aui-faq-trigger-size: #{rem($aui-faq-trigger-size)};
  --aui-faq-trigger-open-icon: #{$aui-faq-trigger-open-icon};
  --aui-faq-trigger-close-icon: #{$aui-faq-trigger-close-icon};
}