:root {
  --aui-cta-border: #{$aui-cta-border};
  --aui-cta-border-radius: #{rem($aui-cta-border-radius)};
  --aui-cta-heading-color: #{$aui-cta-heading-color};
  --aui-cta-content-color: #{$aui-cta-content-color};
  --aui-cta-background: #{$aui-cta-background};
  --aui-cta-box-shadow: #{$aui-cta-box-shadow};
  --aui-cta-padding: #{rem($aui-cta-padding)};
  --aui-cta-padding--sm: #{rem($aui-cta-padding--sm)}
}
