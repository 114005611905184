.page-contacts {
  #content {
    .aui-line {
      &:nth-child(2) {
        @media #{$aui-breakpoint-md} {
          margin-top: calc(var(--aui-section-margin-bottom) * -1 - 10%);
        }

        .text-with-image {
          &__info {
            & > h3 {
              display: none;
            }

            & > div {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}