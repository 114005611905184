// Variables
$aui-table-min-width: 650px !default;
$aui-table-padding--y: 12px !default;
$aui-table-padding--x: 8px !default;

$aui-table-row-background--even: transparent !default;
$aui-table-row-color--even: inherit !default;

$aui-table-row-background--odd: $aui-color-border !default;
$aui-table-row-color--odd: $aui-color-white !default;

@import './../css-variables/components/table';

// Styles
.aui-table {
  width: 100%;
  min-width: var(--aui-table-min-width);

  border-collapse: collapse;

  td, th {
    padding: var(--aui-table-padding--y) var(--aui-table-padding--x);
   }

  thead {
    th {
      font-weight: var(--aui-font-weight--bold);
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background: var(--aui-table-row-background--even);
        color: var(--aui-table-row-color--event);
      }

      &:nth-child(odd) {
        background: var(--aui-table-row-background--odd);
        color: var(--aui-table-row-color--odd);
      }
    }
  }
}

// Classes
.aui-table-wrapper {
  width: 100%;

  overflow-x: auto;
}