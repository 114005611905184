:root {
  --aui-header-top-bar-padding: #{$aui-header-top-bar-padding};
  --aui-header-top-bar-background: #{$aui-header-top-bar-background};
  --aui-header-top-bar-color: #{$aui-header-top-bar-color};
  --aui-header-top-bar-font-size: #{rem($aui-header-top-bar-font-size)};
  --aui-header-top-bar-font-weight: #{$aui-header-top-bar-font-weight};

  --aui-header-bar-background: #{$aui-header-bar-background};
  --aui-header-bar-box-shadow: #{$aui-header-bar-box-shadow};
  --aui-header-bar-padding: #{$aui-header-bar-padding};
  --aui-header-bar-navigation-justify-content: #{$aui-header-bar-navigation-justify-content};
  --aui-header-bar-logo-max-width: #{rem($aui-header-bar-logo-max-width)};

  --aui-header-burger-offset-top: #{rem($aui-header-burger-offset-top)};
  --aui-header-burger-background: #{$aui-header-burger-background};
  --aui-header-burger-padding: #{rem($aui-header-burger-padding)};
}