[aui-kit] {
  .header {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 123;
  
    background: var(--aui-color-white);
    box-shadow: var(--aui-box-shadow--base);
  
    &__nav {
      display: flex;
      gap: var(--aui-gap--base);
  
      padding: var(--aui-padding--md) 0;
    }
  }
}
