// Variables
$aui-benefit-gap: 24px !default;

@import "./../css-variables/components/benefit.scss";

// Styles
.aui-benefit {
  display: flex;
  gap: var(--aui-benefit-gap);

  &--center {
    align-items: center;

    &:is(.aui-benefit--col) {
      .aui-benefit__info {
        * {
          text-align: center;
        }
      }
    }
  }

  &--col {
    flex-direction: column;
  }
}