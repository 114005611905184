// Variables
$aui-quote-border: none !default;
$aui-quote-border-radius: $aui-border-radius--md !default;
$aui-quote-background: transparent !default;
$aui-quote-heading-color: inherit !default;
$aui-quote-content-color: inherit !default;
$aui-quote-padding: $aui-padding--lg !default;
$aui-quote-padding--sm: $aui-padding--md !default;

$aui-quote-quote-color: $aui-color-primary !default;

@import './../css-variables/sections/quote';

// Styles
.aui-quote-section {
  --aui-quote-quotes-size: #{rem(30px)};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  background: var(--aui-quote-background);

  border: var(--aui-quote-border);
  border-radius: var(--aui-quote-border-radius);

  padding: var(--aui-quote-padding--sm);

  @media #{$aui-breakpoint-md} {
    --aui-quote-quotes-size: #{rem(50px)};

    padding: var(--aui-quote-padding);
  }

  &:not(.aui-section) {
    margin-bottom: var(--aui-section-margin-bottom);
  }

  &::after,
  &::before  {
    width: var(--aui-quote-quotes-size);
    height: var(--aui-quote-quotes-size);

    display: flex;
    align-items: center;

    position: absolute;
    content: '';
    
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" xml:space="preserve"><path fill="#{$aui-quote-quote-color}" d="M0 4v12h8c0 4.41-3.586 8-8 8v4c6.617 0 12-5.383 12-12V4H0zm20 0v12h8c0 4.41-3.586 8-8 8v4c6.617 0 12-5.383 12-12V4H20z"/></svg>');
    background-repeat: no-repeat;
  }

  &::before {
    top: var(--aui-quote-padding--sm);
    left: var(--aui-quote-padding--sm);

    @media #{$aui-breakpoint-md} {
      top: var(--aui-quote-padding);
      left: var(--aui-quote-padding);
    }
  }

  &::after {
    bottom: var(--aui-quote-padding--sm);
    right: var(--aui-quote-padding--sm);

    @media #{$aui-breakpoint-md} {
      bottom: var(--aui-quote-padding);
      right: var(--aui-quote-padding);
    }
  }

  * {
    text-align: center;
  }

  &__title,
  &__subtitle,
  &__content,
  &__author {
    padding: 0 calc(var(--aui-quote-quotes-size) + var(--aui-padding--sm));
  }

  &__title, &__subtitle {
    color: var(--aui-quote-heading-color);
  }

  &__content {
    color: var(--aui-quote-content-color);
  }

  &__author {
    margin-top: var(--aui-margin--md);
  }

  &__button {
    margin-top: var(--aui-margin--md);

    @media #{$aui-breakpoint-md} {
      margin-top: var(--aui-margin--lg);
    }
  }
}