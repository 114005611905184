.page-reference {
    .text-with-image {
        @media only screen and (max-width: map-get($aui-breakpoints, md)) {
            flex-direction: column;
        }

        &__info {
            > div {
                p {
                    overflow-wrap: anywhere;
                }
            }
        }
    }
}