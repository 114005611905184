// Variables
$aui-signpost-section-signpost-basis: 300px !default;
$aui-signpost-section-signposts-gap: $aui-gap--base !default;

@import '../css-variables/sections/signpost.scss';

// Styles
.aui-signpost-section {
  &:not(.aui-section) {
    margin-bottom: var(--aui-section-margin-bottom);
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: var(--aui-signpost-section-signposts-gap);

    .aui-signpost {
      width: var(--aui-signpost-section-signpost-basis);

      flex-grow: 1;
    }
  }
}
