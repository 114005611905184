:root {
  --aui-icon-size--sm: #{rem($aui-icon-size--sm)};
  --aui-icon-size--base: #{rem($aui-icon-size--base)};
  --aui-icon-size--lg: #{rem($aui-icon-size--lg)};

  --aui-icon-background: #{$aui-icon-background};
  --aui-icon-color: #{$aui-icon-color};
  --aui-icon-border-radius: #{rem($aui-icon-border-radius)};
  --aui-icon-border-width: #{rem($aui-icon-border-width)};
  --aui-icon-border-type: #{$aui-icon-border-type};
  --aui-icon-border-color: #{$aui-icon-border-color};
  --aui-icon-padding: #{rem($aui-icon-padding)};
}