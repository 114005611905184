.categories {
  height: 100%;

  & > h3, & > .aui-like-h3 {
    &:not(:empty) {
      @include titleList;

      margin-bottom: rem(40px);
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media #{$aui-breakpoint-md} {
      height: 100%;
    }

    &--flex-start {
      justify-content: flex-start;
    }

    &-item {
      @include aui-transition-base;

      font-size: clamp(1.5rem, 1.4rem + 0.8vw, 2.313rem); // 18px to 37px H4
      color: rgba($aui-color-primary, .3);

      margin-bottom: rem(6px);

      &:last-child {
        margin-bottom: 0;
      }

      &:hover,
      &.is-active {
        color: var(--aui-color-primary);
      }

      &:is(a) {
        --aui-link-decoration: none;
        --aui-link-decoration--hover: underline;

        &.is-active {
          --aui-link-decoration: underline;
        }
      }
    }
  }
}