// Variables
$aui-faq-border-width: 1px !default;
$aui-faq-border-type: solid !default;
$aui-faq-border-color: $aui-color-border !default;
$aui-faq-border-radius: $aui-border-radius--base !default;
$aui-faq-padding: $aui-padding--base !default;
$aui-faq-margin-bottom: $aui-margin--base !default;
$aui-faq-trigger-size: 24px !default;
$aui-faq-trigger-open-icon: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgICAgICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTEgMnY5SDJ2Mmg5djloMnYtOWg5di0yaC05VjJaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz4KICAgICAgPC9zdmc+") !default;
$aui-faq-trigger-close-icon: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMTkgMTNINXYtMmgxNHoiLz48L3N2Zz4=") !default;


@import '../css-variables/components/faq.scss';

// Styles
.aui-faq {
  width: 100%;

  border: var(--aui-faq-border-width) var(--aui-faq-border-type) var(--aui-faq-border-color);
  border-radius: var(--aui-faq-border-radius);

  margin-bottom: var(--aui-faq-margin-bottom);

  &:last-child {
    margin-bottom: 0;
  }

  &__question {
    width: 100%;

    display: flex;
    justify-content: space-between;
    gap: rem(16px);

    padding: var(--aui-faq-padding);

    * {
      margin-bottom: 0;
    }

    &-trigger {
      width: var(--aui-faq-trigger-size);
      height: var(--aui-faq-trigger-size);

      content: var(--aui-faq-trigger-open-icon);

      padding-top: rem(5px);
    }

    &.aui-show-slide--is-open {
      .aui-faq__question-trigger {
        content: var(--aui-faq-trigger-close-icon);
      }
    }
  }

  &__answer {
    width: 100%;

    padding: var(--aui-faq-padding);
  }
}