@import 'variables/layout';

// Styles
.aui-container {
  width: var(--aui-container-width);
	max-width: var(--aui-container-max-width--base);

  margin: 0 auto;

	&--xs {
		max-width: var(--aui-container-max-width--xs);
	}

	&--sm {
		max-width: var(--aui-container-max-width--sm);
	}

	&--base {
		max-width: var(--aui-container-max-width--base);
	}

	&--lg {
		max-width: var(--aui-container-max-width--lg);
	}

	&--xl {
		max-width: var(--aui-container-max-width--xl);
	}

	&--fluid {
		width: 100%;
		max-width: 100%;

		padding: 0 var(--aui-container-fluid-padding);
		margin: 0;
	}
}