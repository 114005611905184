// Variables
$aui-product-price-gap: $aui-gap--xs !default;

$aui-product-price-item-gap: $aui-gap--xs !default;
$aui-product-price-item-border-width: 1px !default;
$aui-product-price-item-border-type: solid !default;
$aui-product-price-item-border-color: $aui-color-border !default;
$aui-product-price-item-padding: 0 0 $aui-padding--xs 0 !default;

$aui-product-price-item-label-color: inherit !default;
$aui-product-price-item-label-font-size: $aui-font-size--lg !default;
$aui-product-price-item-label-font-weight: inherit !default;

$aui-product-price-item-value-color: inherit !default;
$aui-product-price-item-value-font-size: $aui-font-size--lg !default;
$aui-product-price-item-value-font-weight: inherit !default;

$aui-product-price-item-value-discount-color: inherit !default;
$aui-product-price-item-value-discount-font-size: inherit !default;
$aui-product-price-item-value-discount-font-weight: inherit !default;

$aui-product-price-item-value-after-discount-color: inherit !default;
$aui-product-price-item-value-after-discount-font-size: inherit !default;
$aui-product-price-item-value-after-discount-font-weight: inherit !default;


@import "./../css-variables/components/product-price.scss";

// Styles
.aui-product-price {
  display: flex;
  flex-direction: column;
  gap: var(--aui-product-price-gap);

  &__item {
    width: 100%;

    display: flex;
    justify-content: space-between;
    gap: var(--aui-product-price-item-gap);

    border-bottom: var(--aui-product-price-item-border-width) var(--aui-product-price-item-border-type) var(--aui-product-price-item-border-color);

    padding: var(--aui-product-price-item-padding);

    &:last-child {
      border-bottom: none;
    }

    &--col {
      flex-direction: column;
    }

    &--left {
      justify-content: flex-start;
      align-items: flex-start;
    }

    &--center {
      justify-content: center;
      align-items: center;
    }

    &--right {
      justify-content: flex-end;
      align-items: flex-end;
    }

    &-label {
      color: var(--aui-product-price-item-label-color);
      font-size: var(--aui-product-price-item-label-font-size);
      font-weight: var(--aui-product-price-item-label-font-weight);
    }

    &-value {
      display: inline-flex;
      flex-wrap: wrap;
      gap: var(--aui-gap--xs);

      color: var(--aui-product-price-item-value-color);
      font-size: var(--aui-product-price-item-value-font-size);
      font-weight: var(--aui-product-price-item-value-font-weight);

      * {
        line-height: 1;
      }

      &:has(.aui-product-price-after-discount) {
        .aui-product-price-before-discount {
          text-decoration: line-through;
          font-weight: $aui-font-weight--base;
        }

        .aui-product-price-after-discount {
          font-weight: $aui-font-weight--bold;
        }
      }

      .aui-product-price-discount {
        color: var(--aui-product-price-item-value-discount-color);
        font-size: var(--aui-product-price-item-value-discount-font-size);
        font-weight: var(--aui-product-price-item-value-discount-font-weight);
      }

      .aui-product-price-after-discount {
        color: var(--aui-product-price-item-value-after-discount-color);
        font-size: var(--aui-product-price-item-value-after-discount-font-size);
        font-weight: var(--aui-product-price-item-value-after-discount-font-weight);
      }
    }
  }
}