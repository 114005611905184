:root {
  --aui-font-size--4xl: #{rem($aui-font-size--4xl)};
  --aui-font-size--3xl: #{rem($aui-font-size--3xl)};
  --aui-font-size--2xl: #{rem($aui-font-size--2xl)};
  --aui-font-size--xl: #{rem($aui-font-size--xl)};
  --aui-font-size--lg: #{rem($aui-font-size--lg)};
  --aui-font-size--base: #{rem($aui-font-size--base)};
  --aui-font-size--sm: #{rem($aui-font-size--sm)};
  --aui-font-size--xs: #{rem($aui-font-size--xs)};

  --aui-font-weight--thin: #{$aui-font-weight--thin};
  --aui-font-weight--base: #{$aui-font-weight--base};
  --aui-font-weight--semi-bold: #{$aui-font-weight--semi-bold};
  --aui-font-weight--bold: #{$aui-font-weight--bold};
  --aui-font-weight--extra-bold: #{$aui-font-weight--extra-bold};

  --aui-line-height--sm: #{rem($aui-line-height--sm)};
  --aui-line-height--base: #{rem($aui-line-height--base)};
  --aui-line-height--lg: #{rem($aui-line-height--lg)};

  --aui-letter-spacing--base: #{rem($aui-letter-spacing--base)};
  --aui-letter-spacing--md: #{rem($aui-letter-spacing--md)};
  --aui-letter-spacing--lg: #{rem($aui-letter-spacing--lg)};

  --aui-link-decoration:  #{$aui-link-decoration};
  --aui-link-decoration--hover: #{$aui-link-decoration--hover};

  --aui-headers-margin-coeficient: #{$aui-headers-margin-coeficient};
}