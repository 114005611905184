.dot-rating {
  display: flex;
  gap: rem(6px);

  &__item {
    width: rem(11px);
    height: rem(11px);

    flex-shrink: 0;

    border-radius: var(--aui-border-radius--full);
    border: rem(1px) solid var(--aui-color-primary);

    background: transparent;

    &.is-active {
      background: var(--aui-color-primary);
    }
  }
}