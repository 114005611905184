// Variables
$aui-category-block-name-padding: $aui-padding--base !default;
$aui-category-block-name-text-align: center !default;
$aui-category-block-name-font-size: $aui-font-size--xl !default;
$aui-category-block-name-font-weight: $aui-font-weight--bold !default;
$aui-category-block-name-color: $aui-color-white !default;
$aui-category-block-name-background: rgba($aui-color-primary, 0.7) !default;

@import '../css-variables/components/category-block';

// Styles
.aui-category-block {
  width: 100%;

  &__name {
    font-size: var(--aui-category-block-name-font-size);
    font-weight: var(--aui-category-block-name-font-weight);
    color: var(--aui-category-block-name-color);
  }

  // Type -> Image
  &.aui-image {
    position: relative;

    .aui-category-block__name {
      width: 100%;

      position: absolute;
      left: 0;
      bottom: 0;

      text-align: var(--aui-category-block-name-text-align);

      background: var(--aui-category-block-name-background);

      padding: var(--aui-category-block-name-padding);
    }
  }

  // Type -> Button
  &.aui-button {
    &:has(img) {
      justify-content: start;
    }
  }
}