.product-detail-section {
  background: var(--aui-color-white);

  padding-bottom: var(--aui-section-margin-bottom);

  margin-bottom: 0;

  & + #content {
    & > .aui-line {
      padding-top: rem(40px);

      @media #{$aui-breakpoint-sm} {
        padding-top: rem(87px);
      }
    }

    .text-with-image {
      @media only screen and (max-width: map-get($aui-breakpoints, md)) {
        flex-direction: column;
      }
    }
  }

  .aui-breadcrumbs {
    margin-bottom: rem(36px);
  }
}

.product-detail-content {
  padding-top: var(--aui-section-margin-bottom);
}

.product-detail {
  display: flex;
  flex-direction: column;
  gap: rem(20px);

  @media #{$aui-breakpoint-lg} {
    flex-direction: row;
    justify-content: space-between;

    .splide__track {
      height: auto;
      aspect-ratio: 4 / 5;

      .splide__slide {
        height: 100%;

        .aui-product-block__image {
          height: 100%;

          img {
            height: 100%;

            object-fit: cover;
          }
        }
      }
    }
  }

  .splide__pagination {
    display: flex !important;
    top: auto;
    bottom: rem(25px);

    @media #{$aui-breakpoint-lg} {
      bottom: rem(65px);
    }

    &__page {
      background: var(--aui-color-white);

      &.is-active {
        background: var(--aui-color-primary);
      }
    }
  }

  &__images {
    width: 100%;

    @media #{$aui-breakpoint-lg} {
      width: 50%;
      height: max-content;
      max-width: rem(597px);
    }
  }

  &__info {
    width: 100%;

    font-size: var(--aui-font-size--base);

    @media #{$aui-breakpoint-lg} {
      max-width: rem(400px);
    }

    .aui-like-h4 {
      margin-bottom: 0;
    }

    &-title {
      margin-bottom: calc(var(--aui-font-size--3xl) / 3);
      font-size: clamp(1.523rem, 1.35rem + 2.13vw, 2.75rem); // 34px to 44px
    }

    &-subtitle {
      &:not(:empty) {
        @include titleList;
      }

      font-size: var(--aui-font-size--xs);

      @media #{$aui-breakpoint-md} {
        margin-top: 0;
        margin-bottom: calc(var(--aui-font-size--3xl) / 2);
      }
    }

    &-description {
      a {
        font-weight: var(--aui-font-weight--semi-bold);
      }
    }

    hr {
      margin: rem(22px) 0;
    }

    &-params,
    &-actions {
      & > div {
        display: flex;
        flex-direction: column;
        gap: rem(8px);

        margin-bottom: rem(16px);

        @media #{$aui-breakpoint-sm} {
          flex-direction: row;
          justify-content: space-between;
          gap: rem(16px);

          margin-bottom: rem(8px);
        }

        &:last-child {
          margin-bottom: 0;
        }

        span {
          &:first-child {
            font-weight: var(--aui-font-weight--semi-bold);
          }

          @media #{$aui-breakpoint-sm} {
            &:last-child {
              text-align: right;
            }
          }
        }
      }
    }

    &-params {
      &--dots {
        & > div {
          @media #{$aui-breakpoint-sm} {
            align-items: center;
          }
        }
      }
    }

    &-variants {
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      align-items: center;
      
      gap: rem(16px);

      // @media #{$aui-breakpoint-sm} {
      //   flex-direction: row;
      //   justify-content: space-between;
      //   align-items: center;
      //   gap: rem(16px);
      // }

      .aui-like-h4 {
        flex-shrink: 0;
      }

      .aui-flex {
        flex-wrap: wrap;

        @media #{$aui-breakpoint-sm} {
          justify-content: flex-end;
        }
      }
    }

    &-actions {
      & > div {
        flex-direction: row;
        justify-content: space-between;
        gap: rem(16px);

        margin-bottom: rem(16px);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}